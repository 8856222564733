import authReducers, {
  STATE_KEY as AUTH_STATE_KEY,
} from 'reducers/authReducers'

import entityReducers, {
  STATE_KEY as ENTITY_STATE_KEY,
} from 'reducers/entityReducers'

import appStatusReducers, {
  STATE_KEY as APP_STATUS_STATE_KEY,
} from 'reducers/appStatusReducers'

import inventoryReducers, {
  STATE_KEY as DASHBOARD_STATE_KEY,
} from 'reducers/inventoryReducers'

import quoteReducers, {
  STATE_KEY as QUOTE_STATE_KEY,
} from 'reducers/quoteReducers'

import naughtyReducers, {
  STATE_KEY as NAUGHTY_STATE_KEY,
} from 'reducers/naughtyReducers'

import leadReducers, {
  STATE_KEY as LEAD_STATE_KEY,
} from 'reducers/leadReducers'

// wire this up

const reducers = {
  [AUTH_STATE_KEY]: authReducers,
  [ENTITY_STATE_KEY]: entityReducers,
  [APP_STATUS_STATE_KEY]: appStatusReducers,
  [DASHBOARD_STATE_KEY]: inventoryReducers,
  [QUOTE_STATE_KEY]: quoteReducers,
  [NAUGHTY_STATE_KEY]: naughtyReducers,
  [LEAD_STATE_KEY]: leadReducers,
}

export default reducers
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import difference from 'lodash/difference'
import { Base64 } from 'js-base64'

import { getRooftopId } from 'selectors/appStatusSelectors'
import { ROOTS } from 'pages/routes'
import { getQuoteIds } from 'selectors/quoteSelectors'

export const NS_SEPARATOR = '.'

export const getActiveRouteName = state => get(state, 'router.route', ''),
  getActivePathname = state => get(state, 'router.pathname'),
  getActiveId = state => get(state, 'router.params.id'),
  getCurrentPageState = state =>
    `${getActivePathname(state)}${get(state, 'router.search')}`,
  getActiveHash = state => get(state, 'router.hash').replace('#', ''),
  getDefaultRouteUrl = state =>
    getRouteWithRooftop(state, Object.keys(ROOTS).find(r => ROOTS[r].default)),
  getQueryParamsWith = (state, params) => {
    let newQueryParams = { ...getActiveQuery(state) }

    Object.keys(params || {}).forEach(k => (newQueryParams[k] = get(params, k)))

    return newQueryParams
  },
  getQueryParamsWithout = (state, paramsToRemove = {}, newQueryParams) => {
    newQueryParams = newQueryParams || { ...getActiveQuery(state) }

    paramsToRemove = Array.isArray(paramsToRemove)
      ? paramsToRemove
      : Object.keys(paramsToRemove)
    paramsToRemove.forEach(k => delete newQueryParams[k])

    return newQueryParams
  },
  // gets all the query params in a given namespace without the namespace in the keynames, or all params if ns is empty
  getQuery = (state, key = 'router.query', ns = '') => {
    let query = get(state, key, {}),
      nsSplit = ns.split(NS_SEPARATOR),
      ret = isEmpty(nsSplit)
        ? query
        : Object.keys(query).reduce(
            (ret, k) =>
              k.startsWith(ns)
                ? {
                    ...ret,
                    [difference(k.split(NS_SEPARATOR), nsSplit).join(
                      NS_SEPARATOR,
                    )]: query[k],
                  }
                : ret,
            {},
          )

    return ret
  },
  getActiveQuery = (state, ns) => getQuery(state, 'router.query', ns),
  getActiveParams = state => getQuery(state, 'router.params'),
  getPreviousQuery = (state, ns) =>
    getQuery(state, 'router.previous.query', ns),
  isQueryParamsDifferent = (state, ns) =>
    !isEqual(getActiveQuery(state, ns), getPreviousQuery(state, ns)),
  // gets all the query params WITHOUT a given namespace, leaves existing keynames
  getQueryParamsWithoutNS = (state, ns = []) => {
    let query = get(state, 'router.query')

    if (isEmpty(ns)) return query

    return Object.keys(query).reduce(
      (ret, k) =>
        !ns.some(n => k.startsWith(n)) ? { ...ret, [k]: query[k] } : ret,
      {},
    )
  },
  previousStateFromHash = state => {
    let appStateHash = getActiveHash(state)
        .split('&')
        .find(h => h.startsWith('state=')),
      appStateParam = getQueryValue(state, 'state'),
      appState = appStateHash
        ? decodeURIComponent(appStateHash.replace('state=', ''))
        : appStateParam

    return appState ? Base64.decode(appState) : null
  },
  getQueryValue = (state, key, def) => {
    return get(getActiveQuery(state), key, def)
  },
  getQueryArrayValue = (state, key, def) => {
    let val = get(getActiveQuery(state), key, def)

    return Array.isArray(val) ? val : [val]
  },
  getParamValue = (state, key, def) => {
    return get(state, `router.params.${key}`, def)
  },
  getRouteWithRooftop = (state, route) =>
    route ? route.replace(':dealerId', getRooftopId(state)) : '',
  isAuthRoute = state =>
    ['/', '/reset-password', '/forgot-password', '/setup'].includes(
      getActivePathname(state),
    ),
  isVDP = state => {
    let { vehicle, quote } = getQuoteIds(state)

    return vehicle && quote
  }

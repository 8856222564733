export const ROUTES = {
  '/': {
    '/:dealerId/inventory': {
      title: 'All Cars',
      icon: 'car',
      showInUtilNav: true,
      default: true,
    },
    '/:dealerId/about': {
      title: 'About Us',
      icon: 'info-circle',
    },
    '/:dealerId/me': {
      title: 'My Profile',
      icon: 'user',
    },
    '/:dealerId/signout': {
      // TODO vet this
      title: 'Sign out',
    },
    '/:dealerId/lead': {
      requireLogin: true,
      showInUtilNav: true,
      title: '🍒 Cherry Leads',
      permissions: ['rooftop.leads/list'],
    },
    '/:dealerId/settings': {
      title: ({ userName }) => userName,
      icon: 'cog',
      showInUtilNav: true,
      requireLogin: true,
      menu: {
        '/:dealerId/settings/': {
          title: 'Settings',
          permissions: ['rooftop.config/quote'],
        },
        '/:dealerId/stats': {
          title: 'Stats',
        },
        '/:dealerId/downloads': {
          title: 'Downloads',
          xpermissions: ['rooftop.config/reports'],
        },
        '/:dealerId/appointments': {
          title: 'Appointments',
          permissions: ['rooftop.config/appointments-dashboard'],
        },
        hr2: {},
        '/:dealerId/customerView/': {
          title: 'Customer View Toggle',
          noFollow: true,
          onClick: ({ toggleQueryValue }) => toggleQueryValue('customerView'),
        },
        '/rooftopChooser': {
          title: 'Change Rooftop',
          noFollow: true,
          filter: ({ rooftopOptions = [] }) =>
            rooftopOptions && rooftopOptions.length > 1,
          onClick: ({ toggleQueryValue }) =>
            toggleQueryValue('showRooftopModal'),
        },
        hr3: {},
        'https://frikintech.com/support/': {
          title: 'Get Help...',
          external: true,
        },
        'https://meetings.hubspot.com/jason-sparks/monthly-reporting?uuid=97da3496-e4ca-4fb0-9d87-a99857e53b81': {
          title: 'Schedule time to learn...',
          external: true,
        },
        '/:dealerId/signout': {
          title: 'Sign out',
          onClick: ({ signOut }) => signOut(),
          noFollow: true,
        },
      },
    },
    '/:dealerId': {},
    '/:dealerId/': {},
    '/:dealerId/404': {},
    '/:dealerId/500': {},
    '/:dealerId/q/:shortCode': {},
    '/:dealerId/b/:shortCode': {},
    '/:dealerId/l/:shortCode': {},
    '/:dealerId/vehicle/:id/:condition': {},
    '/:dealerId/vehicle/:id/:condition/:year': {},
    '/:dealerId/vehicle/:id/:condition/:year/:make': {},
    '/:dealerId/vehicle/:id/:condition/:year/:make/:model': {},
    '/:dealerId/vehicle/:id/:condition/:year/:make/:model/:trim': {},
    '/:dealerId/vehicle/:id': {},
    '/:dealerId/lead/:sessionId': {},
    '/:dealerId/*': {},
    // TODO these below are the Helix routes, these should probably be a separate
    // export to prevent collisions in the future, but for now it's alright
    '/rooftops': {},
    '/tenants': {},
    '/websties': {},
    '/feeds': {},
    '/homenet': {},
    '/insuranceiq': {},
  },
}

export const ROOTS = ROUTES['/']

export const NAV_ROUTES = Object.keys(ROOTS).reduce(
  (ret, k) =>
    ROOTS[k].showInMainNav || ROOTS[k].showInUtilNav
      ? { ...ret, [k]: ROOTS[k] }
      : ret,
  {},
)

export const createNavFilter = (navItems, props) => {
  return k => {
    const requiresLogin = Boolean(navItems[k].requireLogin),
      hasFilter = Boolean(navItems[k].filter),
      passesFilterCheck = Boolean(
        navItems[k].filter && navItems[k].filter(props),
      ),
      passesLoginCheck = Boolean(navItems[k].requireLogin && props.isLoggedIn)

    return (
      (!requiresLogin && !hasFilter) || passesFilterCheck || passesLoginCheck
    )
  }
}

export default ROUTES

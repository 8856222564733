import numeral from 'numeral'
import get from 'lodash/get'
import { Base64 } from 'js-base64'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import phoneFormatter from 'phone-formatter'
import { version } from '../../package.json'
import { isMobilePhone, isEmail } from 'validator'

import { READY, ERROR, SET_COMPLETE_XHR } from 'reducers/appStatusReducers'
import { updateAppStatus } from 'actions/appStatusActions'
import { HIDDEN_PREFIX, REQUEST_SEP } from 'selectors/appStatusSelectors'
import { goToError } from 'actions/pageStateActions'

import throttle from 'lodash/throttle'

import API_ENDPOINTS from 'endpoints'

import { getEnvName } from 'config/env'

export const RETRIES = ['java.io.IOException: Connection reset by peer']
export const PHONE_FORMAT = '(NNN) NNN-NNNN'

export const TP_PARTNER_ID = 'nh8zMSryWqSXuR7Cw'

export const GRAVITY_FORMS_URL =
  'https://www.frikintech.com/wp-json/gf/v2/forms/5/submissions'

export const SALES_ROOFTOPS = ['demo', 'your-dealership']
export const OUR_ROOFTOPS = [...SALES_ROOFTOPS, 'demo-volvo-williston-vt']
export const THEMEABLE_ROOFTOPS = ['demo', 'your-dealership', 'mdrive-demo']

export const ABE_CHAT_ROOFTOPS = []

export const OEM_THEME_OPTIONS = {
  acura: 'Acura',
  audi: 'Audi',
  bmw: 'BMW',
  cadillac: 'Cadillac',
  cdjr: 'Chrysler Dodge Jeep RAM',
  chevrolet: 'Chevrolet',
  ford: 'Ford',
  gmc: 'GMC',
  honda: 'Honda',
  hyundai: 'Hyundai',
  inifiti: 'INFINITI',
  jaguarLandRover: 'Jaguar Land Rover',
  kia: 'Kia',
  lexus: 'Lexus',
  mazda: 'Mazda',
  mercedes: 'Mercedes-Benz',
  mini: 'MINI',
  nissan: 'Nissan',
  porsche: 'Porsche',
  subaru: 'Subaru',
  toyota: 'Toyota',
  volkswagen: 'Volkswagen',
  volvo: 'Volvo',
}

export const GENERIC_THEME_OPTIONS = {
  frikin: 'Frikin Motors',
  britishRacingGreen: 'British Racing Green',
  earthlingOrange: 'Earthling Orange',
  mach5White: 'Mach 5',
  rivieraBlue: 'Riviera Blue',
  subLime: 'Sub-Lime',
  tuxedoBlack: 'Tuxedo Black',
}

export const WHEN_INCLUDE = {
  AUTO: 'AUTO',
  ALWAYS: 'ALWAYS',
  NEVER: 'NEVER',
}

export const WHEN_INCLUDE_LABELS = {
  [WHEN_INCLUDE.AUTO]: 'Include automatically for known customers',
  [WHEN_INCLUDE.ALWAYS]: 'Always include in payments',
  [WHEN_INCLUDE.NEVER]: 'Never automatically include in payments',
}

export const keys = Object.keys,
  values = obj => keys(obj).reduce((ret, k) => [...ret, obj[k]], []),
  logger = (kind, ...args) => isDebugMode() && console[kind](...args),
  isDebugMode = () => window.LOG_LEVEL === 'DEBUG',
  info = (...args) => logger('info', ...args),
  group = group => (...args) => logger('group', group, ...args),
  groupCollapsed = group => (...args) =>
    logger('groupCollapsed', group, ...args),
  groupEnd = group => (...args) => logger('groupEnd', group, ...args),
  log = (...args) => logger('log', ...args),
  warn = (...args) => logger('warn', ...args),
  error = (...args) => logger('error', ...args),
  time = (...args) => logger('time', ...args),
  timeLog = (...args) => logger('timeLog', ...args),
  timeEnd = (...args) => logger('timeEnd', ...args),
  formatPhone = (phoneNumberString = '') =>
    phoneFormatter.format(phoneNumberString, PHONE_FORMAT),
  rawMoney = k => numeral(k).format('0,0.00'),
  rawMoneyShort = k => numeral(Math.ceil(k)).format('0,0'),
  rawMoneySuperShort = k => numeral(k).format('0,0a'),
  money = k => numeral(k).format('$0,0.00'),
  moneyShort = k => numeral(Math.ceil(k)).format('$0,0'),
  moneySuperShort = (k, showTenths) =>
    numeral(k).format(showTenths ? '$0,0.0a' : '$0,0a'),
  moneyRange = nums =>
    nums
      .filter(x => x && x > 0)
      .map(n => moneyShort(n))
      .join('-'),
  ordinal = k => numeral(k).format('0o'),
  percent = k => numeral(k).format('0.0[0000]%'),
  percentShort = k => numeral(k).format('0%'),
  num = k => numeral(k).format('0,0'),
  numShort = k => numeral(k).format('0,0a'),
  decimal = k => numeral(k).format('0,0.0'),
  decimalLong = k => numeral(k).format('0.0[0000]'),
  asFloat = num => parseFloat(num.toString().replace(/\D/g, '')),
  reduceEntities = (entities = [], entityType) => {
    return entities.reduce(
      (ret, e) => ({
        ...ret,
        [e.id]: { ...e, _entityType: entityType },
      }),
      {},
    )
  },
  toTitleCase = str =>
    str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    ),
  easeInOutQuad = function(t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  },
  obfusName = (name, canDo) => {
    if (isEmpty(name)) return null

    if (!canDo) return `${get(name, '[0]')}.`

    if (canDo) return name
  },
  obfusPhone = (phone, canDo) => {
    if (isEmpty(phone)) return null

    if (!canDo) return `*** *** ${phone.slice(-4)}`

    if (canDo) return phone
  },
  obfusEmail = (email, canDo) => {
    if (isEmpty(email)) return null

    if (!canDo) return `********@${email.split('@')[1]}`

    if (canDo) return email
  },
  obfusDevice = (browserName, osName, canDo) => {
    if (!canDo) return `*** on ***`
    return `${browserName} on ${osName}`
  },
  obfusCustomerType = (customerType, canDo) => {
    if (!canDo) return `***`
    return `${customerType}`
  },
  obfusSources = (sources, canDo) => {
    const renderSources = canDo ? sources : sources.map(s => `${s[0]}***`)
    return renderSources.join(', ')
  },
  obfusAdf = (adfSent, adfSentOnValue, canDo) => {
   if (!canDo) return `***`
    if (adfSent === true) return  adfSentOnValue
   if (adfSent === false) return "NOT IN DATE RANGE"
   return adfSent
 },
  queryMapper = (a, prefix = '') =>
    Object.keys(a)
      .map(k => (a[k] ? `${prefix}${k}=${a[k]}` : null))
      .filter(x => x)
      .join('&'),
  termAsYears = term => term / 12,
  scrollTo = throttle(
    (scrollToId, containerPath = 'window', offset = 0, duration = 500) => {
      let scrollArg = containerPath === 'window' ? 'scrollY' : 'scrollTop',
        container =
          containerPath === 'window'
            ? window
            : document.querySelector(containerPath),
        start = get(container, scrollArg, 0),
        end =
          get(document.querySelector(`#${scrollToId}`), 'offsetTop', 0) -
          offset,
        change = end - start,
        currentTime = 0,
        increment = 10,
        animateScroll = function() {
          currentTime += increment
          container[scrollArg] = easeInOutQuad(
            currentTime,
            start,
            change,
            duration,
          )

          if (currentTime < duration) setTimeout(animateScroll, increment)
        }

      setTimeout(animateScroll(), 1)
    },
    500,
  ),
  scrollHorizontal = throttle((containerPath, offset = 500, duration = 250) => {
    let container = document.querySelector(containerPath),
      start = get(container, 'scrollLeft', 0),
      end = start + offset,
      change = end - start,
      currentTime = 0,
      increment = 10,
      animateScroll = function() {
        currentTime += increment
        container.scrollLeft = easeInOutQuad(
          currentTime,
          start,
          change,
          duration,
        )

        if (currentTime < duration) setTimeout(animateScroll, increment)
      }

    setTimeout(animateScroll(), 1)
  }, 500),
  rateMarkets = {
    0: 'Alabama',
    1: 'Alaska',
    2: 'Arizona',
    3: 'Arkansas',
    4: 'California - Northern California',
    52: 'California - San Diego',
    51: 'California - Southern California',
    5: 'Colorado',
    6: 'Connecticut',
    7: 'Delaware',
    8: 'Florida',
    9: 'Georgia',
    10: 'Hawaii',
    11: 'Idaho',
    12: 'Illinois',
    13: 'Indiana',
    14: 'Iowa',
    15: 'Kansas',
    16: 'Kentucky',
    17: 'Louisiana',
    18: 'Maine',
    19: 'Maryland',
    20: 'Massachusetts',
    21: 'Michigan',
    22: 'Minnesota',
    23: 'Mississippi',
    24: 'Missouri',
    25: 'Montana',
    26: 'Nebraska',
    27: 'Nevada',
    28: 'New Hampshire',
    29: 'New Jersey',
    30: 'New Mexico',
    31: 'New York',
    32: 'North Carolina',
    33: 'North Dakota',
    34: 'Ohio',
    35: 'Oklahoma',
    36: 'Oregon',
    37: 'Pennsylvania',
    38: 'Rhode Island',
    39: 'South Carolina',
    40: 'South Dakota',
    41: 'Tennessee',
    42: 'Texas',
    43: 'Utah',
    44: 'Vermont',
    45: 'Virginia',
    46: 'Washington',
    47: 'West Virginia',
    48: 'Wisconsin',
    49: 'Wyoming',
    50: 'Washington DC',
  },
  states = {
    AL: 'ALABAMA',
    AK: 'ALASKA',
    AZ: 'ARIZONA',
    AR: 'ARKANSAS',
    CA: 'CALIFORNIA',
    CO: 'COLORADO',
    CT: 'CONNECTICUT',
    DE: 'DELAWARE',
    FL: 'FLORIDA',
    GA: 'GEORGIA',
    HI: 'HAWAII',
    ID: 'IDAHO',
    IL: 'ILLINOIS',
    IN: 'INDIANA',
    IA: 'IOWA',
    KS: 'KANSAS',
    KY: 'KENTUCKY',
    LA: 'LOUISIANA',
    ME: 'MAINE',
    MD: 'MARYLAND',
    MA: 'MASSACHUSETTS',
    MI: 'MICHIGAN',
    MN: 'MINNESOTA',
    MS: 'MISSISSIPPI',
    MO: 'MISSOURI',
    MT: 'MONTANA',
    NE: 'NEBRASKA',
    NV: 'NEVADA',
    NH: 'NEW HAMPSHIRE',
    NJ: 'NEW JERSEY',
    NM: 'NEW MEXICO',
    NY: 'NEW YORK',
    NC: 'NORTH CAROLINA',
    ND: 'NORTH DAKOTA',
    OH: 'OHIO',
    OK: 'OKLAHOMA',
    OR: 'OREGON',
    PA: 'PENNSYLVANIA',
    RI: 'RHODE ISLAND',
    SC: 'SOUTH CAROLINA',
    SD: 'SOUTH DAKOTA',
    TN: 'TENNESSEE',
    TX: 'TEXAS',
    UT: 'UTAH',
    VT: 'VERMONT',
    VA: 'VIRGINIA',
    WA: 'WASHINGTON',
    WV: 'WEST VIRGINIA',
    WI: 'WISCONSIN',
    WY: 'WYOMING',
  },
  calendarFormats = {
    sameDay: '[Today at] h:mm a',
    lastDay: '[Yesterday at] h:mm a',
    lastWeek: 'dddd [at] h:mm a',
    sameElse: 'MM/DD/YYYY h:mm a',
  },
  momentToMs = m => m.unix() * 1000,
  getStateAbbriv = state =>
    Object.keys(states).find(k => k === state.toUpperCase()),
  lastFullPeriodDateRange = unit => {
    let currentTime = new Date(),
      m = moment
    return {
      currentEnd: momentToMs(m(currentTime).startOf(unit)),
      currentStart: momentToMs(
        m(currentTime)
          .startOf(unit)
          .subtract(1, unit),
      ),
      previousEnd:
        momentToMs(
          m(currentTime)
            .startOf(unit)
            .subtract(1, unit),
        ) - 1,
      previousStart: momentToMs(
        m(currentTime)
          .startOf(unit)
          .subtract(2, unit),
      ),
    }
  },
  currentPeriodDateRange = unit => {
    let currentTime = new Date(),
      m = moment
    return {
      currentEnd: momentToMs(m(currentTime)),
      currentStart: momentToMs(m(currentTime).startOf(unit)),
      previousEnd: momentToMs(m(currentTime).subtract(1, unit)),
      previousStart: momentToMs(
        m(currentTime)
          .subtract(1, unit)
          .startOf(unit),
      ),
    }
  },
  dateOptions = {
    today: {
      label: 'Today',
      range: () => currentPeriodDateRange('day'),
      listen: true,
    },
    yesterday: {
      label: 'Yesterday',
      range: () => lastFullPeriodDateRange('day'),
    },
    weekToDate: {
      label: 'Week to Date',
      duringLabel: 'this Week to Date',
      range: () => currentPeriodDateRange('week'),
      listen: true,
    },
    lastWeek: {
      label: 'Last Week',
      range: () => lastFullPeriodDateRange('week'),
    },
    monthToDate: {
      label: 'Month to Date',
      duringLabel: 'this Month to Date',
      range: () => currentPeriodDateRange('month'),
      listen: true,
    },
    lastMonth: {
      label: 'Last Month',
      range: () => lastFullPeriodDateRange('month'),
    },
    //yearToDate: {
    //label: 'Year To Date',
    //duringLabel: 'this Year to Date',
    //range: () => currentPeriodDateRange('year'),
    //listen: true,
    //},
  },
  dateStringtoMsRange = (dateString = 'today') => {
    return dateOptions[dateString].range()
  },
  makeRequestKey = (method, path, data, showLoader = true, dupeOk = false) => {
    let requestData = {
      ...data,
    }
    if (dupeOk) requestData.dupeOk = new Date().getTime()

    let requestKey = Base64.encode(JSON.stringify({ method, path, ...data }))

    return !showLoader
      ? [HIDDEN_PREFIX, requestKey].join(REQUEST_SEP)
      : requestKey
  },
  makeRequestTimestamp = () => new Date().getTime(),
  ns = API_ENDPOINTS.API.ns,
  getDomain = () => {
    return get(API_ENDPOINTS.API.endpoints.find(e => e.name === ns), 'endpoint')
  },
  processResp = r => r,
  easyThen = (dispatch, requestKey) => r => {
    dispatch(updateAppStatus(READY))

    setTimeout(
      () =>
        dispatch({
          type: SET_COMPLETE_XHR,
          payload: requestKey,
        }),
      1,
    )

    return processResp(r)
  },
  easyCatch = (dispatch, requestKey, throwErrors) => e => {
    dispatch({
      type: SET_COMPLETE_XHR,
      payload: requestKey,
    })

    if (get(e, 'response.status') === 404) {
      dispatch(goToError(404))
    } else if (e instanceof StaleRequestException) {
      window.LOG_LEVEL === 'DEBUG' &&
        console.warn('Stale Request: ', requestKey)

      throw new StaleRequestException()
    } else {
      dispatch(updateAppStatus(ERROR, e))
      window.logLevel === 'DEBUG' && console.error('UNKNOWN API ERROR', e)
    }

    if (throwErrors) {
      throw e
    }
  },
  roundPayment = (payment, multiple) => {
    if (!multiple) return payment

    return Math.max(
      multiple,
      Math.abs(Math.ceil(payment / multiple) * multiple),
    )
  },
  rounder = (payment, multiple = 0, rangePercent = 0) => {
    let modifier = 1 + rangePercent / 100,
      p =
        !isNaN(multiple) && multiple > 0
          ? Math.ceil((payment * modifier) / multiple) * multiple
          : payment * modifier

    return p
  },
  getPaymentRange = (
    payment,
    nearestMultiple = 0,
    rangePercent = 0,
    bothWays = false,
  ) => {
    let starting = rounder(payment, nearestMultiple),
      ret = [
        !bothWays
          ? starting
          : rounder(payment, nearestMultiple, rangePercent * -1),
        rangePercent > 0 ? rounder(payment, nearestMultiple, rangePercent) : 0,
      ].filter(x => x && x > 0)

    if (ret[0] === get(ret, '[1]')) ret[1] += nearestMultiple

    return ret
  },
  onMessage = event => {
    //TODO Check sender origin to be trusted
    //if (event.origin !== "http://example.com") return;
    var { func, payload } = get(event, 'data'),
      evalFunc = get(window, func, null)

    if (typeof evalFunc === 'function') {
      evalFunc.call(null, payload)
    } else {
      console.warn(`Unknown function call from child frame ${func}`)
    }
  },
  setupMessenger = () => {
    if (window.addEventListener)
      window.addEventListener('message', onMessage, false)
    else if (window.attachEvent)
      window.attachEvent('onmessage', onMessage, false)
  },
  teardownMessenger = () => {
    if (window.removeEventListener)
      window.removeEventListener('message', onMessage, false)
    else if (window.detatchEvent)
      window.detatchEvent('onmessage', onMessage, false)
  },
  makeStaticAssetPath = errata => {
    return [
      window.location.origin,
      ['local', 'development'].includes(getEnvName()) ? null : version,
      errata,
    ]
      .filter(x => x)
      .join('/')
  },
  creditSort = (vals = []) => {
    let newVals = [...vals],
      idx = newVals.findIndex(v => v.value === 0),
      zeroCredit = idx > -1 ? newVals[idx] : null

    if (idx === -1) return vals

    newVals.splice(idx, 1)

    return [...newVals, zeroCredit]
  },
  importAll = r => r.keys().map(r),
  getLogos = () =>
    importAll(require.context('static/images/oemLogos'))
      .filter(x => x)
      .reduce((ret, l = '') => {
        let path = get(l, 'default', '')

        return {
          ...ret,
          [path
            .split('/')
            .slice(-1)[0]
            .split('.')[0]]: path,
        }
      }, {}),
  terminalMatcher = ({ to }) => typeof to !== 'object' && !Array.isArray(to),
  tagDiff = (matcher = terminalMatcher) => (
    newThing = {},
    oldThing = {},
    path = [],
  ) => {
    if (isEmpty(newThing)) return {}

    let changes = Object.keys(newThing).reduce((ret, k) => {
      let to = get(newThing, k),
        from = get(oldThing, k),
        newPath = [...path, k],
        isDiff = (a, b) => !isEqual(a, b),
        match = matcher({ to, from }, newPath.join('.')),
        terminal = terminalMatcher({ to, from })

      if (match && isDiff(to, from)) {
        ret[newPath.join('.')] = { to, from }
      }
      if (!terminal) {
        ret = { ...ret, ...tagDiff(matcher)(to, from, newPath) }
      }

      return ret
    }, {})

    return changes
  },
  isCustomerKnown = customer => {
    let { firstName, lastName, phone = '', email = '' } = customer,
      hasName = !isEmpty(firstName) && !isEmpty(lastName), // has a first and last name
      hasContact = isMobilePhone(phone, 'en-US') || isEmail(email) // customer has valid email or phone

    return hasName && hasContact
  }

export const unauthed = request => ({
  message: `unauthed`,
  request,
})
export const staleRequest = request => ({
  message: `Stale Request for ${request.path}, tossing response`,
  request,
})
export const dupeRequest = request => ({
  message: `Request aborted for ${request.path}, duplicate of exsiting active request`,
  request,
})
export function StaleRequestException() {}
export function RetryFailureException() {}

import get from 'lodash/get'
import set from 'lodash/set'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import uuid from 'uuid/v1'

import placeholder from 'static/images/chip-placeholder.png'
import { getTaggedCustomerFromState } from 'selectors/appStatusSelectors'

import {
  STATE_KEY,
  INVENTORY_FILTER_NS as NS,
} from 'reducers/inventoryReducers'

import { getEntity } from 'selectors/entitySelectors'
import {
  getActiveQuery,
  getQueryValue,
  getQueryArrayValue,
} from 'selectors/pageStateSelectors'
import { getDefaultSort } from 'selectors/appStatusSelectors'
import {
  getCashDealTotal,
  getPreviousQuotesForVehicle,
  quoteHasLease,
  quoteHasLoan,
  getGlobalQuoteFilters,
} from 'selectors/quoteSelectors'

import { getCustomerTrades, getImmortalPins } from 'selectors/naughtySelectors'

import { SEARCH_NS } from 'actions/inventoryActions'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, (a, b) =>
  isEqual(a, b),
)

export const SORT_BYS = {
  'lease.payment.low': 'Lowest Lease Payment',
  'lease.payment.high': 'Highest Lease Payment',
  'lease.value.high': 'Best Lease Value',
  'lease.residual.high': 'Highest Residual',
  'loan.payment.low': 'Lowest Loan Payment',
  'loan.payment.high': 'Highest Loan Payment',
  'loan.value.high': 'Best Loan Value',
  'inventory.deals.high': 'Featured Vehicles',
  'inventory.price.low': 'Lowest Price',
  'inventory.price.high': 'Highest Price',
  'inventory.wiggle.high': 'Most Loan to Value',
}

export const MDRIVE_SORT_BYS = {
  'inventory.match.best': 'Lowest Payment',
  'lease.value.high': 'Best Lease Value',
  'loan.value.high': 'Best Loan Value',
  'inventory.price.low': 'Lowest Price',
  'inventory.price.high': 'Highest Price',
  'inventory.deals.high': 'Featured Vehicles',
}

export const HOUSE_BANK_SORT_BYS = {
  'loan.payment.low': 'Lowest Loan Payment',
  'loan.payment.high': 'Highest Loan Payment',
  'inventory.deals.high': 'Featured Vehicles',
  'inventory.price.low': 'Lowest Price',
  'inventory.price.high': 'Highest Price',
}

// TODO config?
export const PAYMENT_FILTER_OPTIONS = [
  100,
  200,
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
].map(v => v.toString())

const enforceListParams = obj =>
  Object.keys(obj).reduce(
    (ret, k) => ({
      ...ret,
      [k]: Array.isArray(obj[k]) ? obj[k] : [obj[k]],
    }),
    {},
  )

export const isLoading = state => get(state, `${STATE_KEY}.loading`, false),
  getYMMT = (state, id, withCondition) => {
    let v = getEntity(state, id)

    return [
      withCondition ? get(v, 'type') : null,
      get(v, 'year'),
      get(v, 'make'),
      get(v, 'model'),
      get(v, 'trim'),
    ]
      .filter(x => x)
      .join(' ')
  },
  getIsUsedCar = (state, vId) => get(getEntity(state, vId), 'type') === 'Used',
  getVehicleIds = state => getAllVehilceIds(state),
  getVehicleEmbeddedQuoteIncludeTaxes = (state, vId) => get(getEntity(state, vId), 'quote.includeTaxes'),
  getVehicleEmbeddedQuoteIncludeFees = (state, vId) => get(getEntity(state, vId), 'quote.includeFees'),
  getAllVehilceIds = state => get(state, `${STATE_KEY}.vehicleIds`, []),
  getFilteredInventoryCount = state => get(state, `${STATE_KEY}.totalCount`, 0),
  inventoryHasLeases = state =>
    getAllVehilceIds(state).some(vId => quoteHasLease(state, vId)),
  inventoryHasLoans = state =>
    getAllVehilceIds(state).some(vId => quoteHasLoan(state, vId)),
  getDealIds = state => get(state, `${STATE_KEY}.dealIds`, []),
  getFilters = state => get(state, `${STATE_KEY}.filters`, []),
  getAllFilters = state => {
    let ret = get(state, `${STATE_KEY}.allFilters`, []).map(f => ({
      ...f,
      total: f.count,
      count: getFilterCount(state, f.value),
    }))

    return ret
  },
  getActiveInventoryFilters = (state, errata = {}) => {
    let getFilters = state => ({
        ...getActiveQuery(state, NS), // gets inventory filters for filtering results
        ...getGlobalQuoteFilters(state, {
          trimmed: true,
          grayList: ['loanTerm', 'leaseTerm'],
        }), // gets quote params for mdrive calls
      }),
      filters = enforceListParams(getFilters(state)),
      search = getActiveQuery(state, SEARCH_NS),
      trades = getCustomerTrades(state),
      myInit = { body: { ...search, ...filters } },
      getKnownState = s => getFilters(s)

    myInit.body = {
      ...myInit.body,
      ...errata,
      trades,
    }

    return [myInit, getKnownState, !isEmpty(search)]
  },
  getSortBy = state =>
    getQueryValue(state, `inventory.sortBy`, getDefaultSort(state)),
  getFilterGroups = (state, all) =>
    groupBy((all ? getAllFilters : getFilters)(state), f => f.filter[0]),
  getFilterGroup = (state, group, all) =>
    get(getFilterGroups(state, all), group, []).map(f => ({
      ...f,
      selected: isFilterValueSelected(state, f.filter, f.value), // TODO this may be removed when filters from backend are fixed
    })),
  getSelectedFilters = (state, namespace = NS) => ({
    ...getActiveQuery(state, namespace),
    ...getActiveQuery(state, 'q'), // include quote filters in return, just cuz
  }),
  getPreviouslySelectedFilters = state =>
    get(state, `${STATE_KEY}.selectedFilters`),
  getFilterGroupLabel = (state, group) =>
    get(getFilterGroup(state, group), '[0].label', []).join(' '),
  getSelectedFiltersList = state => {
    let allFilters = getFilters(state),
      selectedFilters = getSelectedFilters(state)

    return allFilters.filter(f => {
      return get(selectedFilters, f.filter, []).includes(f.value)
    }, {})
  },
  isFilterValueSelected = (state, filterName, value) => {
    let rawFilterValue = get(getSelectedFilters(state), filterName, []),
      convertedFilterValue = Array.isArray(rawFilterValue)
        ? rawFilterValue
        : [rawFilterValue],
      selectedValue = convertedFilterValue.map(v => v.toString()),
      ret = selectedValue.includes(value.toString())

    return ret
  },
  isFilterValueDisabled = (state, filterName, value) => {
    let activeGroups = Object.keys(getActiveQuery(state, 'inventory'))

    return (
      !activeGroups.includes(filterName) &&
      !getFilters(state).find(f => f.value === value)
    )
  },
  getFilterCount = (state, value) => {
    let filter = getFilters(state).find(f => f.value[0] === value[0])

    return get(filter, 'count', 0)
  },
  getSelectedFilterValue = (state, filterName, defaultValue) => {
    return get(getSelectedFilters(state), filterName, defaultValue)
  },
  getVinDetails = (state, id) => get(getEntity(state, id), 'vinDetails', {}),
  getStockPhotos = (state, id) =>
    sortBy(get(getVinDetails(state, id), 'media.images', []), 'shotCode').map(
      i => i.url,
    ),
  getWarrantyInfo = (state, id) =>
    get(
      get(getVinDetails(state, id), 'consumerInfo', []).find(
        ci => ci.type === 'Warranty',
      ),
      'items',
      [],
    ).reduce((ret, item) => {
      let name = item.name.replace(' Miles/km', '').replace(' Years', ''),
        unit = item.name.includes('Years') ? 'years' : 'miles'

      set(ret, `${name}.${unit}`, item.value)

      return ret
    }, {}),
  getImages = (state, id) => {
    let vehicle = getEntity(state, id)
    return [
      ...(vehicle.images || []).map(i => ({
        src: i.url,
      })),
      ...getStockPhotos(state, id).map(src => ({
        src: process.env.BUILD_ENV === 'production' ? src : placeholder,
      })),
    ]
  },
  getFirstImage = (state, id) => get(getImages(state, id), '[0]', {}),
  isShowingVehicle = state => getActiveQuery(state).vehicleId,
  getInventoryChipProps = () =>
    createDeepEqualSelector(
      [
        (_, op) => op,
        (s, { id }) => getEntity(s, id),
        (s, { id, quoteId }) =>
          !getActiveQuery(s).vehicleId && getCashDealTotal(s, id, quoteId),
        (s, { id }) =>
          !getActiveQuery(s).vehicleId && getPreviousQuotesForVehicle(s, id),
      ],
      (op, vehicle, _, previousQuotes, isShowingVehicle) => {
        return {
          ...op,
          vehicle,
          isLoading: isEmpty(vehicle),
          previousQuotes,
          isShowingVehicle,
        }
      },
    ),
  getPinnedVehicles = state => {
    let pins = getQueryValue(state, 'pins', []),
      regularPins = Array.isArray(pins) ? pins : [pins],
      reducer = (ret, vId) => ({ ...ret, [vId]: uuid() })

    let ret = {
      ...{},
      ...regularPins.reduce(reducer, {}),
      ...getImmortalPins(state),
    }

    return ret
  },
  isVehiclePinned = (state, vId) =>
    Object.keys(getPinnedVehicles(state)).includes(vId),
  getVehicleValue = (state, vId, key, def) =>
    get(getEntity(state, vId), key, def),
  getVehicleInfo = (state, vId) => {
    let gvv = key => getVehicleValue(state, vId, key, ''),
      keys = {
        cityMpg: () => gvv('cityMpg'),
        highwayMpg: () => gvv('highwayMpg'),
        fuelType: () => gvv('fuel'),
        engine: () => gvv('engine'),
        bodyStyle: () => gvv('bodyStyle'),
        marketClass: () => gvv('marketClass'),
        passengers: () => gvv('passengers'),
        transmission: () => gvv('transmission.description'),
        color: () => gvv('color'),
        miles: () => (gvv('odometer') || '').toString(),
        vin: () => gvv('vin'),
        stockNumber: () => gvv('stockNumber'),
      }

    return Object.keys(keys).reduce(
      (ret, k) => ({ ...ret, [k]: keys[k]() }),
      {},
    )
  },
  hasWarrantyData = (state, id) =>
    !getIsUsedCar(state, id) && !isEmpty(getDataOneWarranties(state, id)),
  hasEquipment = (state, id) => !isEmpty(getDataOneEquipment(state, id)),
  hasSpecs = (state, id) => !isEmpty(getDataOneSpecs(state, id)),
  hasDataOneData = (state, id) =>
    !isEmpty(
      getEntity(state, id, {}),
      'dataoneStyle.standard_generic_equipment',
    ),
  getChromeEquipment = (state, id) =>
    groupBy(
      get(getEntity(state, id), 'vinDetails.equipment.standard'),
      'subCategory',
    ),
  getDataOneEquipment = (state, id) =>
    get(getEntity(state, id), 'dataoneStyle.standard_generic_equipment'),
  getDataOneSpecs = (state, id) =>
    get(getEntity(state, id), 'dataoneStyle.standard_specifications'),
  getDataOneWarranties = (state, id) =>
    get(getEntity(state, id), 'dataoneStyle.warranties'),
  getDataOneSafetyEquipment = (state, id) =>
    get(getEntity(state, id), 'dataoneStyle.safety_equipment'),
  getAllPins = state => {
    let customer = getTaggedCustomerFromState(state),
      queryPins = getQueryArrayValue(state, 'pins', []).reduce(
        (ret, p) => ({
          ...ret,
          [p]: null,
        }),
        {},
      ),
      pins = { ...get(customer, 'quotes', {}), ...queryPins }

    return pins
  },
  getSelectedFeatures = state => {
    let selectedFeatures = get(getActiveQuery(state, NS), 'feature', [])

    selectedFeatures = Array.isArray(selectedFeatures)
      ? selectedFeatures
      : [selectedFeatures]

    return selectedFeatures
  }

export const STATE_KEY = 'lead'

export const REPLACE_LEAD_LIST = 'REPLACE_LEAD_LIST'

const initialState = {
  loading: false,
  leadIds: [],
}

export default function leadReducers(state = initialState, action) {
  switch (action.type) {
    case REPLACE_LEAD_LIST:
      return { ...state, leadIds: action.payload }

    default:
      return state
  }
}

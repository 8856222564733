import React from 'react'
import { moneyShort, TP_PARTNER_ID } from 'utils'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import merge from 'lodash/merge'
import axios from 'axios'

import {
  getCustomerTrades,
  getCustomerHasNoTrade,
} from 'selectors/naughtySelectors'

const TP = window.SNAP

export const isTradeUnknown = ({ low, high }) => low === 0 && high === 0,
  BASE_URL = 'https://snap-api.tradepending.com/api/v4/',
  DETAILS_URL = `${BASE_URL}select/details`,
  SEARCH_URL = `${BASE_URL}ymmtsearch`,
  REPORT_URL = `${BASE_URL}report`,
  REPORT_LINK_URL =
    'https://snap-api.tradepending.com/api/v4/report-html?vehicle_id=167570&partner_id=nh8zMSryWqSXuR7Cw&url=www.frikintech.com&zip_code=93012&mileage=36000',
  TRADE_FIELDS = ['year', 'make', 'model', 'trim'],
  isTradeLeased = trade => get(trade, 'isLease', false),
  getTradeShortLabel = ({ year, model, trim }, noTrim) =>
    [year, model, !noTrim && trim].filter(x => x).join(' '),
  getEquity = trade => moneyShort(getRawEquity(trade)),
  getRawEquity = ({ allowance = 0, residual = 0, payoff = 0, isLease }) => {
    let equity = allowance - residual - payoff

    if (isLease && equity < 0) return 0

    return equity
  },
  getEquityContent = trade => (
    <>
      {getRawEquity(trade) > 0 && (
        <>
          We estimate that you may have{' '}
          <strong>{getEquity(trade)} of equity!</strong>{' '}
        </>
      )}
      Verify your estimated payoff to get the most accurate
      payments.
    </>
  ),
  getAllowanceContent = getEquityContent,
  getReportLinkUrl = ({ allowanceDomain, zipCode, trade }) => {
    let { id, mileage } = trade

    return id && zipCode && mileage && allowanceDomain
      ? `https://snap-api.tradepending.com/api/v4/report-html?vehicle_id=${id}&partner_id=${TP_PARTNER_ID}&url=${allowanceDomain}&zip_code=${zipCode}&mileage=${mileage}`
      : null
  },
  getReportValues = trade => {
    let values = get(trade, 'tradePending.report.tradein', {}),
        { low, high } = values

    return [low, high].filter(x => x)
  },
  getReportContent = (trade = {}, customer = {}) => {
    let { allowance = 0, mileage = 0 } = trade,
      report = get(trade, 'tradePending.report', {}),
      low = get(report, 'tradein.low', 0),
      // medium = get(report, 'tradein.target', 0),
      high = get(report, 'tradein.high', 0),
      firstName = get(customer, 'firstName'),
      nameContent = firstName ? (
        <>
          {firstName},<br />
        </>
      ) : null,
      tradeOk = !isEmpty(report) && mileage > 0,
      tradeUnknown = isTradeUnknown({ low, high }),
      reaction = high && allowance < high ? 'positive' : 'negative',
      copy = {
        positive: {
          header: (
            <>
              <span role="img" aria-label="appraisal">
                🤩
              </span>
              {nameContent}We Want Your Car!
            </>
          ),
          body: (
            <>
              Your car could be worth{' '}
              <strong>{moneyShort(Math.floor(low))}</strong> to{' '}
              <strong>{moneyShort(Math.floor(high))}</strong>. Verify all
              vehicle information below including estimated payoff for the most
              accurate payments.
            </>
          ),
        },
        negative: {
          header: (
            <>
              <span role="img" aria-label="appraisal">
                👋
              </span>
              {nameContent}Let's Talk!
            </>
          ),
          body: (
            <>
              You're looking for about{' '}
              <strong>{moneyShort(allowance - high)}</strong> to{' '}
              <strong>{moneyShort(allowance - low)} more</strong> than the car
              is likely worth. But let's see what we can do.
            </>
          ),
        },
        unknown: {
          header: (
            <>
              <span role="img" aria-label="appraisal">
                👋
              </span>
              {nameContent}Let's Talk!
            </>
          ),
          body: (
            <>
              We don't have enough market data to give you an instant appraisal.
              We can still provide an appraisal but we'll need to see the car.
            </>
          ),
        },
        start: {
          header: (
            <>
              <span role="img" aria-label="appraisal">
                🚘
              </span>
              {nameContent}Get an Instant Appraisal!
            </>
          ),
          body: (
            <>
              Tell us <strong>What you Drive</strong>, the{' '}
              <strong>Current Mileage</strong> and{' '}
              <strong>what you want for it</strong>.
            </>
          ),
        },
      },
      reportContent = tradeUnknown ? copy.unknown : copy[reaction]

    if (!tradeOk) reportContent = copy.start

    return reportContent
  },
  getYMMT = (trade = {}, force) => {
    if (isEmpty(trade)) return ''
    let ymmt = TRADE_FIELDS.map(f => get(trade, f))
      .filter(x => x)
      .join(' ')

    return force ? ymmt : trade.ymmt || ymmt
  },
  getFullYMMT = (trade = {}, force) => {
    let ymmt = getYMMT(trade, force),
      d = trade || {},
      ymmtDetails = [
        d.drivetrain,
        d.engine,
        d.fuel_type,
        d.door ? `${d.door} door` : null,
        d.body,
      ].filter(x => x)

    return isEmpty(ymmtDetails) ? null : [ymmt, ...ymmtDetails].join(' ')
  },
  setupTradePending = (tradeKey, onChange = () => {}) => {
    TP &&
      TP.configure_with_options(
        {
          css_selector: `.trade-input-${tradeKey} input.trade-pending`,
          partner_id: TP_PARTNER_ID,
        },
        onChange,
      )
  },
  searchTrade = (allowanceDomain, query) =>
    axios.get(SEARCH_URL, {
      params: {
        query,
        result_count: 5,
        dealer_url: allowanceDomain,
        partner_id: TP_PARTNER_ID,
      },
    }),
  tradeIsValid = (trade = {}) => TRADE_FIELDS.every(k => trade[k]),
  getTradeChoices = ({ allowanceDomain, vehicle = {} }) => {
    let { year, make, model, trim } = vehicle

    return axios.get(DETAILS_URL, {
      params: {
        year,
        make,
        model,
        trim,
        dealer_url: allowanceDomain,
        partner_id: TP_PARTNER_ID,
      },
    })
  },
  getTradeReport = ({ allowanceDomain, vehicleId, zipCode, mileage }) => {
    return axios.get(REPORT_URL, {
      params: {
        vehicle_id: vehicleId,
        url: allowanceDomain,
        zip_code: zipCode,
        mileage,
        partner_id: TP_PARTNER_ID,
      },
    })
  },
  MILEAGE_MOD = 12000,
  rectifyTrade = (trade, errata = {}, options = {}) => {
    let id = get(
        errata,
        'tradePending.vehicle.id',
        trade.id || trade.tradePendingId,
      ),
      thisYear = new Date().getFullYear(),
      autoMiles =
        MILEAGE_MOD *
        (parseInt(thisYear, 10) - parseInt(trade.year || thisYear - 1, 10)),
      mileage = get(errata, 'mileage', trade.mileage || autoMiles),
      chooseRangeValue = (low, medium, high, chooseRange) => {
        if (chooseRange === 'low') {
          return low
        } else if (chooseRange === 'high') {
          return high
        } else {
          return medium
        }
      },
      clearTrade =
        options.replace ||
        options.clearTrade ||
        trade.id !== id ||
        mileage !== trade.mileage,
      tpReport = get(errata, 'tradePending.report'),
      low = get(tpReport, 'tradein.low', 0),
      medium = get(tpReport, 'tradein.target', 0),
      high = get(tpReport, 'tradein.high', 0),
      chooseRange = get(errata, 'tradePending.chooseRange', 'medium'),
      newValue = chooseRangeValue(low, medium, high, chooseRange),
      newTrade = {
        ...merge({}, trade, errata),
        //mileage,
        id,
        ymmt: getYMMT(trade, true),
        fullYmmt: getFullYMMT(trade, true),
      }

    // If the trade is new/different or allowance is missing, replace the allowance
    // with the TP target value, otherwise use what you got
    newTrade.allowance =
      clearTrade || !newTrade.allowance
        ? Math.max(0, newValue)
        : errata.allowance || newTrade.allowance || 0

    // ensure an int value for payoff
    newTrade.payoff = parseInt(errata.payoff || newTrade.payoff || 0, 10)

    // ensure an int value for payoff
    newTrade.residual = parseInt(errata.residual || newTrade.residual || 0, 10)

    // ensure an int value for year
    if (newTrade.year) newTrade.year = parseInt(newTrade.year, 10)

    // if acv not entered, use the allowance as a starting value
    newTrade.acv = parseInt(
      newTrade.acv || errata.allowance || newTrade.allowance || 0,
      10,
    )

    newTrade.ymmt = getYMMT(trade, true)
    newTrade.fullYmmt = getFullYMMT(trade, true)

    return newTrade
  },
  isTradeValid = (trade = {}) => {
    let hasTpId =
        !isEmpty(get(trade, 'tradePending.vehicle.id')) ||
        !isEmpty(get(trade, 'tradePendingId')) ||
        !isEmpty(get(trade, 'id')),
      tpData = get(trade, 'tradePending'),
      hasTpYmmt = get(trade, 'ymmt') // may be set by the magic cookie decode

    return isEmpty(tpData) || hasTpId || hasTpYmmt
  },
  isTradeValueOk = (trade = {}) => {
    const cutoff = 1000 * 30 * 2 * 60 * 24, // one day
          now = +new Date().getTime()

    let { confirmed = 0 } = trade
    if (typeof confirmed === 'boolean') confirmed = 0

    //let ret = !isValid || (confirmed > 0 && now - confirmed < cutoff)

    let ret = confirmed > 0 && (now - confirmed < cutoff) && isTradeValid(trade)
    return ret
  },
  areTradesConfirmed = state => {
    let trades = getCustomerTrades(state)

    if (isEmpty(trades) || getCustomerHasNoTrade(state)) return true

    let tradesConfirmed = trades.every(isTradeValueOk)

    return tradesConfirmed
  },

  canRedisplayOverview = (state, tradeIndex) => {
    const trades = getCustomerTrades(state)
    if (isEmpty(trades) || getCustomerHasNoTrade(state)) return false
    const lastShown = trades[tradeIndex]?.lastShownOverview || 0,
          cutoff    = 1000 * 30 * 2 * 60 * 24, // one day
          now       = +new Date().getTime()

    return (now - lastShown) > cutoff
  }

window.searchTrade = searchTrade
window.getTradeChoices = getTradeChoices
window.getTradeReport = getTradeReport

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import set from 'lodash/set'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import uuid from 'uuid/v1'

import { NaughtyDB as NDB } from 'utils/NaughtyDB'
import { ILLUMINATIONS_KEY, SQUEEZE_KEY } from 'utils/NaughtyDB'

import { MERGE_ENTITIES } from 'reducers/entityReducers'
import { GET } from 'actions/apiActions'
import { dateStringtoMsRange, GRAVITY_FORMS_URL, info } from 'utils'
import axios from 'axios'
import { getEnvName } from 'config/env.js'

import { shouldTrack } from 'selectors/authSelectors'

import {
  SET_LOADING_QUOTE_INTERACTIONS,
  SET_SQUEEZE,
  SET_TAGGED_CUSTOMER,
} from 'reducers/appStatusReducers'

import {
  getBrowser,
  isMobile,
  getAppVersion,
  getRooftopId,
  getFingerPrint,
} from 'selectors/appStatusSelectors'

import { hydrateCustomer } from 'actions/appStatusActions'

import { addToPageState, goHome } from 'actions/pageStateActions'
import { getQueryValue, getParamValue } from 'selectors/pageStateSelectors'

import {
  getSelectedDateRange,
  getSelectedEventType,
  getRooftopKey,
  getSqueeze,
} from 'selectors/naughtySelectors'

import { getQuoteValue, getCurrentQuoteValue } from 'selectors/quoteSelectors'
import { initialTrade } from 'reducers/quoteReducers'
import { getCustomer } from 'selectors/appStatusSelectors'
import { getSelectedFilters } from 'selectors/inventorySelectors'

import {
  UPDATE_AGGREGATES,
  REPLACE_ILLUMINATIONS,
  UPDATE_MAP_STATS,
} from 'reducers/naughtyReducers'

import { SALES_ROOFTOPS } from 'utils'

import { COOKIE_NAME } from 'utils/MagicCookie'

import { rectifyTrade } from 'utils/trade'

import { resetQuote, changeQuote } from 'actions/quoteActions'

export const KEY = 'a',
  QUOTE_CHANGED = 'QUOTE_CHANGED',
  QUOTE_ADDITION = 'QUOTE_ADDITION',
  QUOTE_REMOVAL = 'QUOTE_REMOVAL',
  QUOTE_REFRESHED = 'QUOTE_REFRESHED',
  VEHICLE_VIEWED = 'VEHICLE_VIEWED',
  QUOTE_VIEWED = 'QUOTE_VIEWED',
  QUOTE_SAVED = 'QUOTE_SAVED',
  QUOTE_REACTION = 'QUOTE_REACTION',
  OPENED_DIFFERENT_VEHICLE = 'OPENED_DIFFERENT_VEHICLE',
  MAGIC_LINK_OPENED = 'MAGIC_LINK_OPENED',
  VEHICLE_PINNED = 'VEHICLE_PINNED',
  VEHICLE_UNPINNED = 'VEHICLE_UNPINNED',
  TAG_CHANGED = 'TAG_CHANGED',
  SQUEEZE_JACKET_OPENED = 'SQUEEZE_JACKET_OPENED',
  SQUEEZE_JACKET_CLOSED = 'SQUEEZE_JACKET_CLOSED'

export const QUOTE_CHANGE_MATCHERS = {
  survey: {
    matcher: k =>
      [
        'leaseTerm',
        'loanTerm',
        'miles',
        'creditScore',
        'down',
        'mode',
        'customerZipCode',
        'customer.approveTab',
        'desiredPayment',
        'viewMode',
      ].includes(k),
    context: (k, newValues, oldValues) => {
      let ret = { key: k, from: get(oldValues, k), to: get(newValues, k) }

      return isEqual(ret.from, ret.to) ? null : ret
    },
  },
  adds: {
    matcher: k =>
    ['warranties', 'additions', 'maintenancePlans'].includes(k.split('[')[0]),
    context: (k, newValues, oldValues) => {
      let from = get(oldValues, k),
        to = get(newValues, k)

      return isEqual(from, to)
        ? null
        : {
          key: k,
          from: get(oldValues, k),
          to: get(newValues, k),
          item: cloneDeep(get(oldValues, `${k.split('.')[0]}`, {})),
        }
    },
  },
  incentives: {
    matcher: k =>
    ['selectedRebateTypes'].includes(k.split('[')[0]),
    context: (k, newValues, oldValues) => {
      let from = get(oldValues, k),
        to = get(newValues, k)

      return isEqual(from, to)
        ? null
        : {
          key: k,
          from: get(oldValues, k),
          to: get(newValues, k),
          item: cloneDeep(get(oldValues, `${k.split('.')[0]}`, {})),
        }
    },
  },
  trades: {
    matcher: k => {
      let blackList = ['report', 'choices']

      let ret =
        k !== 'trades' &&
        k.split('[')[0] === 'trades' &&
        !blackList.some(b => k.includes(b))

      return ret
    },
    context: (k, newValues, oldValues) => {
      return {
        key: k,
        from: get(oldValues, k),
        to: get(newValues, k),
        trade: get(oldValues, `${k.split('.')[0]}`, {}),
      }
    },
  },
}

export const addImmortal = (key, payload, raw, replace) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: 'ADD_TO_IMMORTAL', payload })

  let rooftopKey = getRooftopKey(getState(), key)

  return NDB.get(rooftopKey).then((db = {}) => {
    NDB.set(
      rooftopKey,
      {
        ...(replace ? {} : db),
        ...payload,
      },
      raw,
    )
  })
},
  sendIllumination = event => dispatch => {
    if (window.manager != null) {
      window.manager.sendMessage(
        '/device/events',
        event,
        () => {
          dispatch({ type: 'EVENTS_SAVED', payload: event })
          dispatch(flushByType(ILLUMINATIONS_KEY, [event.id]))
        },
        () => {
          console.log('failed to send event: ' + event.id)
        },
      )
    }
  },
  track = (type, payload = {}, squeeze) => (dispatch, getState) => {
    if (!shouldTrack(getState())) return

    let taggedCustomer = getCustomer(getState())

    if (payload.quoteId && payload.vehicleId) {
      payload.zipCode =
        payload.zipCode ||
        getQuoteValue(
          getState(),
          payload.vehicleId,
          payload.quoteId,
          'customerZipCode',
        )
    }

    let id = uuid(),
      e = eval, // eslint-disable-line
      evalLength =
        e && typeof e.toString === 'function' ? e.toString().length : 0,
      iData = {
        _entityType: 'event',
        id,
        type,
        payload,
        //user: 'TODO',
        at: moment().valueOf(),
        browser: {
          ...getBrowser(getState()),
          userAgent: get(window, 'navigator.userAgent'),
          doNotTrack: get(window, 'navigator.doNotTrack'),
          platform: get(window, 'navigator.platform'),
          evalLength,
          mobile: isMobile(getState()),
          tag: taggedCustomer,
          appVersion: getAppVersion(getState()),
        },
      }

    return dispatch(addImmortal(ILLUMINATIONS_KEY, { [id]: iData }, true))
      .then(() => squeeze && dispatch(incrementSqueeze()))
      .then(dispatch(sendIllumination(iData)))
  },
  trackVlpView = () => (dispatch, getState) => {
    dispatch(
      track('VLP_VIEWED', {
        selectedFilters: getSelectedFilters(getState()),
      }),
    )
  },
  trackSqueezeOpen = (step, auto, payload = {}) => dispatch => {
    dispatch(
      track(SQUEEZE_JACKET_OPENED, {
        step,
        auto,
        ...payload,
      }),
    )
  },
  trackSqueezeClose = (step, submitted, payload = {}) => dispatch => {
    dispatch(
      track(SQUEEZE_JACKET_CLOSED, {
        step,
        submitted,
        ...payload,
      }),
    )
  },
  trackOpen = () => (dispatch, getState) => {
    let existingCustomer = getCustomer(getState())

    if (get(existingCustomer, '_entityType') === 'customer') {
      dispatch(track('OPENED_ILLUMIQUOTE', {}))
    }
  },
  incrementSqueeze = () => async (dispatch, getState) => {
    let squeeze = await getSqueeze(getState()),
      squeezeCount = get(squeeze, 'count', 0) + 1

    dispatch({ type: SET_SQUEEZE, payload: squeezeCount })

    return dispatch(
      addImmortal(SQUEEZE_KEY, { ...squeeze, count: squeezeCount }),
    )
  },
  tagDealerUser = () => dispatch =>
    dispatch(
      hydrateCustomer({
        _entityType: 'dealer',
        lastTag: new Date(),
      }),
    ),
  tagSalesLead = salesLeadData => (dispatch, getState) => {
    let taggedCustomer = getCustomer(getState()),
      isDifferentLead = !isEqual(taggedCustomer, salesLeadData),
      submitLead = isDifferentLead,
      fingerprint = getFingerPrint(getState())

    dispatch(addToPageState({ demoScheduled: true }))

    dispatch(
      hydrateCustomer({
        sessionId: uuid(),
        fingerprint,
        ...salesLeadData,
        _entityType: 'salesLead',
        lastTag: new Date(),
      }),
    )

    if (submitLead) dispatch(submitGravityForm(salesLeadData))
    else info('skipping form sumission, not different enough')
  },
  deleteMagicCookie = () => () => {
    document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`
  },
  addTradeToTag = options => (dispatch, getState) => {
    let customer = getCustomer(getState()) || {},
      { trades = [] } = customer

    return dispatch(
      hydrateCustomer(
        {
          hasNoTrade: false,
          trades: [...trades, { ...initialTrade }],
        },
        options,
      ),
    )
  },
  removeTradeFromTag = (index, options) => (dispatch, getState) => {
    let customer = getCustomer(getState()) || {},
      { trades = [] } = customer,
      removed = trades.splice(index, 1)

    // we create our own tag changed here to better specify the removed trade
    options.createChangeEvent &&
      dispatch(
        track(TAG_CHANGED, {
          removed: {
            trade: { ...removed[0], index },
          },
        }),
      )

    return dispatch(
      hydrateCustomer(
        {
          trades,
        },
        { ...options, createChangeEvent: false },
      ),
    )
  },
  addTrade = addTradeToTag,
  removeTrade = removeTradeFromTag,
  MILEAGE_MOD = 12000,
  updateTrade = (idx, data = {}, options = {}) => (dispatch, getState) => {
    let vehicleId = getParamValue(getState(), 'id'),
      quoteId = getQueryValue(getState(), 'quoteId'),
      trades = getCurrentQuoteValue(getState(), 'trades', []),
      trade = rectifyTrade(get(trades, idx, {}), data, options),
      extraQuoteKeys = ['mileage', 'year', 'make', 'model', 'trim', 'ymmt']

    // this song and dance is to ensure that we track quote change events correctly,
    // since the 'trades' object will bypass the creation of those events
    let extraQuoteData = extraQuoteKeys.reduce((ret, k) => {
      if (data[k] && data[k] !== trade[k]) ret[`trades[${idx}].${k}`] = data[k]

      return ret
    }, {})

    set(trades, idx, trade)

    // if createChangeEvent is false, assume a quote change triggered this change
    // intead of the squeeze jacket
    !options.createChangeEvent &&
      dispatch(
        changeQuote(
          vehicleId,
          quoteId,
          { trades, ...extraQuoteData },
          {
            ...options,
            squeeze: false,
          },
        ),
      )

    return dispatch(hydrateCustomer({ trades }, options))
  },
  gtmPushCustomerKnown = () => (dispatch, getState) => {
    let { source, campaign, sessionId } = getCustomer(getState()),
      payload = {
        occured_at: new Date().getTime(),
        source,
        campaign,
        session_id: sessionId,
      }

    window.dataLayer.push({ event: 'Customer_became_known', ...payload })

    dispatch({ type: 'GTM_PUSH', payload })
  },
  gtmPush = payload => dispatch => {
    dispatch({ type: 'GTM_PUSH', payload })

    window.dataLayer.push({ event: 'View', ...payload })
  },
  transmit = () => (dispatch, getState) => {
    if (!shouldTrack(getState())) return

    let rooftopKey = getRooftopKey(getState(), ILLUMINATIONS_KEY)

    rooftopKey &&
      NDB.get(rooftopKey)
        .then(data => {
          if (!isEmpty(data)) {
            let body = Object.values(data)
            body.forEach(event => {
              dispatch(sendIllumination(event))
            })
          }

          return data
        })
        .catch(e => {
          console.error('Error in transmission')
        })
        .finally(() => {
          // may result in duplicates sent, but is a means of retrying messages that fail to send initially
          window.setTimeout(() => dispatch(transmit()), 30000)
        })
  },
  flushByType = (key, ids) => (dispatch, getState) => {
    let rooftopKey = getRooftopKey(getState(), key)

    key === 'customer' && dispatch({ type: SET_TAGGED_CUSTOMER, payload: {} })
    NDB.flush(rooftopKey, ids)

    return dispatch({ type: 'FLUSH_BY_TYPE', payload: { key, ids } })
  },
  resetCustomerTag = (vehicleId, quoteId) => dispatch => {
    vehicleId && quoteId && dispatch(resetQuote(vehicleId, quoteId))

    dispatch(flushByType('customer'))
    dispatch(goHome())
  },
  getEventsForQuote = (quoteId, showLoader) => (dispatch, getState) => {
    if (shouldTrack(getState())) return

    showLoader &&
      dispatch({ type: SET_LOADING_QUOTE_INTERACTIONS, payload: true })

    return dispatch(GET.interactions({ queryStringParameters: { quoteId } }))
      .then(data => {
        let payload = (data || []).reduce(
          (ret, d) => ({
            ...ret,
            [d.id]: {
              _entityType: 'event',
              ...d,
            },
          }),
          {},
        )

        dispatch({
          type: MERGE_ENTITIES,
          payload,
        })
      })
      .then(() =>
        dispatch({ type: SET_LOADING_QUOTE_INTERACTIONS, payload: false }),
      )
  },
  flush = key => () => NDB.flush(key),
  getAggregateStats = () => (dispatch, getState) => {
    return dispatch(
      GET.aggregateStats({
        queryStringParameters: dateStringtoMsRange(
          getSelectedDateRange(getState()),
        ),
      }),
    ).then(r => {
      dispatch({ type: UPDATE_AGGREGATES, payload: r })
    })
  },
  getAllIlluminations = (dateRange, eventType) => (dispatch, getState) => {
    dateRange = dateRange || getSelectedDateRange(getState())
    eventType = eventType || getSelectedEventType(getState()) || null

    let queryStringParameters = dateStringtoMsRange(dateRange)
    if (eventType) queryStringParameters.eventType = eventType

    return dispatch(
      GET.interactions({
        queryStringParameters,
      }),
    ).then(r => {
      dispatch({
        type: REPLACE_ILLUMINATIONS,
        payload: Object.keys(r || {}).reduce((ret, k) => [...ret, r[k]], []),
      })
    })
  },
  getMapData = (dateRange, eventType) => (dispatch, getState) => {
    dateRange = dateRange || getSelectedDateRange(getState())
    eventType = eventType || getSelectedEventType(getState()) || null

    let queryStringParameters = dateStringtoMsRange(dateRange)
    if (eventType) queryStringParameters.eventType = eventType

    return dispatch(
      GET.mapStats({
        queryStringParameters,
      }),
    ).then(r =>
      dispatch({
        type: UPDATE_MAP_STATS,
        payload: r,
      }),
    )
  },
  submitGravityForm = lead => (dispatch, getState) => {
    let keyDict = {
      firstName: 'input_1_3',
      lastName: 'input_1_6',
      phone: 'input_3',
      email: 'input_2',
      rooftopName: 'input_6',
      role: 'input_4',
      how: 'input_5',
    },
      postData = Object.keys(keyDict).reduce(
        (ret, k) => ({ ...ret, [keyDict[k]]: lead[k] }),
        {},
      ),
      axiosOptions = {
        method: 'POST',
        data: postData,
        url: GRAVITY_FORMS_URL,
      }

    postData['input_8'] = window.document.referrer

    if (
      getEnvName() === 'production' &&
      SALES_ROOFTOPS.includes(getRooftopId(getState()))
    ) {
      dispatch({ type: 'SUBMIT_GRAVITY_FORM', payload: postData })
      axios(axiosOptions)
    } else {
      console.warn('Skipping gravity form submit', postData)
    }
  }

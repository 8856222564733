import { info as uInfo, error as uError } from 'utils'

/**
 This file contains code to maintain a websocket-based connection to the server.

 It exhibits the following behaviors:
 - if the connection has been active recently due to messages received from the server, it relies on that recency to trust that the connection is healthy.
 - if the connection has not been active recently enough, it sends a ping to the server and expects a pong in return
 - if the server does not send a pong back in time it closes the current connection and creates a new one
 - if consecutive connection failures occur, it begins to apply randomly-jittered exponential backoff between connection attempts to avoid overwhelming the server with a thundering herd of connection requests 
 - whenever the connection is established and healthy, any subscriptions that have been requested are automatically re-setup again with the server, in this way subscriptions are persistent past the lifetime of a single connection
 */

let DEFAULT_WELCOME_DEADLINE_DURATION = 5 * 1000 // must receive a welcome within 5 seconds of a connect attempt
let DEFAULT_PING_THRESHOLD_DURATION = 30 * 1000 // 30 seconds of inactivity requires a ping
let DEFAULT_PONG_DEADLINE_DURATION = 5 * 1000 // must receive a pong within 5 seconds of a ping

let DEFAULT_INITIAL_RETRY_INTERVAL = 1 * 1000 // wait no less than 1 second between connect retries
let DEFAULT_MAX_RETRY_INTERVAL = 80 * 1000 // wait no longer than 80 seconds between connect retries
let DEFAULT_BACKOFF_FACTOR = 2 // increase back-off interval based on powers of 2

let managerIdCounter = 0

function info(ns, state, message, ...args) {
  let all = [`${new Date()} ${ns} [${state.managerId}]- ${message}`].concat(
    args,
  )
  uInfo.apply(null, all)
}

function error(ns, state, message, ...args) {
  let all = [`${new Date()} ${ns} [${state.managerId}]- ${message}`].concat(
    args,
  )
  uError.apply(null, all)
}

let NS_MANAGER = 'manager.fn',
  NS_WS = 'manager.ws'

// state operators

function makeState(endpointUrl, deliveryListenerFn, statusListenerFn) {
  return {
    // configs
    endpointUrl: endpointUrl,
    deliveryListenerFn: deliveryListenerFn,
    statusListenerFn: statusListenerFn,
    subscriptions: new Set(),

    welcomeDeadlineDuration: DEFAULT_WELCOME_DEADLINE_DURATION,
    pingThresholdDuration: DEFAULT_PING_THRESHOLD_DURATION,
    pongDeadlineDuration: DEFAULT_PONG_DEADLINE_DURATION,
    baseConnectRetryInterval: DEFAULT_INITIAL_RETRY_INTERVAL,
    maxConnectRetryInterval: DEFAULT_MAX_RETRY_INTERVAL,
    connectRetryBackoffFactor: DEFAULT_BACKOFF_FACTOR,

    // socket attributes
    ws: null,
    connectInstant: null,
    welcomeInstant: null,
    lastActivityInstant: null,
    sendCounter: 0,
    receipts: new Map(),
    sendHeaders: {},

    // management attributes
    manageFn: null,
    timeoutId: null,
    consecutiveFailedConnectAttempts: 0,
    status: STATUS_UNKNOWN,
    shutdown: false,
    terminated: false,
    managerId: managerIdCounter++,
  }
}

function getWelcomeDeadline(state) {
  return state.connectInstant + state.welcomeDeadlineDuration
}

function welcomeDeadlineMissed(state, now) {
  let deadline = getWelcomeDeadline(state)
  return now > deadline
}

function getPingThreshold(state) {
  return state.lastActivityInstant + state.pingThresholdDuration
}

function pingThresholdReached(state, now) {
  let lastActivityDuration = now - state.lastActivityInstant
  return lastActivityDuration > state.pingThresholdDuration
}

function getPongDeadline(state) {
  return (
    state.lastActivityInstant +
    state.pingThresholdDuration +
    state.pongDeadlineDuration
  )
}

function pongDeadlineMissed(state, now) {
  let lastActivityDuration = now - state.lastActivityInstant
  return (
    lastActivityDuration >
    state.pingThresholdDuration + state.pongDeadlineDuration
  )
}

function backoffSleepFn(cap, base, attempt) {
  let linearSleep = Math.min(cap, base * 2 ** attempt)
  let randomSleep = Math.random() * linearSleep
  return randomSleep
}

function getBackoffDuration(state, now) {
  if (state.consecutiveFailedConnectAttempts > 0) {
    let backoff = backoffSleepFn(
        state.maxConnectRetryInterval,
        state.baseConnectRetryInterval,
        state.consecutiveFailedConnectAttempts,
      ),
      backoffUntil = state.connectInstant + backoff
    if (backoffUntil > now) {
      let duration = backoffUntil - now
      return duration
    }
  }
  return 0
}

function connectionAttempted(state) {
  return state.connectInstant != null
}

function connectionIsClosed(state) {
  return (
    state.ws != null &&
    (state.ws.readyState === WebSocket.CLOSED ||
      state.ws.readyState === WebSocket.CLOSING)
  )
}

function connectionIsOpen(state) {
  return state.ws != null && state.ws.readyState === WebSocket.OPEN
}

function hasReceivedWelcome(state) {
  return state.welcomeInstant != null
}

function backoffInProgress(state, now) {
  return state.backoffUntilInstant != null && state.backoffUntilInstant > now
}

function backoffCompleted(state, now) {
  return state.backoffUntilInstant != null && state.backoffUntilInstant <= now
}

function backoffDurationRemaining(state, now) {
  return state.backoffUntilInstant - now
}

function send(state, message) {
  state.ws.send(JSON.stringify(message))
}

function sendPing(state) {
  send(state, { type: 'ping' })
}

function sendSetSubs(state, subscriptions) {
  send(state, { type: 'set-subs', subs: Array.from(subscriptions) })
}

function notifyManager(state) {
  state.manageFn()
}

// manager event handlers

function onConnectionAttemptFailed(state) {
  state.consecutiveFailedConnectAttempts++
}

function onBackoff(state, now, duration) {
  state.backoffUntilInstant = now + duration
}

function onBackoffCompleted(state) {
  state.backoffUntilInstant = null
}

// websocket event handlers

function onConnectionClosed(state) {
  info(NS_WS, state, 'connection closed')
  notifyManager(state)
}

function onWelcomeReceived(state, m) {
  state.connId = m['conn-id']
  info(NS_WS, state, 'welcome received', state.connId)

  let now = Date.now()
  state.welcomeInstant = now
  state.lastActivityInstant = now
  state.consecutiveFailedConnectAttempts = 0

  sendSetSubs(state, Array.from(state.subscriptions))
  notifyManager(state)
}

function onPongReceived(state) {
  info(NS_WS, state, 'pong received')
  state.lastActivityInstant = Date.now()
  notifyManager(state)
}

function onSubDeliveryReceived(state, m) {
  info(NS_WS, state, 'delivery received')
  state.lastActivityInstant = Date.now()
  state.deliveryListenerFn(m)
  notifyManager(state)
}

function onReceiptReceived(state, m) {
  let receiptId = m.headers['receipt-id']
  let receipt = state.receipts.get(receiptId)

  info(NS_WS, state, 'receipt received: ' + receiptId)
  state.lastActivityInstant = Date.now()

  state.receipts.delete(receiptId)
  receipt.deliverySuccessFn()
  notifyManager(state)
}

function onError(state, m) {
  if (m && m.headers) {
    let msg = ''
    if (m.headers.message) {
      msg = m.headers.message
    } else {
      msg = 'error'
    }

    error(NS_WS, state, msg, m)

    let receiptId = m.headers['receipt-id']
    if (receiptId) {
      let receipt = state.receipts.get(receiptId)
      if (receipt) {
        receipt.deliveryFailureFn()
        state.receipts.delete(receiptId)
      }
    }
  } else {
    console.error(m)
  }
}

function onMessageReceived(state, data) {
  let m = JSON.parse(data),
    type = m.type,
    process = {
      welcome: () => {
        onWelcomeReceived(state, m)
      },
      error: () => {
        onError(state, m)
      },
      pong: () => {
        onPongReceived(state)
      },
      'sub-delivery': () => {
        onSubDeliveryReceived(state, m)
      },
      receipt: () => {
        onReceiptReceived(state, m)
      },
    }
  type && process[type] && process[type]()
}

// health event handlers

export const STATUS_UNKNOWN = 'unknown',
  STATUS_HEALTHY = 'healthy',
  STATUS_UNHEALTHY = 'unhealthy'

function determineStatus(state) {
  if (state.consecutiveFailedConnectAttempts > 2) {
    return STATUS_UNHEALTHY
  } else {
    return STATUS_HEALTHY
  }
}

function hasStatusChanged(state, newStatus) {
  return state.status !== newStatus
}

function onStatusChange(state, newStatus) {
  let oldStatus = state.status
  state.status = newStatus
  info(
    NS_MANAGER,
    state,
    'connection state changed: ' + oldStatus + ' -> ' + newStatus,
  )
  state.statusListenerFn(newStatus, oldStatus)
}

// management actions, each returns how long to wait before running manageFn again

function actionWelcomeDeadline(state, now) {
  let when = getWelcomeDeadline(state)
  let duration = when - now
  info(
    NS_MANAGER,
    state,
    'waiting for welcome deadline (in ' + duration + 'ms)',
  )
  return duration
}

function actionPingThreshold(state, now) {
  let when = getPingThreshold(state)
  let duration = when - now
  info(NS_MANAGER, state, 'waiting for ping threshold (in ' + duration + 'ms)')
  return duration
}

function makeActionPongDeadline(state, now) {
  let when = getPongDeadline(state)
  let duration = when - now
  info(NS_MANAGER, state, 'waiting for pong deadline (in ' + duration + 'ms)')
  return duration
}

function actionConnect(state, now) {
  if (!connectionAttempted(state)) {
    info(NS_MANAGER, state, 'connecting')
  } else {
    info(NS_MANAGER, state, 're-connecting')
  }

  // clean up any existing socket
  if (state.ws) {
    if (
      state.ws.readyState === WebSocket.CONNECTING ||
      state.ws.readyState === WebSocket.OPEN
    ) {
      state.ws.close()
    }
  }

  let ws = new WebSocket(state.endpointUrl)
  ws.onclose = () => {
    onConnectionClosed(state)
  }
  ws.onmessage = event => {
    onMessageReceived(state, event.data)
  }

  state.ws = ws
  state.connectInstant = now
  state.welcomeInstant = null
  state.lastActivityInstant = null

  return actionWelcomeDeadline(state, now)
}

function actionBackoff(state, duration) {
  info(
    NS_MANAGER,
    state,
    'backing-off retries after ' +
      state.consecutiveFailedConnectAttempts +
      ' attempt(s) (will resume in ' +
      duration +
      'ms)',
  )
  return duration
}

function shutdown(state) {
  info(NS_MANAGER, state, 'shutting down')
  if (state.ws) {
    if (
      state.ws.readyState === WebSocket.CONNECTING ||
      state.ws.readyState === WebSocket.OPEN
    ) {
      state.ws.close()
    }
    state.ws = null
  }
  state.terminated = true
  return null
}

// determine the next action the management fn should take based on the current state

function nextAction(state) {
  if (state.shutdown) {
    return shutdown(state)
  }

  let now = Date.now()

  if (!connectionAttempted(state)) {
    info(NS_MANAGER, state, 'no connection present: actionConnect')
    return actionConnect(state, now)
  } else if (connectionIsClosed(state)) {
    if (state.receipts.size > 0) {
      info(
        NS_MANAGER,
        state,
        `closed connection has ${state.receipts.size} outstanding receipts, reporting failures`,
      )
      for (const receipt of state.receipts.values()) {
        try {
          receipt.deliveryFailureFn()
        } catch (e) {
          info(
            NS_MANAGER,
            state,
            `failed to deliver receipt failure to receipt callback: ${e}`,
          )
        }
      }
      state.receipts.clear()
    }

    if (backoffInProgress(state, now)) {
      info(
        NS_MANAGER,
        state,
        'socket found closed but backoff not yet complete: actionBackoff',
      )
      let duration = backoffDurationRemaining(state, now)
      return actionBackoff(state, duration)
    } else if (backoffCompleted(state, now)) {
      info(
        NS_MANAGER,
        state,
        'socket found closed and backoff complete: actionConnect',
      )
      onBackoffCompleted(state)
      return actionConnect(state, now)
    } else {
      let msg = ''
      if (!hasReceivedWelcome(state)) {
        msg = 'connection attempt failed'
        onConnectionAttemptFailed(state)
      } else {
        msg = 'socket found closed'
      }

      let backoffDuration = getBackoffDuration(state, now)
      if (backoffDuration > 0) {
        info(NS_MANAGER, state, msg + ': actionBackoff')
        onBackoff(state, now, backoffDuration)
        return actionBackoff(state, backoffDuration)
      } else {
        info(NS_MANAGER, state, msg + ': actionConnect')
        return actionConnect(state, now)
      }
    }
  } else if (!hasReceivedWelcome(state)) {
    if (welcomeDeadlineMissed(state, now)) {
      info(NS_MANAGER, state, 'passed welcomeDeadline: actionConnect')
      onConnectionAttemptFailed(state)
      return actionConnect(state, now)
    } else {
      info(NS_MANAGER, state, 'welcomeDeadline not yet reached')
      return actionWelcomeDeadline(state, now)
    }
  } else if (pongDeadlineMissed(state, now)) {
    info(NS_MANAGER, state, 'passed pongDeadline')
    return actionConnect(state, now)
  } else if (pingThresholdReached(state, now)) {
    info(NS_MANAGER, state, 'sending ping')
    sendPing(state)
    return makeActionPongDeadline(state, now)
  } else {
    return actionPingThreshold(state, now)
  }
}

export function makeManager(endpointUrl, deliveryListenerFn, statusListenerFn) {
  let state = makeState(endpointUrl, deliveryListenerFn, statusListenerFn)
  state.manageFn = function manageFn() {
    if (!state.terminated) {
      let nextRunInterval = nextAction(state)

      let status = determineStatus(state)
      if (hasStatusChanged(state, status)) {
        onStatusChange(state, status)
      }

      if (state.timeoutId != null) {
        window.clearTimeout(state.timeoutId)
      }
      if (nextRunInterval != null) {
        state.timeoutId = window.setTimeout(state.manageFn, nextRunInterval)
      }
    }
  }
  state.sendMessage = (
    destination,
    body,
    deliverySuccessFn,
    deliveryFailureFn,
  ) => {
    sendMessage(state, destination, body, deliverySuccessFn, deliveryFailureFn)
  }
  return state
}

export function startManager(state) {
  state.manageFn()
}

export function stopManager(state) {
  state.shutdown = true
  state.manageFn()
}

export function setSubscriptions(state, subscriptions) {
  state.subscriptions = new Set()
  for (let s of subscriptions) {
    state.subscriptions.add(s)
  }
}

export function setSendHeaders(state, headers) {
  state.sendHeaders = Object.assign({}, headers)
}

export function sendMessage(
  state,
  destination,
  body,
  deliverySuccessFn,
  deliveryFailureFn,
) {
  if (state.shutdown) {
    error(NS_MANAGER, state, 'cannot send message, manager is shutting down')
    deliveryFailureFn()
    return
  }

  if (state.status === STATUS_UNHEALTHY) {
    error(NS_MANAGER, state, 'cannot send message, connection not healthy')
    deliveryFailureFn()
    return
  }

  if (!connectionIsOpen(state)) {
    error(NS_MANAGER, state, 'cannot send message, connection is not open')
    deliveryFailureFn()
    return
  }

  let receiptId = state.sendCounter.toString(),
    receipt = {
      deliverySuccessFn: deliverySuccessFn,
      deliveryFailureFn: deliveryFailureFn,
    },
    msg = {
      type: 'send',
      headers: {
        ...state.sendHeaders,
        ...{
          destination: destination,
          receipt: receiptId,
        },
      },
      body: body,
    }

  state.receipts.set(receiptId, receipt)
  try {
    info(
      NS_MANAGER,
      state,
      'sending message to destination ' +
        destination +
        ' with receipt ' +
        receiptId,
      msg,
    )
    send(state, msg)
    state.sendCounter++
  } catch (e) {
    state.receipts.delete(receiptId)
    throw e
  }

  return receiptId
}

import Auth from '@aws-amplify/auth'
import API from '@aws-amplify/api'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import promiseRetry from 'promise-retry'

import {
  XHR_PENDING,
  SET_OUTSTANDING_XHR,
  READY,
  RETRY_TICK,
} from 'reducers/appStatusReducers'

import { updateAppStatus } from 'actions/appStatusActions'
import {
  isActiveRequest,
  getRequest,
  getRooftopId,
} from 'selectors/appStatusSelectors'

import { getIsLoggedIn } from 'selectors/authSelectors'
import { redirect } from 'actions/pageStateActions'

import {
  ns,
  makeRequestKey,
  makeRequestTimestamp,
  easyThen,
  easyCatch,
  unauthed,
  dupeRequest,
  staleRequest,
  warn,
} from 'utils'

export const RETRY_ERRORS = [
  'java.io.IOException: Connection reset by peer',
  'java.io.IOException: Premature EOS, presumed disconnect',
  //'Network Error',
  'ECONNABORTED',
]

export const RETRY_LIMIT = 20

const REQUEST = ({
  method,
  authRequired = false,
  showLoader = true,
  canRetry = true,
  dupeCheck = true,
  includeRooftop = true,
  throwErrors = false,
  rawResponse = false,
  authExcluded = false,
}) => path => (myInit, urlParams, getKnownState = () => ({})) => (
  dispatch,
  getState,
) => {
  let requestTimeStamp = makeRequestTimestamp(),
    knownState = getKnownState(getState()),
    requestData = {
      method,
      myInit,
      path,
      urlParams,
      authRequired,
    },
    retryCheck = (retry, number) => err => {
      if (
        canRetry &&
        ((err.response && RETRY_ERRORS.includes(err.response.data)) ||
          RETRY_ERRORS.includes(err.message) ||
          RETRY_ERRORS.includes(err.code))
      ) {
        warn('Retrying...', err)
        dispatch({ type: RETRY_TICK, payload: number })

        retry(err)
      } else {
        warn('NOT Retrying, error unknown [' + JSON.stringify(err) + ']')

        throw err
      }
    }

  if (urlParams)
    Object.keys(urlParams).forEach(
      k => (path = path.replace(`{${k}}`, urlParams[k])),
    )

  let requestKey = makeRequestKey(method, path, myInit, showLoader)

  const makeRequest = authRequired => (auth = {}) => {
    let { idToken = {} } = auth,
      headers = {}

    if (includeRooftop) {
      headers['X-Frikin-Rooftop'] = getRooftopId(getState())
    }

    if (authRequired && isEmpty(idToken)) throw unauthed(requestData)

    if (dupeCheck && isActiveRequest(getState(), requestKey))
      throw dupeRequest(requestData)

    if (idToken && !authExcluded)
      headers.Authorization = `Bearer ${idToken.jwtToken}`

    dispatch(updateAppStatus(XHR_PENDING))

    dupeCheck &&
      dispatch({
        type: SET_OUTSTANDING_XHR,
        payload: {
          [requestKey]: requestTimeStamp,
        },
      })

    return promiseRetry(
      (retry, retryCount) => {
        return API[method](ns, path, {
          ...myInit,
          headers,
          response: rawResponse,
        }).catch(retryCheck(retry, retryCount))
      },
      {
        factor: 2,
        minTimeout: 150,
        maxTimeout: 2500,
        randomize: true,
        retries: RETRY_LIMIT,
      },
    )
      .then(r => {
        if (
          (dupeCheck && !getRequest(getState(), requestKey)) ||
          !isEqual(knownState, getKnownState(getState()))
        ) {
          throw staleRequest(requestData)
        } else {
          return r
        }
      })
      .then(easyThen(dispatch, requestKey))
      .catch(easyCatch(dispatch, requestKey, throwErrors))
  }

  return (authRequired || getIsLoggedIn(getState())
    ? Auth.currentSession()
    : // eslint-disable-next-line
    Promise.resolve()
  )
    .then(makeRequest(authRequired))
    .catch(e => {
      warn(e, path)
      dispatch(updateAppStatus(READY, e))

      // TODO there are some requests that happen when the user isn't logged in,
      // such as getQuotesForVehicle that might trigger a signout,so it tries to
      // see if app state thinks the user is logged in as part of the check
      if (
        getIsLoggedIn(getState()) &&
        authRequired &&
        (e.message === 'unauthed' || e === 'No current user')
      )
        dispatch(redirect('/', true))

      if (throwErrors) {
        throw e
      }
    })
}

// method,
// authRequired = false,
// showLoader = true,
// canRetry = true,
// dupeCheck = true,
// includeRooftop = true,

let R = REQUEST,
  P = R({ method: 'post' }),
  G = R({ method: 'get' })

export const GET = {
  vehicleDetails: (...args) => G('/vehicles/uuid/{uuid}')(...args),
  quotes: (...args) =>
    R({ method: 'get', authRequired: true })('/quotes')(...args),
  quote: (...args) => G('/quotes/uuid/{uuid}')(...args),
  quotesForVehicle: (...args) =>
    R({ method: 'get', authRequired: true })('/quotes?vehicle={vehicleId}')(
      ...args,
    ),
  quotesForVehicleNoLoader: (...args) =>
    R({ method: 'get', authRequired: true, showLoader: false })(
      '/quotes?vehicle={vehicleId}',
    )(...args),
  userData: (...args) =>
    R({ method: 'get', authRequired: true })('/user')(...args),
  rooftop: (...args) => G('/rooftops/code/{rooftopId}')(...args),
  allLendersForConfig: (...args) => G('/configs/lenders/all')(...args),
  vehicleRebates: (...args) =>
    G('/vehicles/uuid/{vId}/rebates/zipcode/{zipcode}')(...args),
  marketForZip: (...args) =>
    R({
      method: 'get',
      authRequired: false,
      showLoader: false,
      canRetry: true,
      dupeCheck: false,
    })('/programs/markets/zip/{zipcode}')(...args),

  vehiclesMulti: (...args) => G('/vehicles/multi')(...args),
  retryTest: (...args) => G('/dev/retry-test')(...args),
  interactions: (...args) =>
    R({ method: 'get', authRequired: true })('/interactions')(...args),
  aggregateStats: (...args) =>
    R({ method: 'get', authRequired: true })('/stats/aggregates')(...args),
  mapStats: (...args) =>
    R({ method: 'get', authRequired: true })('/stats/zipcode')(...args),
  usersForConfig: (...args) =>
    R({ method: 'get', authRequired: true })('/users')(...args),
  userByEmail: (...args) =>
    R({ method: 'get', authRequired: true })('/user/search/{email}')(...args),
  userById: (...args) =>
    R({ method: 'get', authRequired: true })('/user/id/{id}')(...args),
  roles: (...args) =>
    R({ method: 'get', authRequired: true })('/roles')(...args), // TODO may not need auth
  configsForRooftop: (...args) =>
    R({ method: 'get', authRequired: true, showLoader: false })(
      '/config/category/{category}',
    )(...args),
  configById: (...args) =>
    R({ method: 'get', authRequired: true, showLoader: false })('/config/{id}')(
      ...args,
    ),
  configMakes: (...args) => G('/config/options/makes')(...args),
  configModelsForMake: (...args) => G('/config/options/models')(...args),
  configTrimsForModel: (...args) => G('/config/options/trims')(...args),
  vehicleSummary: (...args) =>
    R({
      method: 'get',
      authRequired: false,
      showLoader: false,
      canRetry: false,
    })('/link/decoder')(...args),
  leadDetails: (...args) =>
    R({
      method: 'get',
      authRequired: false,
      showLoader: false,
      canRetry: false,
    })('/leads/details/{sessionId}')(...args),
  leads: (...args) =>
    R({ method: 'get', authRequired: true })('/leads/listing')(...args),
  taxFeeOptionsByZip: (...args) => G('/taxes/{zip}')(...args),
  availableRooftopsForUser: (...args) => G('/list-rooftops')(...args),
  showAndTell: (...args) =>
    R({ method: 'get', authRequired: true })('/config/rooftop/show-and-tell')(
      ...args,
    ),
  getPortkeyRoutes: (...args) =>
    R({
      method: 'get',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/get-portkey-routes')(...args)
}

export const POST = {
  vehicles: (...args) => P('/list-vehicles')(...args),
  evoxImage: (...args) =>
    R({ method: 'post', canRetry: false })('/vehicles/evox')(...args),
  vehiclesMdrive: (...args) => P('/list-vehicles-mdrive')(...args),
  vehiclesMultiMdrive: (...args) => P('/get-vehicles-mdrive')(...args),
  vehiclesExact: (...args) => P('/vehicles/search')(...args),
  vehiclesExactMdrive: (...args) => P('/search-vehicles-mdrive')(...args),
  vehicleFilters: (...args) => P('/vehicles/filters')(...args),
  vehicleFiltersMdrive: (...args) => P('/get-vehicle-filters-mdrive')(...args),
  saveQuote: (...args) => P('/quotes')(...args),
  quoteLink: (...args) => P('/link')(...args),
  configs: (...args) =>
    R({ method: 'post', authRequired: true })('/configs')(...args),
  config: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      showLoader: true,
      throwErrors: true,
    })('/config')(...args), // new one
  programsForQuote: (...args) => P('/programs/quote')(...args),
  programsForCustomQuote: (...args) => P('/programs/roll')(...args),
  rollResults: (...args) => P('/quotes/roll')(...args),
  programInfo: (...args) => P('/programs/info')(...args),
  interactions: (...args) =>
    R({ method: 'post', authRequired: false, showLoader: false })(
      '/interactions',
    )(...args),
  user: (...args) =>
    R({ method: 'post', authRequired: true })('/user')(...args),
  userPref: (...args) =>
    R({ method: 'post', authRequired: true })('/user/id/{id}/preferences')(
      ...args,
    ),
  showAndTell: (...args) => P('/config/rooftop/show-and-tell')(...args),
  getWebsiteConfigs: (...args) =>
    R({ method: 'post', authRequired: true, includeRooftop: false })(
      '/get-website-configs',
    )(...args),
  updateWebsiteConfig: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/update-website-config')(...args),
  deleteWebsiteConfig: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/delete-website-config')(...args),
  generateWebsitePaymentFile: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/generate-website-payment-file')(...args),
  getRooftopScripts: (...args) =>
    R({ method: 'post', authRequired: true })('/get-rooftop-scripts')(...args),
  updateRooftopScripts: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      canRetry: false,
      throwErrors: true,
    })('/update-rooftop-scripts')(...args),
  getTenants: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/get-tenants')(...args),
  createTenant: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/create-tenant')(...args),
  updateTenant: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/update-tenant')(...args),
  deleteTenant: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/delete-tenant')(...args),
  getRooftops: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/get-rooftops')(...args),
  createRooftop: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/create-rooftop')(...args),
  updateRooftop: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/update-rooftop')(...args),
  disableRooftop: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/disable-rooftop')(...args),
  reenableRooftop: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/reenable-rooftop')(...args),
  deleteRooftop: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/delete-rooftop')(...args),
  rebateDescriptions: (...args) => P('/get-rebate-descriptions')(...args),
  getFeeds: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/get-feeds')(...args),
  createFeed: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/create-feed')(...args),
  updateFeed: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/update-feed')(...args),
  deleteFeed: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/delete-feed')(...args),
  getServiceIqEmailSummary: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/get-serviceiq-email-summary')(...args),
  getReportDownloadLink: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/get-report-download-link')(...args),
  getHomenetFeeds: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/get-homenet-feeds')(...args),
  createHomenetFeed: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/create-homenet-feed')(...args),
  updateHomenetFeed: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/update-homenet-feed')(...args),
  deleteHomenetFeed: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/delete-homenet-feed')(...args),
  getServiceIQSimulatorParams: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/get-serviceiq-simulator-params')(...args),
  simulateServiceIQ: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/simulate-serviceiq')(...args),
  getVehicleDetailsMDrive: (...args) =>
    R({
      method: 'post',
      authRequired: false,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/get-vehicle-details-mdrive')(...args),
  getProgramsForQuoteMDrive: (...args) =>
    R({
      method: 'post',
      authRequired: false,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/get-programs-for-quote-mdrive')(...args),
  rollProgramsCashMDrive: (...args) =>
    R({
      method: 'post',
      authRequired: false,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/roll-programs-cash-mdrive')(...args),
  rollQuoteMDrive: (...args) =>
    R({
      method: 'post',
      authRequired: false,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/roll-quote-mdrive')(...args),
  getProgramInfoMDrive: (...args) =>
    R({
      method: 'post',
      authRequired: false,
      includeRooftop: true,
      canRetry: false,
      throwErrors: true,
    })('/get-program-info-mdrive')(...args),
  savePortkeyRoute: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/save-portkey-route')(...args),
  deletePortkeyRoute: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/delete-portkey-route')(...args),
  syncSettingsToMarketscan: (...args) =>
    R({
      method: 'post',
      authRequired: true,
      includeRooftop: false,
      canRetry: false,
      throwErrors: true,
    })('/sync-settings-to-marketscan')(...args),
}

export const HEAD = {}
export const PUT = {}

export const DELETE = {
  user: (...args) =>
    R({ method: 'del', authRequired: true })('/user/id/{id}')(...args),
  configById: (...args) =>
    R({ method: 'del', authRequired: true, showLoader: false })('/config/{id}')(
      ...args,
    ),
}

export const echo = code => () =>
  R({
    method: 'put',
    includeRooftop: false,
    canRetry: false,
    throwErrors: true,
    authExcluded: true,
  })(`/simulate-response/${code}`)()

export const proxyRequest = (path, opts = {}, body) =>
  R({
    method: 'get',
    authRequired: true,
    showLoader: false,
    canRetry: true,
    dupeCheck: true,
    rawResponse: true,
    ...opts,
  })(path)({ body })

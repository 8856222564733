export const STATE_KEY = 'naughty'

export const UPDATE_AGGREGATES = 'UPDATE_AGGREGATES'
export const UPDATE_ILLUMINATIONS = 'UPDATE_ILLUMINATIONS'
export const REPLACE_ILLUMINATIONS = 'REPLACE_ILLUMINATIONS'
export const UPDATE_MAP_STATS = 'UPDATE_MAP_STATS'

const initialState = {
  aggregateStats: {},
  illuminations: [],
  isGettingStats: false,
}

export default function naughtReducers(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AGGREGATES:
      return { ...state, aggregateStats: action.payload }

    case REPLACE_ILLUMINATIONS:
      return {
        ...state,
        illuminations: action.payload,
      }

    case UPDATE_ILLUMINATIONS:
      // NOTE: here were storing the illuminations like this to prevent memory
      // leak runaway, may need to condier those for quote level interactions
      // too

      return {
        ...state,
        illuminations: Object.values(
          [
            ...state.illuminations,
            ...action.payload,
          ]
          .reduce((ret, illumination) => ({...ret, [illumination.id]: illumination}), {})
        )
        .sort((a, b) => +b.at - +a.at)
        .slice(0, 100)
      }

    case UPDATE_MAP_STATS:
      return { ...state, mapStats: action.payload }

    default:
      return state
  }
}

export const STATE_KEY = 'dashboard'

export const LOADING_VEHICLES = 'LOADING_VEHICLES'
export const LOADED_VEHICLES = 'LOADED_VEHICLES'
export const LOADING_DEALS = 'LOADING_DEALS'
export const LOADED_DEALS = 'LOADED_DEALS'
export const LOADING_FILTERS = 'LOADING_FILTERS'
export const LOADED_FILTERS = 'LOADED_FILTERS'
export const LOADED_ALL_FILTERS = 'LOADED_ALL_FILTERS'
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'

export const INVENTORY_FILTER_NS = 'inventory'

const initialState = {
  vehicleIds: [],
  dealIds: [],
  loading: false,
  loadingFilters: false,
  filters: [],
  allFilters: [],
  selectedFilters: {},
}

const filtersFilter = f => !f.groupWidth || f.groupWidth === 1

export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_VEHICLES:
      return { ...state, loading: true }

    case LOADING_DEALS:
      return { ...state, loading: true }

    case LOADED_VEHICLES:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    case LOADING_FILTERS:
      return { ...state, loadingFilters: true }

    case LOADED_FILTERS:
      return {
        ...state,
        filters: (action.payload || []).filter(filtersFilter),
        loadingFilters: false,
      }

    case LOADED_ALL_FILTERS:
      return {
        ...state,
        allFilters: (action.payload || []).filter(filtersFilter),
      }

    case LOADED_DEALS:
      return { ...state, dealIds: action.payload, loading: false }

    case SET_SELECTED_FILTERS:
      return { ...state, selectedFilters: action.payload }

    default:
      return state
  }
}

export default inventoryReducer

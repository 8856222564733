import React from 'react'
import set from 'lodash/set'

export const initialFee = {
    description: '',
    value: '',
    loan: true,
    lease: true,
    taxable: false,
    inCap: false,
    taxInCap: false,
  },
  initialAdd = {
    name: '',
    description: '',
    price: 0,
    retail: 0,
    cost: 0,
    include: false,
    show: false,
  },
  initialTrade = {
    allowance: 0,
    payoff: 0,
    acv: 0,
  },
  initialDiscount = {
    name: '',
    amount: 0,
    include: false,
    show: false,
  }

export const INTENTS = {
  accepted: 'accepted',
  created: 'created',
  rejected: 'rejected',
  getOTD: 'getOTD',
  revised: 'revised',
  rolled: 'rolled',
  helpRequested: 'helpRequested',
  shared: 'shared',
  customize: 'customize',
  cashRejected: 'cashRejected',
  contact: 'contact',
  getCashPrice: 'getCashPrice',
  holdDeal: 'holdDeal',
  buyNow: 'buyNow',
  tradeAppraisal: 'tradeAppraisal',
  magicLink: 'magicLink',

  accepted2: 'accepted2', // V2 forms
  buyNow2: 'buyNow2', // V2 forms
  tradeAppraisal2: 'tradeAppraisal2', // V2 forms
  getTaxesAndFees: 'getTaxesAndFees', // V2 forms
  sellCar: 'sellCar'
}

export const INTENTS_STATS_KEYS = {
  accepted: 'quote_reaction_accepted',
  created: 'quote_reaction_created',
  rejected: 'quote_reaction_rejected',
  revised: 'quote_reaction_revised',
  rolled: 'quote_reaction_rolled',
  helpRequested: 'quote_reaction_help_requested',
  shared: 'quote_reaction_shared',
  customize: 'quote_reaction_customize',
  cashRejected: 'quote_reaction_cash_rejected',
  contact: 'quote_reaction_contact',
  getOTD: 'quote_reaction_getOTD',
  getCashPrice: 'quote_reaction_getCashPrice',
  holdDeal: 'quote_reaction_hold_deal',
  buyNow: 'quote_reaction_buy_now',
  tradeAppraisal: 'quote_reaction_trade_appraisal',

  // NOTE: from FSCRUM-1516 note that accepted and 
  // accept2 both have the same stat key etc...
  accepted2: 'quote_reaction_accepted',
  buyNow2: 'quote_reaction_buy_now',
  tradeAppraisal2: 'quote_reaction_trade_appraisal',
  getTaxesAndFees: 'quote_reaction_taxes_andFees'
}

export const REACTIONS = [
  INTENTS.accepted,
  INTENTS.revised,
  INTENTS.getOTD,
  INTENTS.shared,
  INTENTS.rolled,
  INTENTS.cashRejected,
  INTENTS.rejected,
  INTENTS.getCashPrice,
  INTENTS.holdDeal,
  INTENTS.buyNow,
  INTENTS.tradeAppraisal,
]

export const INTENTS_EMOJI = {
  created:         (<span className="emoji" role="img" aria-label="created" >        💡 </span>),
  accepted:        (<span className="emoji" role="img" aria-label="accepted">        😃 </span>),
  rejected:        (<span className="emoji" role="img" aria-label="rejected">        🤣 </span>),
  revised:         (<span className="emoji" role="img" aria-label="revised">         😴 </span>),
  rolled:          (<span className="emoji" role="img" aria-label="rolled">          🤔 </span>),
  helpRequested:   (<span className="emoji" role="img" aria-label="saved">           😅 </span>),
  shared:          (<span className="emoji" role="img" aria-label="shared">          👋 </span>),
  customize:       (<span className="emoji" role="img" aria-label="shared">          💪 </span>),
  cashRejected:    (<span className="emoji" role="img" aria-label="rejected">        🤔 </span>),
  contact:         (<span className="emoji" role="img" aria-label="contact">         🍋 </span>),
  getOTD:          (<span className="emoji" role="img" aria-label="created">         😎 </span>),
  getCashPrice:    (<span className="emoji" role="img" aria-label="created">         🤑 </span>),
  holdDeal:        (<span className="emoji" role="img" aria-label="created">         🤩 </span>),
  buyNow:          (<span className="emoji" role="img" aria-label="created">         🤩 </span>),
  tradeAppraisal:  (<span className="emoji" role="img" aria-label="created">         🚘 </span>),
  magicLink:       (<span className="emoji" role="img" aria-label="magicLink">       🚀 </span>),

  accepted2:       (<span className="emoji" role="img" aria-label="accepted2">       🛞 </span>),
  buyNow2:         (<span className="emoji" role="img" aria-label="buyNow2">         🤩 </span>),
  tradeAppraisal2: (<span className="emoji" role="img" aria-label="tradeAppraisal2"> 🤔 </span>),
  getTaxesAndFees: (<span className="emoji" role="img" aria-label="tradeAppraisal2"> 💰 </span>),
}

export const INTENTS_LABEL_STRINGS = {
  accepted: 'I want to see it!',
  helpRequested: 'I need some help…',
  revised: 'Let me sleep on it…',
  rolled: 'I had a different payment in mind…',
  rejected: 'Are you serious?!',
  shared: 'Share with a friend',
  customize: 'I want to customize this payment!',
  cashRejected: 'I had a different price in mind…',
  created: 'Tell us about yourself!',
  getCashPrice: "What's my price?",
  holdDeal: 'Hold this Deal!',
  buyNow: 'I want to buy this car!',
  tradeAppraisal: 'I want a Trade Appraisal!',

  getOTD: 'Show me personalized payments now!',
  accepted2: "I want a Test Drive!",
  buyNow2: "Check availability",
  tradeAppraisal2: "What's my trade worth?",
  getTaxesAndFees: "How much with Taxes and Fees?",
  sellCar: "Sell My Car"
}

export const INTENTS_LABELS = {
  accepted: (<span> I want to <strong>see</strong> it! </span>),
  helpRequested: (<span> I need some <strong>help</strong>&hellip; </span>),
  revised: (<span> Let me <strong>sleep</strong> on it&hellip; </span>),
  rolled: (<span> I had a <strong>different payment</strong> in mind&hellip; </span>),
  rejected: (<span> Are you <strong>serious</strong>?! </span>),
  shared: (<span> <strong>share</strong> with a friend </span>),
  customize: (<span> I want to <strong>customize</strong> this payment! </span>),
  cashRejected: (<span> I had a <strong>different price</strong> in mind&hellip; </span>),
  created: (<span> Tell us about <strong>yourself</strong>! </span>),
  getOTD: (<span> Show me <strong>personalized</strong> payments <strong>now</strong>! </span>),
  getCashPrice: (<span> What's <strong>my price</strong>? </span>),
  holdDeal: (<span> <strong>Hold this Deal</strong>! </span>),
  buyNow: (<span> I want to <strong>buy this car</strong>! </span>),
  tradeAppraisal: (<span> I want a <strong>Trade Appraisal</strong>! </span>),

  accepted2: (<span> I want a <strong>Test Drive</strong>! </span>),
  buyNow2: (<span> Check availability </span>),
  tradeAppraisal2: (<span> What's my trade worth? </span>),
  getTaxesAndFees: (<span> How much with <strong>taxes</strong> & <strong>fees</strong>? </span>),
  sellCar: (<span> I want to <strong>sell my car</strong>! </span>),
}

export const INTENT_LABELS_SIMPLE_BUTTON_PROPS = {
  [INTENTS.accepted2]: { label: 'I want a Test Drive!', emoji: '🛞' },
  [INTENTS.buyNow2]: { label: 'Check availability', emoji: '🤩' },
  [INTENTS.tradeAppraisal2]: { label: "What's my trade worth?", emoji: '🤔' },
  [INTENTS.getTaxesAndFees]: { label: 'How much with Taxes & Fees?', emoji: '💰' },
  [INTENTS.getOTD]: { label: 'See personalized payments now!', emoji: '😎' },
}

export const STATUS = {
  rolling: 'rolling',
  saving: 'saving',
  ready: 'ready',
}

export const PROFIT_STYLES = {
  reserve: 'reserve',
  rateBump: 'rate',
}

const INITIAL_QUOTE = {
  _entityType: 'quote',
  gettingPrograms: false,
  gotProgramInfo: false,
  status: null,
  msrp: 0,
  adjMsrp: 0,
  price: 0,
  cost: 0,
  invoice: 0,
  advanceThreshold: 0,
  vehicleOptionCode: null,
  modes: [{ label: 'Lease', value: 'lease' }, { label: 'Loan', value: 'loan' }],
  customer: {},
  desiredPayment: 0,
  latest: {}, // the most recent version of the quote
  expirationDays: 7, //TODO config
  expirations: [
    { label: '1 Days', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '14 Days', value: 14 },
  ],

  daysToFirstPayment: 30, //TODO need config
  trades: [initialTrade],

  //customerZipCode: '', // starting value should come from rooftop config

  fees: [],
  additions: [],
  warranties: [],
  maintenancePlans: [],
  discounts: [],

  programs: [],
  selectedRebateCustom: [],
  selectedRebateIds: [],
  selectedRebateValues: {},

  rebateInfo: {}, //mScan rebate api response object
  rebateDetails: {}, //mScan rebate api response object

  downPaymentUsage: 'TOTAL_OUT_OF_POCKET',
  dealerIncentivesReducePrice: false,
  paidReserveReducesPrice: false,

  desiredPaidReserve: 0,
  desiredPaidReserveRange: 0,
  aprMarkup: 0,
  moneyFactorMarkup: 0,
  profitStyle: PROFIT_STYLES.cash,
  acceptLowerMaxRate: false,

  locks: {},
  viewMode: 'simple',
}

export const STATE_KEY = 'quote',
  UPDATE_GLOBAL_QUOTE_VALUE = 'UPDATE_GLOBAL_QUOTE_VALUE'

export default function quoteReducers(state = INITIAL_QUOTE, action) {
  switch (action.type) {
    case UPDATE_GLOBAL_QUOTE_VALUE:
      let newState = { ...state }

      Object.keys(action.payload).forEach(k =>
        set(newState, k, action.payload[k]),
      )

      return newState

    default:
      return state
  }
}

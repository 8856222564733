export const STATE_KEY = 'auth'
export const UPDATE_AUTH = 'user/updateAuth'
export const TOGGLE_ROLE = 'user/toggleRole'
export const SIGN_OUT = 'user/signOut'
export const ADD_ROOFTOP_OPTIONS = 'user/rooftopOptions'

const initialState = {
  cognitoUser: null,
  authData: null,
  userData: null,
  rooftopOptions: null,
}

export const DEFAULT_PERMS = {
  'deal.fields/customize': { r: true, w: true },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AUTH:
      return { ...state, ...action.payload }

    case TOGGLE_ROLE:
      return { ...state, role: state.role === 'customer' ? 'manager' : 'customer' }

   case ADD_ROOFTOP_OPTIONS:
      return { ...state, rooftopOptions: action.payload }

    case SIGN_OUT:
      return {}

    default:
      return state
  }
}

import 'core-js/stable'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import { version } from '../package.json'
import { detect } from 'detect-browser'

import store from './store'
import registerServiceWorker from './registerServiceWorker'
import { getEnvName } from 'config/env.js'

const target = process.env.REACT_APP_BUILD_TARGET || 'standard'

const importBuildTarget = () => {
  if (target === 'helix') return import('targets/helix.js')
  else if (target === 'salesiq') return import('targets/salesiq.js')
  else return import('pages/index.js')
}

importBuildTarget().then(({ default: Component }) =>
  render(
    <Provider store={store}>
      <Component version={version} browser={detect()} />
    </Provider>,
    document.getElementById('root'),
  ),
)

registerServiceWorker()

document.addEventListener('touchend', () => {})

console.info(`frikintech-${target}@${version}, env: ${getEnvName()}`)

import Rollbar from 'rollbar'
import { getPersonInfoForRollBar } from 'selectors/authSelectors'

import { version } from '../../../package.json'
import { getEnvName } from 'config/env.js'

const CONFIG = {
  accessToken: '5f71812bec1b43649a8e46a65c8bbf07',
  captureUncaught: false,
  payload: {
    environment: getEnvName(),
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: version,
      },
    },
  },
}

export default function createMiddleware(shouldReport) {
  let RB = shouldReport ? new Rollbar(CONFIG) : null

  return store => next => action => {
    try {
      return next(action)
    } catch (err) {
      let userInfo = getPersonInfoForRollBar(store.getState())

      if (shouldReport) {
        RB.configure(CONFIG, {
          payload: {
            person: userInfo.email ? userInfo : 'abe@frikin.tech', // abe is a non-logged in user
          },
        })

        RB.error(err)
      } else {
        console.warn(
          '>>> ERROR, WOULD SEND TO ROLLBAR',
          '\n',
          {
            ...userInfo,
            environment: getEnvName(),
          },
          '\n',
          err,
        )
      }

      return
    }
  }
}

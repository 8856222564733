/*
 * This is the interface to the ImmortalDB instance. It strives to be as safe as
 * possible with updates because thrid party usages of SalesIQ combined with
 * browser privacy settings can cause things to explode
 *
 * All functions are async and should be executed with the expectation of being
 * slow or behind. Use sparingly.
 */

import isEmpty from 'lodash/isEmpty'
import { warn } from 'utils'

export const ILLUMINATIONS_KEY = 'illuminations'
export const CUSTOMER_KEY = 'customer'
export const SQUEEZE_KEY = 'squeeze'

let p = () => new Promise(r => r()), // eslint-disable-line
  parse = (d = {}) => JSON.parse(d) || {},
  stringify = d => (d ? JSON.stringify(d) : null),
  // This is a dummy ImmortalDB used in cases for iframes with 3rd party cookied
  // disabled to maintain API presence
  ImmortalDB = {
    get: p,
    set: p,
  }

try {
  ImmortalDB = require('immortal-db').ImmortalDB
} catch (e) {
  warn('ImmortalDB failed to init: ', e)
}

// interface directly with the ImmortalDB storage
export const NaughtyDB = {
  get: async key => {
    let ret = {}

    try {
      await ImmortalDB.get(key).then(db => (ret = parse(db)))
    } catch (e) {
      warn(e)
      NaughtyDB.set(key) // failure, set value to null
    }

    return ret
  },
  getByType: async (key, type) => {
    let ret = {}

    try {
      await ImmortalDB.get(key).then(db => {
        let data = parse(db)

        ret = Object.keys(data).reduce(
          (ret, k) =>
            data[k]._entityType === type ? { ...ret, [k]: data[k] } : ret,
          {},
        )
      })
    } catch (e) {
      NaughtyDB.set(key) // failure, set value to null
      warn(e)
    }

    return ret
  },
  set: async (key, data) => {
    try {
      await ImmortalDB.set(key, stringify(data))
    } catch (e) {
      warn(e)
    }
  },
  flush: async (key, ids = []) => {
    let newData = {}

    try {
      let data = await NaughtyDB.get(key)

      newData = isEmpty(ids)
        ? {}
        : Object.keys(data || {}).reduce(
            (ret, k) => (ids.includes(k) ? ret : { ...ret, [k]: data[k] }),
            {},
          )

      warn(
        `IDB FLUSH result: ${ids.length} flushed / ${
          Object.keys(newData).length
        } cached`,
      )
    } catch (e) {
      warn(e)
    }

    newData = isEmpty(newData) ? null : newData

    await NaughtyDB.set(key, newData)
  },
  getSqueeze: async rooftopId =>
    await NaughtyDB.get(`${rooftopId}-${SQUEEZE_KEY}`),
  getCustomer: async rooftopId =>
    await NaughtyDB.get(`${rooftopId}-${CUSTOMER_KEY}`),
  tagCustomer: async (rooftopId, payload, replace) => {
    let key = `${rooftopId}-${CUSTOMER_KEY}`

    return await NaughtyDB.get(key).then((db = {}) => {
      NaughtyDB.set(key, {
        ...(replace ? {} : db),
        ...payload,
      })
    })
  },
  setSqueeze: () => {}, // TODO
}

window.flushNaughty = NaughtyDB.flush
window.getNaughty = NaughtyDB.get

import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import Fingerprint2 from 'fingerprintjs2'
import uuid from 'uuid/v1'

import { getUserPosition } from 'discada/dist/Map'

import API_ENDPOINTS from 'endpoints'
import {
  makeManager,
  startManager,
  stopManager,
  setSubscriptions,
  setSendHeaders,
} from 'utils/socket'

import {
  UPDATE_STATUS,
  READY,
  UPDATE_THEME,
  UPDATE_ROOFTOP_ID,
  UPDATE_ROOFTOP_INFO,
  UPDATE_BANKS,
  SET_FINDING_USER,
  SET_CONFIG,
  SET_AVAILABLE_CONFIGS,
  SET_CONFIG_VEHICLE_DATA,
  SET_GETTING_CONFIGS,
  CUSTOMIZE_DEMO,
  REPLACE_AVAILABLE_CONFIGS,
  UPDATE_SOCKET_HEALTH,
  UPDATE_ABE_CHAT_POSITION,
  UPDATE_ROOFTOP_SCRIPTS,
  SET_THEME,
  SET_LOCATION,
  SET_LOCATION_LOADING,
  SET_GETTING_PROGRAMS,
  SET_SHOW_DISTRACTOR,
  SET_TAGGED_CUSTOMER,
  SET_PROGRAMS_DIRTY,
} from 'reducers/appStatusReducers'

import { GET, POST, DELETE } from 'actions/apiActions'
import {
  getFingerPrint,
  getRooftopId,
  getRooftopUuid,
  getUseHouseBank,
} from 'selectors/appStatusSelectors'

import { shouldTrack, getIsLoggedIn } from 'selectors/authSelectors'

import { getUserIdToken } from 'selectors/authSelectors'

import { REPLACE_ENTITIES, DELETE_ENTITIES } from 'reducers/entityReducers'
import { THEMEABLE_ROOFTOPS } from 'utils'

import { reduceEntities } from 'utils'
import { removeFromPageState } from 'actions/pageStateActions'
import { isAuthRoute } from 'selectors/pageStateSelectors'
import { getSelectedDateRange } from 'selectors/naughtySelectors'
import {
  transmit,
  track,
  TAG_CHANGED,
  resetCustomerTag,
} from 'actions/naughtyActions'
import { toggleRebateCategories } from 'actions/quoteActions'

import {
  getConfig,
  getAvailableConfigs as getConfigsFromState,
  getCurrentConfigIdentifiers,
  getCustomer,
  getMeaningfulDiff,
} from 'selectors/appStatusSelectors'

import { info, dateOptions, tagDiff } from 'utils'
import { STATUS_HEALTHY } from 'utils/socket'
import { UPDATE_ILLUMINATIONS } from 'reducers/naughtyReducers'
import { getParamValue } from 'selectors/pageStateSelectors'
import { getQuoteIds } from 'selectors/quoteSelectors'

export const updateAppStatus = (status, message, errors) => dispatch =>
    dispatch({ type: UPDATE_STATUS, payload: { status, message, errors } }),
  resetAppStatus = () => dispatch =>
    dispatch({ type: UPDATE_STATUS, payload: { status: READY } }),
  updateBrowserInfo = (browser, appVersion) => dispatch =>
    Fingerprint2.get(comps => {
      let fingerprint = Fingerprint2.x64hash128(
        comps.map(c => c.value).join(),
        31,
      )

      dispatch({
        type: UPDATE_STATUS,
        payload: {
          browser,
          fingerprint,
          appVersion,
        },
      })
    }),
  updateTheme = theme => dispatch =>
    dispatch({ type: UPDATE_THEME, payload: theme }),
  updateRooftopId = rooftopId => (dispatch, getState) => {
    rooftopId = rooftopId || getParamValue(getState(), 'dealerId')

    dispatch({ type: UPDATE_ROOFTOP_ID, payload: rooftopId })
    dispatch(getRooftop())
  },
  customizeDemo = customer => (dispatch, getState) => {
    let { rooftopName, theme } = customer

    if (
      THEMEABLE_ROOFTOPS.includes(getRooftopId(getState())) &&
      (get(customer, 'theme') || get(customer, 'rooftopName'))
    ) {
      dispatch({ type: CUSTOMIZE_DEMO, payload: { rooftopName, theme } })
    }
  },
  getRooftop = () => (dispatch, getState) => {
    let rooftopId = getRooftopId(getState())

    window.setTheme = theme => dispatch(setTheme(theme))

    if (!rooftopId) return

    return dispatch(GET.rooftop(null, { rooftopId }))
      .then(info => {
        !isEmpty(info) &&
          dispatch(
            updateRooftopInfo({
              ...info,
            }),
          )
      })
      .then(() => {
        if(!getUseHouseBank(getState())){
          dispatch(getAllLenders())
        }
      })
  },
  createConfig = (category, identifiers, configId) => (dispatch, getState) => {
    if (!category) {
      console.warn('Config category missing')

      return
    }

    let config = configId ? getConfig(getState(), configId) : {},
      body = {
        ...identifiers,
        config,
        category,
      }

    return dispatch(POST.config({ body })).then(() =>
      dispatch(
        removeFromPageState([
          'addConfig',
          'cloneConfig',
          'vehicleConfig',
          'bankConfig',
          'rateMarkupConfig',
          'fAndIConfig',
          'pricingRulesConfig',
        ]),
      ),
    )
  },
  saveConfig = (
    category,
    config,
    configId,
    onSave = () => {},
    onError = () => {},
  ) => (dispatch, getState) => {
    if (!category) {
      console.warn('Config category missing')

      return
    }

    let identifiers = getCurrentConfigIdentifiers(getState(), configId),
      body = {
        ...identifiers,
        config,
        category,
      },
      doSave = POST.config

    return dispatch(doSave({ body }))
      .then(() =>
        dispatch(
          removeFromPageState([
            'addConfig',
            'cloneConfig',
            'vehicleConfig',
            'bankConfig',
            'rateMarkupConfig',
            'fAndIConfig',
            'pricingRulesConfig',
          ]),
        ),
      )
      .then(onSave)
      .catch(e => onError(e))
  },
  updateRooftopInfo = info => dispatch => {
    dispatch({ type: UPDATE_ROOFTOP_INFO, payload: info })
  },
  getAllLenders = () => (dispatch, getState) => {
    if (!isAuthRoute(getState())) {
      dispatch(GET.allLendersForConfig()).then(banks =>
        dispatch({ type: UPDATE_BANKS, payload: banks }),
      )
    }
  },
  getUsersForConfig = () => dispatch => {
    return dispatch(GET.usersForConfig()).then(r =>
      dispatch({
        type: REPLACE_ENTITIES,
        payload: reduceEntities(r, 'user'),
      }),
    )
  },
  getUserById = id => dispatch => {
    return dispatch(GET.userById({}, { id })).then(r =>
      dispatch({
        type: REPLACE_ENTITIES,
        payload: reduceEntities(r, 'user'),
      }),
    )
  },
  getUserByEmail = email => dispatch => {
    dispatch({ type: SET_FINDING_USER, payload: { finding: true, user: null } })

    return dispatch(GET.userByEmail({}, { email })).then(r =>
      dispatch({
        type: SET_FINDING_USER,
        payload: { finding: false, user: r || {} },
      }),
    )
  },
  clearUserSearch = () => dispatch =>
    dispatch({
      type: SET_FINDING_USER,
      payload: { finding: false, user: null },
    }),
  updateUserPreference = (id, type, value) => dispatch => {
    dispatch(POST.userPref({ body: { [type]: !!value } }, { id })).then(() =>
      dispatch(getUsersForConfig()),
    )
  },
  saveUser = user => dispatch => {
    dispatch(POST.user({ body: user })).then(() => {
      dispatch(getUsersForConfig())
      dispatch(removeFromPageState(['addUser', 'editUserId']))
    })
  },
  deleteUser = id => dispatch => {
    dispatch(DELETE.user(null, { id })).then(() => {
      dispatch({
        type: DELETE_ENTITIES,
        payload: [id],
      })

      dispatch(getUsersForConfig())
      dispatch(removeFromPageState(['addUser', 'editUserId']))
    })
  },
  getAvailableConfigs = category => dispatch => {
    return dispatch(GET.configsForRooftop(null, { category })).then(configs => {
      dispatch(replaceConfigsByCategory(category, configs))
    })
  },
  replaceConfigsByCategory = (category, configs) => (dispatch, getState) => {
    let existingConfigs = getConfigsFromState(getState()),
      keepConfigs = Object.keys(existingConfigs).reduce((ret, k) => {
        let c = existingConfigs[k]

        return c.category === category ? ret : { ...ret, [k]: c }
      }, {})

    dispatch({
      type: REPLACE_AVAILABLE_CONFIGS,
      payload: { configs: { ...keepConfigs, ...configs } },
    })
  },
  clearActiveConfig = () => {},
  clearConfig = id => dispatch => {
    dispatch({ type: SET_CONFIG, payload: { id, config: null } })
  },
  clearAvailableConfigs = () => dispatch => {
    dispatch({ type: SET_AVAILABLE_CONFIGS, payload: {} })
  },
  getConfigById = id => dispatch => {
    if (!id) return

    dispatch({ type: SET_GETTING_CONFIGS, payload: true })

    dispatch(GET.configById(null, { id })).then(config => {
      let configCategory = get(config, 'category'),
          payload = {
            id,
            config: get(config, 'config.config', {}), // TODO whhhhhy
          }
      if(configCategory === 'house-bank'){
        payload.configuredProgramData = get(config, 'configuredProgramData')
      }
      dispatch({
        type: SET_CONFIG,
        payload: payload,
      })

      dispatch({ type: SET_GETTING_CONFIGS, payload: false })
    })
  },
  getConfigMakes = () => dispatch => {
    dispatch(GET.configMakes()).then(makes =>
      dispatch({ type: SET_CONFIG_VEHICLE_DATA, payload: { makes } }),
    )
  },
  getConfigModelsForMake = make => dispatch => {
    dispatch({ type: SET_CONFIG_VEHICLE_DATA, payload: { models: [] } })

    dispatch(GET.configModelsForMake({ queryStringParameters: { make } })).then(
      models =>
        dispatch({ type: SET_CONFIG_VEHICLE_DATA, payload: { models } }),
    )
  },
  getConfigTrimsForModel = model => dispatch => {
    dispatch({ type: SET_CONFIG_VEHICLE_DATA, payload: { trims: [] } })

    dispatch(GET.configTrimsForModel({ queryStringParameters: { model } }))
      .then()
      .then(trims =>
        dispatch({ type: SET_CONFIG_VEHICLE_DATA, payload: { trims } }),
      )
  },
  deleteConfig = id => dispatch => {
    dispatch(DELETE.configById(null, { id })).then(() =>
      dispatch(
        removeFromPageState(['deleteConfig', 'vehicleConfig', 'bankConfig', 'rateMarkupConfig', 'fAndIConfig', 'pricingRulesConfig']),
      ),
    )
  },
  clearCloneConfigFormData = () => dispatch =>
    dispatch({
      type: SET_CONFIG_VEHICLE_DATA,
      payload: { models: [], trims: [] },
    }),
  changeShowAndTell = showAndTell => dispatch => {
    dispatch({
      type: UPDATE_ROOFTOP_INFO,
      payload: { showAndTell },
    })
  },
  getShowAndTell = () => dispatch => {
    dispatch(GET.showAndTell()).then(showAndTell =>
      dispatch(changeShowAndTell((showAndTell || []).join(','))),
    )
  },
  saveShowAndTell = showAndTellList => dispatch => {
    let body = uniq(showAndTellList.split(/[\s|,|\n]+/))

    return dispatch(POST.showAndTell({ body }))
  },
  setUpSocketForRooftop = () => (dispatch, getState) => {
    let rooftopUuid = getRooftopUuid(getState())
    if (!rooftopUuid) {
      // have to wait for a rooftop uuid to be available
      return
    }

    let token = getUserIdToken(getState())

    let MANAGER_TYPE_DEALER_USER = 'dealer-user',
      MANAGER_TYPE_DEVICE = 'device',
      MANAGER_TYPE_NONE = 'none'

    let desiredManagerType
    if (token) {
      desiredManagerType = MANAGER_TYPE_DEALER_USER
    } else if (shouldTrack(getState())) {
      desiredManagerType = MANAGER_TYPE_DEVICE
    } else {
      desiredManagerType = MANAGER_TYPE_NONE
    }

    if (window.managerType !== desiredManagerType && window.manager != null) {
      info(
        'setUpSocketForRooftop: managerType changed from ' +
          window.managerType +
          ' to ' +
          desiredManagerType +
          ', shutting down manager',
      )
      stopManager(window.manager)
      window.manager = null
    }

    if (window.manager == null) {
      info(
        'setUpSocketForRooftop: setting up socket for managerType ' +
          desiredManagerType,
      )

      let url = API_ENDPOINTS.API.wsEndpointUrl,
        deliveryListenerFn = ({ payload }) => {
          let dateRange = getSelectedDateRange(getState())

          if (!dateRange || get(dateOptions, `${dateRange}.listen`, false))
            dispatch({
              type: UPDATE_ILLUMINATIONS,
              payload,
            })
        },
        statusListenerFn = newStatus => dispatch(setSocketHealth(newStatus))

      if (desiredManagerType === MANAGER_TYPE_DEALER_USER) {
        window.manager = makeManager(
          `${url}/?token=${token}`,
          deliveryListenerFn,
          statusListenerFn,
        )
        setSubscriptions(window.manager, [`rooftop/${rooftopUuid}`])
        setSendHeaders(window.manager, { 'rooftop-uuid': rooftopUuid })
        startManager(window.manager)
        window.managerType = 'dealer-user'
      } else if (desiredManagerType === MANAGER_TYPE_DEVICE) {
        if (window.manager == null) {
          let sessionId = get(getState(), 'appStatus.customer.sessionId')
          if (sessionId) {
            window.manager = makeManager(
              `${url}/?device-id=${sessionId}`,
              deliveryListenerFn,
              statusListenerFn,
            )
            setSendHeaders(window.manager, { 'rooftop-uuid': rooftopUuid })
            startManager(window.manager)
            window.managerType = 'device'
          } else {
            info('setUpSocketForRooftop: invalid device id', sessionId)
          }
        }
      } else {
        info('skipping socket setup')
      }
    }
  },
  setSocketHealth = health => dispatch => {
    dispatch({ type: UPDATE_SOCKET_HEALTH, payload: health })

    // TODO homegrow better vitals tracking instead of pitching to rollbar
    //if (health === STATUS_UNHEALTHY) {
    //throw new Error('Websocket became unhealthy')
    //}

    if (health === STATUS_HEALTHY) {
      dispatch(transmit())
    }
  },
  updateAbeChatPosition = position => dispatch =>
    dispatch({ type: UPDATE_ABE_CHAT_POSITION, payload: position }),
  getRooftopScripts = () => dispatch =>
    dispatch(POST.getRooftopScripts({ body: {} })).then(r =>
      dispatch({ type: UPDATE_ROOFTOP_SCRIPTS, payload: r }),
    ),
  updateRooftopScripts = scripts => dispatch =>
    dispatch(POST.updateRooftopScripts({ body: { scripts } })).then(() =>
      dispatch(getRooftopScripts()),
    ),
  setTheme = theme => dispatch => dispatch({ type: SET_THEME, payload: theme }),
  getUserLocation = () => dispatch => {
    dispatch(setUserLocationLoading(true))
    getUserPosition().then(loc => {
      info('User location found:', loc)

      dispatch(
        hydrateCustomer({
          location: loc,
        }),
      )

      dispatch(setUserLocation(loc))
      dispatch(setUserLocationLoading(false))
    })
  },
  setUserLocationLoading = payload => dispatch =>
    dispatch({ type: SET_LOCATION_LOADING, payload }),
  setUserLocation = loc => dispatch =>
    dispatch({ type: SET_LOCATION, payload: loc }),
  setGettingPrograms = payload => dispatch =>
    dispatch({ type: SET_GETTING_PROGRAMS, payload }),
  setShowDistractor = payload => dispatch =>
    dispatch({ type: SET_SHOW_DISTRACTOR, payload }),
  setProgramsDirty = payload => dispatch =>
    dispatch({ type: SET_PROGRAMS_DIRTY, payload }),
  hydrateCustomer = (customerData = {}, options = {}) => (
    dispatch,
    getState,
  ) => {
    let { replace, createChangeEvent } = options,
      customer = cloneDeep(customerData || {}),
      existingCustomer = cloneDeep(getCustomer(getState())),
      isLoggedIn = getIsLoggedIn(getState()),
      tag = {
        _entityType: 'customer',
        sessionId: uuid(),
        hasNoRebates: false,
        hasNoTrade: false,
        sellMyTrade: false,
        fingerprint: getFingerPrint(getState()),
        ...(replace ? {} : existingCustomer),
        ...customer,
      },
      rectifyQuotes = ({ customer = {}, tag = {} }) => {
        if (customer.quotes) tag.quotes = customer.quotes

        return tag
      },
      rectifyTrades = ({ tag = {} }) => {
        // if the customer data says no trade, set trades to empty list
        if (tag.hasNoTrade) tag.trades = []

        return tag
      },
      rectifyRebates = ({ customer = {}, tag = {} }) => {
        // if the customer data has selected categories rebates, unset has none and replace
        if (!isEmpty(customer.selectedRebateCategories)) {
          tag.hasNoRebates = false
          tag.selectedRebateCategories = customer.selectedRebateCategories
        }

        // if the customer data says they don't have rebates, uncheck any selected
        if (customer.hasNoRebates) {
          tag.selectedRebateTypes = []
          tag.selectedRebateCategories = []

          dispatch(
            toggleRebateCategories(
              customer.vehicleId,
              customer.quoteId,
              customer.selectedRebateCategories,
            ),
          )
        }

        tag.selectedRebateTypes = uniq(
          get(tag, 'selectedRebateCategories', []).map(c => c.Name),
        )

        return tag
      },
      DEALER_USER_TAG = { _entityType: 'dealer' }

    tag = isLoggedIn
      ? DEALER_USER_TAG
      : {
          ...tag,
          ...rectifyQuotes({ tag, customer }),
          ...rectifyRebates({ tag, customer }),
          ...rectifyTrades({ tag, customer }),
        }

    dispatch(customizeDemo(tag))
    createChangeEvent && dispatch(trackMeaningfulChange(tag, existingCustomer))

    return dispatch({
      type: SET_TAGGED_CUSTOMER,
      payload: tag,
    })
  },
  trackMeaningfulChange = (customer, oldCustomer) => dispatch => {
    let changes = getMeaningfulDiff(customer, oldCustomer)

    if (!isEmpty(changes)) {
      // just for debug visibility
      dispatch({
        type: TAG_CHANGED,
        payload: changes,
      })

      dispatch(track(TAG_CHANGED, { changes }))
    } else {
      info('No meaningful changes -> ', tagDiff()(customer, oldCustomer))
    }
  },
  // TODO point all old naughtyuActions calls here
  tagCustomer = (vehicleId, quoteId, _, customerData, replace) =>
    hydrateCustomer({ vehicleId, quoteId, ...customerData }, replace),
  hydrateTaggedCustomer = hydrateCustomer,
  untagCustomer = () => (dispatch, getState) => {
    let { vehicle, quote } = getQuoteIds(getState())

    dispatch(resetCustomerTag(vehicle, quote))
    window.location.reload()
  }

import { push, replace, goBack } from 'redux-little-router'
import isNull from 'lodash/isNull'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  getQueryParamsWith,
  getQueryParamsWithout,
  getActivePathname,
  getQueryParamsWithoutNS,
  getRouteWithRooftop,
  getActiveHash,
  getQueryValue,
} from 'selectors/pageStateSelectors'

import { getEntity } from 'selectors/entitySelectors'

import { resetAppStatus } from 'actions/appStatusActions'

export const addToPageState = (params = {}, hash, removeParams = []) => (
    dispatch,
    getState,
  ) => {
    let existingHash = getActiveHash(getState()),
      pathname = getActivePathname(getState()),
      query = getQueryParamsWith(getState(), params),
      func = !isEmpty(removeParams) ? replace : push

    if (!isEmpty(removeParams))
      query = getQueryParamsWithout(getState(), removeParams, query)

    hash = hash || existingHash

    return dispatch(
      func(
        {
          pathname,
          query,
          hash,
        },
        {
          persistQuery: true,
        },
      ),
    )
  },
  removeFromPageState = (params, replaceState) => (dispatch, getState) => {
    let func = replaceState ? replace : push

    dispatch(resetAppStatus())

    return dispatch(
      func({
        pathname: getActivePathname(getState()),
        query: getQueryParamsWithout(
          getState(),
          Array.isArray(params) ? params : [params],
        ),
        hash: getActiveHash(getState()),
      }),
    )
  },
  replacePageState = (params = {}, hash, additive) => (dispatch, getState) => {
    dispatch(resetAppStatus())

    return dispatch(
      replace({
        pathname: getActivePathname(getState()),
        query: additive ? getQueryParamsWith(getState(), params) : params,
        hash: additive && !hash ? getActiveHash(getState()) : hash,
      }),
    )
  },
  addAndReplacePageState = (params = {}, hash) => (dispatch, getState) => {
    dispatch(resetAppStatus())

    let existingHash = getActiveHash(getState())

    return dispatch(
      replace(
        {
          pathname: getActivePathname(getState()),
          query: getQueryParamsWith(getState(), params),
          hash: hash || existingHash,
        },
        {
          persistQuery: true,
        },
      ),
    )
  },
  removeNSFromPageState = ns => (dispatch, getState) => {
    dispatch(resetAppStatus())
    return dispatch(replacePageState(getQueryParamsWithoutNS(getState(), ns)))
  },
  pushRooftopRoute = (route, query, hash, additive) => (dispatch, getState) => {
    let existingHash = getActiveHash(getState())

    dispatch(resetAppStatus())

    return dispatch(
      push({
        pathname: getRouteWithRooftop(getState(), route),
        query: additive ? getQueryParamsWith(getState(), query) : query,
        hash: hash || existingHash,
      }),
    )
  },
  toggleQueryValue = (queryKey, value = true) => (dispatch, getState) => {
    let currentVal = getQueryValue(getState(), queryKey, '')

    isNull(currentVal) || currentVal.toString() !== value.toString()
      ? dispatch(addToPageState({ [queryKey]: value }))
      : dispatch(removeFromPageState(queryKey))
  },
  goHome = () => dispatch => {
    dispatch(pushRooftopRoute('/:dealerId/inventory'))
    dispatch(resetAppStatus())

    window.location.reload()
  },
  lastPage = () => dispatch => {
    dispatch(resetAppStatus())
    dispatch(goBack())

    window.location.reload()
  },
  goToRooftop = rooftopCode => dispatch => {
    dispatch(resetAppStatus())
    dispatch(push({ pathname: `/${rooftopCode}/inventory` }))

    window.location.reload()
  },
  goToVehicle = (vehicleId, quoteId, mode) => dispatch => {
    dispatch(
      pushRooftopRoute('/:dealerId/inventory', { vehicleId, quoteId }, mode),
    )
    dispatch(resetAppStatus())
  },
  goToVehiclePage = (vehicleId, mode, quoteParams = {}) => (
    dispatch,
    getState,
  ) => {
    let vehicle = getEntity(getState(), vehicleId)

    let retargetParams = ['year', 'make', 'model']
      .map(k =>
        get(vehicle, k, '')
          .toString()
          .replace(/[^a-zA-Z\d]+/g, '-')
          .replace(/-+$/g, '')
          .toLowerCase(),
      )
      .join('/')

    dispatch(
      pushRooftopRoute(
        `/:dealerId/vehicle/${vehicleId}/${retargetParams}`,
        quoteParams,
        mode,
        false,
      ),
    )
  },
  redirect = (href, hard) => dispatch => {
    let location = href || window.location.origin

    hard ? (window.location = location) : dispatch(replace(href))
  },
  goTo = href => dispatch => {
    dispatch(push(href))
  },
  goToError = code => dispatch => {
    dispatch(pushRooftopRoute(`/:dealerId/${code.toString()}`))
    dispatch(resetAppStatus())
  },
  setPageTitle = title => dispatch => {
    dispatch({ type: 'PAGE_TITLE_CHANGE', payload: title })
    dispatch(resetAppStatus())

    document.title = title
  },
  goToRootPath = () => dispatch => {
    dispatch(resetAppStatus()) && dispatch(push({ pathname: `/` }))
  }

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import without from 'lodash/without'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import merge from 'lodash/merge'
import uuid from 'uuid/v1'

import { REPLACE_ENTITIES, MERGE_ENTITIES } from 'reducers/entityReducers'
import { GET, POST } from 'actions/apiActions'
import {
  addAndReplacePageState,
  addToPageState,
  replacePageState,
} from 'actions/pageStateActions'
import { getActiveHash } from 'selectors/pageStateSelectors'
import { STATUS as S, INTENTS } from 'reducers/quoteReducers'
import { getEntity } from 'selectors/entitySelectors'
import { shouldTrack } from 'selectors/authSelectors'

import { refreshPrograms } from 'actions/programActions'

import {
  getIsKnownCustomerFromState,
  getCustomer,
} from 'selectors/appStatusSelectors'

import { tagCustomer } from 'actions/appStatusActions'

import {
  getQuote,
  getQuoteByVersion,
  getQuoteValue,
  getQuoteForProgramPull,
  getBestPayment,
  getBestProgram,
  getBestProgramKey,
  getBestProgramValue,
  getRebatesForVehicle,
  getQuoteTrackerStats,
  getRebateById,
  findNearestProgram,
  getLocks,
  getBestAllAroundProgram,
  generateSelectedRebateIds,
  getStartingModeRaw,
  getRebatesForCategory,
  getSelectedRebateIds,
  shouldQuoteHaveTaxes,
  shouldQuoteHaveFees,
  getPreselectedRebates,
  getCalculatedDesiredFrontProfit,
  getQuoteIds,
} from 'selectors/quoteSelectors'

import {
  getTooSqueezed,
  getTaggedCustomer,
  getCustomerZip,
} from 'selectors/naughtySelectors'

import {
  track,
  QUOTE_CHANGED,
  QUOTE_ADDITION,
  QUOTE_REMOVAL,
  QUOTE_REFRESHED,
  QUOTE_CHANGE_MATCHERS,
  QUOTE_SAVED,
  QUOTE_REACTION,
  deleteMagicCookie,
} from 'actions/naughtyActions'

import {
  UPDATE_REBATE_DETAILS,
  SET_SHORT_CODE_QUOTE_ID,
} from 'reducers/appStatusReducers'

import {
  UPDATE_GLOBAL_QUOTE_VALUE,
  STATUS,
  initialAdd,
  initialTrade,
  initialFee,
} from 'reducers/quoteReducers'

import { trackView, setNewStartingQuoteId } from 'actions/inventoryActions'
import {
  getShouldIncludeTaxesSetting,
  getShouldIncludeFeesSetting,
} from 'selectors/appStatusSelectors'

import { warn, time, timeEnd } from 'utils'

import { getHasMdrive } from 'selectors/appStatusSelectors'

const INITIAL_TYPES = {
  additions: initialAdd,
  warranties: initialAdd,
  maintenancePlans: initialAdd,
  trades: initialTrade,
  fees: initialFee,
}

const QUERY_PROPS = [
  'loanTerm',
  'leaseTerm',
  'creditScore',
  'miles',
  'down',
  'mode',
]

const CLEAN_LIST = [
  // TODO whitelist v. blacklist?
  'complete',
  'mode',
  'dirty',
  'status',
  'intent',
  'term',
  'loanTerm',
  'leaseTerm',
  'creditScore',
  'miles',
  'gettingPrograms',
  'gotProgramInfo',
  'programs',
  'down',
  'rebateInfo', // here because this is lazy loaded and would cause the quote to dirty immediately
  'desiredPayment',
  'customer.approveTab',
  'customer.selectedSection',
  'rollResults',
  'expirationDate',
  'expirations',
  'expirationDays',
  'shortCode',
  'createdBy',
  'locks.mode',
  'locks.term',
  'locks.down',
  'locks.miles',
  'locks.creditScore',
  'viewMode',
  'programsHaveTaxesAndFees',
  'programsHaveTaxes',
  'programsHaveFees',
  'customerComments',
  'appointment.type',
  'appointment.date',
  'appointment.time',
  'isLoading',

  //'customer',
  //'customer.firstName',
  //'customer.lastName',
  //'customer.email',
  //'customer.phone',
  //'taxRegionId',
  //'stateFeeTax',
  //'includeTaxes',
  //'includeFees',
]

const REMOVE_ON_SAVE = ['_versions']

const getClonedQuote = (state, vId, qId, mode, extra) =>
  cloneDeep(getQuote(state, vId, qId, mode, extra))

export const changeQuote = (vId, qId, properties, options = {}) => dispatch => {
    dispatch({
      type: 'CHANGE_QUOTE',
      payload: { vId, qId, properties, options },
    })

    let {
      propagate,
      clean,
      dirty,
      forceProgramUpdate,
      track = true,
      squeeze,
      makeNewId,
      skipRebates,
      showDistractor,
    } = options

    return dispatch(
      updateQuote(
        vId,
        qId,
        properties,
        propagate,
        clean,
        dirty,
        forceProgramUpdate,
        track,
        squeeze,
        makeNewId,
        skipRebates,
        showDistractor,
      ),
    )
  },
  updateQuote = (
    vId,
    qId,
    properties = {},
    propagate,
    clean,
    dirty,
    forceProgramUpdate,
    track = true,
    squeeze,
    makeNewId,
    skipRebates,
    showDistractor = true,
  ) => async (dispatch, getState) => {
    if (!qId || makeNewId) {
      qId = uuid()
      dispatch(addToPageState({ quoteId: qId }))
    }

    if (typeof propagate === 'object') throw JSON.stringify(properties)

    let tooSqueezed = await getTooSqueezed(getState()),
      pd = getClonedQuote(getState(), vId, qId),
      isKnownCustomer = getIsKnownCustomerFromState(getState()),
      dirtyFlag = false,
      isNew = get(getEntity(getState(), vId), 'type') === 'New',
      propKeys = Object.keys(properties),
      pageState = {},
      trackerVals = []

    track = track && shouldTrack(getState())

    if (track && squeeze && tooSqueezed) {
      dispatch(
        addToPageState({
          quoteAction: INTENTS.getOTD,
          mode: getQuoteValue(getState(), vId, qId, 'mode'),
        }),
      )

      return
    }

    // TODO need some kind of universal pre/post processor for things like this?
    if (
      !pd.hasOwnProperty('desiredFrontProfit') ||
      propKeys.includes('invoice') ||
      propKeys.includes('cost') ||
      propKeys.includes('price')
    ) {
      set(
        pd,
        'desiredFrontProfit',
        getCalculatedDesiredFrontProfit(getState(), vId, {
          ...pd,
          ...properties,
        }),
      )
    }

    if (propKeys.includes('desiredFrontProfit')) {
      set(pd, 'desiredFrontProfit', properties.desiredFrontProfit)
      set(
        pd,
        'price',
        (isNew ? pd.invoice : pd.cost) + properties.desiredFrontProfit,
      )
    }

    if (
      propKeys.includes('customerZipCode') &&
      properties.customerZipCode !== pd.customerZipCode
    ) {
      if (!propKeys.includes('rateMarket')) {
        // zip code changed independently of the rateMarket
        dispatch(syncRateMarket(vId, qId, properties.customerZipCode, clean))
      }

      // this can result in double rebate calls on page load
      !skipRebates &&
        dispatch(getRebatesForQuote(vId, qId, properties.customerZipCode))
    }
    // TODO end

    propKeys.forEach(k => {
      if (!clean && !CLEAN_LIST.includes(k)) dirtyFlag = true

      let matchKey = Object.keys(QUOTE_CHANGE_MATCHERS).find(m =>
          QUOTE_CHANGE_MATCHERS[m].matcher(k),
        ),
        match = matchKey ? QUOTE_CHANGE_MATCHERS[matchKey] : null

      if (match && match.context)
        trackerVals = [...trackerVals, match.context(k, properties, pd)].filter(
          x => x,
        )

      set(pd, k, properties[k])

      if (QUERY_PROPS.includes(k)) pageState[`q.${k}`] = properties[k]
    })

    if (!clean && (dirty || dirtyFlag)) {
      set(pd, 'dirty', true)
      set(pd, 'rollResults', null)
    } else {
      set(pd, 'rollResults', properties.rollResults || null)
    }

    let payload = qId ? { [qId]: pd } : {}
    let ret = dispatch({
      type: REPLACE_ENTITIES,
      payload,
    })

    // only do this when tracking
    if (track && propKeys.some(k => k.includes('creditScore')))
      setTimeout(
        () =>
          dispatch(
            tagCustomer(vId, qId, true, {
              creditScore: properties.creditScore || 0,
            }),
          ),
        1,
      )

    track &&
      trackerVals &&
      setTimeout(
        () =>
          dispatch(
            trackQuoteChange(
              vId,
              qId,
              trackerVals,
              squeeze && !isKnownCustomer,
            ),
          ),
        1,
      )

    properties.mode && dispatch(replacePageState({}, properties.mode, true))

    if (!isEmpty(pageState)) {
      dispatch(addAndReplacePageState(pageState))
    }

    if (forceProgramUpdate) {
      dispatch(refreshPrograms({ showDistractor }))
    }

    return ret
  },
  syncRateMarket = (vId, qId, customerZipCode) => (dispatch, getState) => {
    dispatch({ type: 'SYNC_RATE_MARKET' })
    dispatch(changeQuote(vId, qId, { gettingPrograms: true }))
    const oldStateFeeTax = getQuoteValue(getState(), vId, qId, 'stateFeeTax'),
      oldRateMarket = getQuoteValue(getState(), vId, qId, 'rateMarket'),
      oldTaxRegionId = getQuoteValue(getState(), vId, qId, 'taxRegionId')

    return dispatch(validateZip(customerZipCode)).then(rateMarket => {
      if (!isNaN(parseInt(rateMarket, 10)))
        dispatch(
          changeQuote(
            vId,
            qId,
            {
              customerZipCode,
              rateMarket,
              stateFeeTax: rateMarket === oldRateMarket ? oldStateFeeTax : null,
              taxRegionId: rateMarket === oldRateMarket ? oldTaxRegionId : null,
              gettingPrograms: false,
            },
            { clean: true },
          ),
        )
    })
  },
  trackQuoteChange = (vId, qId, changes = [], free) => (dispatch, getState) => {
    if (isEmpty(changes)) return

    dispatch(
      track(
        QUOTE_CHANGED,
        {
          ...getQuoteTrackerStats(getState(), vId, qId),
          changes,
        },
        free,
      ),
    )
  },
  setMode = (op, quoteParams = {}) => dispatch => {
    dispatch(
      changeQuote(
        op.vehicleId,
        op.quoteId,
        { mode: op.mode, ...quoteParams },
        { squeeze: true },
      ),
    )
  },
  setViewMode = op => dispatch => {
    dispatch(changeQuote(op.vehicleId, op.quoteId, { viewMode: op.viewMode }))
  },
  updateCustomerZip = (vId, qId, customerZipCode) => dispatch => {
    dispatch(changeQuote(vId, qId, { customerZipCode }, { squeeze: true }))
    dispatch(tagCustomer(vId, qId, { zipCode: customerZipCode }))

    return dispatch(getRebatesForQuote(vId, qId, customerZipCode)).then(() =>
      dispatch(getProgramsForQuote(vId, qId)),
    )
  },
  selectBestAllAroundProgram = (vId, qId) => (dispatch, getState) => {
    let program = getBestAllAroundProgram(getState(), vId, qId),
      { mode, term, down, creditScore, miles, Payment } = program,
      p = v => parseFloat(v, 10)

    dispatch(
      changeQuote(
        vId,
        qId,
        {
          desiredPayment: Payment,
          mode,
          [`${mode}Term`]: p(term),
          down: p(down),
          creditScore: p(creditScore),
          miles: p(
            !isEmpty(miles)
              ? miles
              : getQuoteValue(getState(), vId, qId, 'miles'),
          ),
        },
        { squeeze: true },
      ),
    )
  },
  updateQuoteSlider = (vId, qId, payment, apply) => (dispatch, getState) => {
    let locks = getLocks(getState(), vId, qId),
      nearest = findNearestProgram(getState(), vId, qId, null, payment, locks),
      { mode, term, down, creditScore, miles } = nearest,
      p = v => parseFloat(v, 10),
      programData = {
        mode,
        [`${mode}Term`]: p(term),
        down: p(down),
        creditScore: p(creditScore),
        miles: p(
          !isEmpty(miles)
            ? miles
            : getQuoteValue(getState(), vId, qId, 'miles'),
        ),
      }

    dispatch(
      changeQuote(vId, qId, {
        desiredPayment: payment,
        ...(apply ? programData : {}),
      }),
    )
  },
  resetQuote = (vId, qId) => (dispatch, getState) => {
    let includeTaxes = getShouldIncludeTaxesSetting(getState()),
      includeFees = getShouldIncludeFeesSetting(getState())

    // nuke magic cookie, just in case
    dispatch(deleteMagicCookie())

    dispatch({ type: 'RESET_QUOTE', payload: { includeTaxes, includeFees } })
    dispatch(
      changeQuote(
        vId,
        qId,
        {
          customer: {},
          appointment: {},
          includeTaxes,
          includeFees,
          programsHaveTaxes: false,
          programsHaveFees: false,
        },
        {
          clean: true,
          track: true,
          forceProgramUpdate: true,
          makeNewId: true,
        },
      ),
    )
  },
  createQuote = (
    vId,
    qId,
    addendum = {},
    forceProgramUpdate,
    replacePageState,
  ) => (dispatch, getState) => {
    let existingQuote = getEntity(getState(), qId, {})
    let activeHash = getActiveHash(getState())

    //dispatch(setShowDistractor(true))
    dispatch(changeQuote(vId, qId, { isLoading: true, gettingPrograms: true }))

    if (isEmpty(qId)) {
      warn('skipping quote creation, missing quote id')
      dispatch(changeQuote(vId, qId, { isLoading: false }))
      return
    }

    time(`===> created quote ${qId}`)

    dispatch({ type: 'CREATE_QUOTE', payload: { qId } })
    replacePageState && dispatch(addAndReplacePageState({ quoteId: qId }))

    return dispatch(GET.quote({}, { uuid: qId }))
      .then(async (response = {}) => {
        let quoteExists = response.id === qId,
          customer = await getTaggedCustomer(getState()),
          quote = {
            customer,
            ...getQuote(getState(), vId, qId), // get the prepencil
            ...response, // merge in the response
            ...addendum, // and any extras
            mode: existingQuote.mode || activeHash,
            isLoading: true,
          }

        //quote exists on server, make sure the starting id is changed
        if (quoteExists) {
          warn('Starting quoteId stale, making new one')
          dispatch(setNewStartingQuoteId(vId))
        }

        let newQuote = isEmpty(response.shortCode),
          hasTaxesAlready =
            quote.includeTaxes || quote.programsHaveTaxes || false,
          hasFeesAlready = quote.includeFees || quote.programsHaveFees || false,
          quoteShouldHaveTaxes = shouldQuoteHaveTaxes(getState(), vId, qId),
          quoteShouldHaveFees = shouldQuoteHaveFees(getState(), vId, qId)

        // overrides from tag
        quote.customerZipCode = customer.zipCode || quote.customerZipCode
        quote.rateMarket = customer.rateMarket || quote.rateMarket
        quote.trades = customer.trades || quote.trades
        quote.selectedRebateCategories =
          customer.selectedRebateCategories || quote.selectedRebateCategories

        let hasRebateCats = !isEmpty(quote.selectedRebateCategories)

        // if it's new quote, figure out if we need to get fees and taxes, reset
        // the rate market too
        if (newQuote) {
          quote.includeFees = hasFeesAlready || quoteShouldHaveFees
          quote.includeTaxes = hasTaxesAlready || quoteShouldHaveTaxes
          quote.customerZipCode =
            customer.zipCode ||
            quote.customerZipCode ||
            getCustomerZip(getState())

          delete quote.rateMarket
        }

        quote.gettingPrograms = forceProgramUpdate
        quote.programsHaveTaxes = quote.programsHaveTaxes || hasTaxesAlready
        quote.programsHaveFees = quote.programsHaveFees || hasFeesAlready

        let tagData = {
          ...quote.customer,
          ...customer,
          quotes: { ...get(quote.customer, 'quotes', {}), [vId]: qId },
        }

        if (quoteExists) {
          if (!isEmpty(quote.trades)) tagData.trades = quote.trades
          tagData.zipCode = quote.customerZipCode
          tagData.rateMarket = quote.rateMarket
          tagData.creditScore = quote.creditScore
          // TODO rebate cats?
        }

        dispatch(tagCustomer(vId, qId, null, tagData, true))
        dispatch(trackView(vId, qId))

        dispatch(
          changeQuote(
            vId,
            qId,
            {
              ...quote,
              isLoading: false,
              complete: true,
            },
            {
              clean: true,
              track: false,
              skipRebates: true, // tells changeQuote to not repull rebates
              forceProgramUpdate: true,
              processRebateCats: hasRebateCats,
              showDistractor: false,
            },
          ),
        )
        timeEnd(`===> created quote ${qId}`)

        return quote
      })
      .catch(e => {
        console.warn('Error loading quote', qId, e)
      })
  },
  updateGlobalQuoteValue = properties => dispatch => {
    dispatch({ type: UPDATE_GLOBAL_QUOTE_VALUE, payload: properties })
  },
  processQuotes = (quotes = []) => dispatch => {
    return dispatch({
      type: MERGE_ENTITIES,
      payload: quotes.reduce(
        (ret, quote) =>
          get(quote, 'latest.id')
            ? {
                ...ret,
                [quote.latest.id]: {
                  _entityType: 'quote',
                  interactionCount: quote.interactionCount,
                  ...quote.latest,
                },
              }
            : ret,
        {},
      ),
    })
  },
  getQuotes = () => dispatch =>
    dispatch(GET.quotes()).then(r => dispatch(processQuotes(r))),
  getQuotesForVehicle = vehicleId => dispatch =>
    dispatch(GET.quotesForVehicle({}, { vehicleId })).then(r => {
      dispatch(processQuotes(r))
    }),
  getQuotesStatusesForVehicle = vehicleId => dispatch => {
    dispatch(GET.quotesForVehicleNoLoader({}, { vehicleId })).then(
      (quotes = []) => {
        let payload = quotes.reduce((ret, q) => {
          return {
            ...ret,
            [get(q, 'latest.id')]: {
              //...getEntity(getState(), get(q, 'latest.id'), {}),
              interactionCount: q.interactionCount,
              intent: get(q, 'latest.intent'),
            },
          }
        }, {})

        dispatch({
          type: MERGE_ENTITIES,
          payload,
        })
      },
    )
  },
  getQuoteById = qId => dispatch => {
    if (!isEmpty(qId)) return

    return dispatch(GET.quote({}, { uuid: qId })).then(r => {
      dispatch(processQuotes([r]))
    })
  },
  getMarketForZip = zipcode => dispatch => {
    return dispatch(GET.marketForZip({}, { zipcode }))
  },
  validateZip = zipcode => dispatch => {
    if (zipcode.length !== 5) {
      return dispatch(addToPageState({ changeZip: 'error' }))
    } else {
      return dispatch(getMarketForZip(zipcode)).then(market => {
        if (typeof market !== 'number') {
          dispatch(addToPageState({ changeZip: 'error' }))
        }

        return market
      })
    }
  },
  getProgramsForCurrentQuote = (addendum = {}, options = {}) => (
    dispatch,
    getState,
  ) => {
    let { vehicle, quote } = getQuoteIds(getState())

    return dispatch(
      getProgramsForQuote(
        vehicle,
        quote,
        {
          customerZipCode: getQuoteValue(
            getState(),
            vehicle,
            quote,
            'customerZipCode',
          ),
          ...addendum,
        },
        options,
      ),
    )
  },
  getProgramsForQuote = (vId, qId, addendum = {}, options = {}) => async (
    dispatch,
    getState,
  ) => {
    await dispatch(
      changeQuote(
        vId,
        qId,
        {
          ...addendum,
          gettingPrograms: true,
        },
        { ...options, clean: true },
      ),
    )

    let mdriveFlag = getHasMdrive(getState()),
      usingMdrive = mdriveFlag,
      creditScore = getQuoteValue(getState(), vId, qId, 'creditScore'),
      leaseTerm = getQuoteValue(getState(), vId, qId, 'leaseTerm'),
      loanTerm = getQuoteValue(getState(), vId, qId, 'loanTerm'),
      miles = getQuoteValue(getState(), vId, qId, 'miles'),
      down = getQuoteValue(getState(), vId, qId, 'down'),
      downs = getQuoteValue(getState(), vId, qId, 'downs'),
      zipcode = getQuoteValue(getState(), vId, qId, 'customerZipCode'),
      endpoint = null

    if (usingMdrive) {
      endpoint = downs.map(d => parseFloat(d.value)).includes(parseFloat(down))
        ? 'getProgramsForQuoteMDrive'
        : 'rollProgramsCashMDrive'
    } else {
      endpoint = downs.map(d => parseFloat(d.value)).includes(parseFloat(down))
        ? 'programsForQuote'
        : 'programsForCustomQuote'
    }

    let pd = cloneDeep(getQuoteForProgramPull(getState(), vId, qId))

    return dispatch(validateZip(zipcode))
      .then(market => {
        if (typeof market === 'number' && parseInt(pd.rateMarket, 10) < 0) {
          pd.rateMarket = market
        }

        if (isNaN(parseInt(market, 10))) {
          throw new Error('Invalid ZIP')
        }
      })
      .then(() => dispatch(getRebatesForQuote(vId, qId, zipcode)))
      .then(() =>
        set(
          pd,
          'rebateInfo.Rebates',
          getRebatesForVehicle(getState(), vId, qId, {
            customer: true,
            dealer: true,
            selected: true,
          }),
        ),
      )
      .then(() =>
        dispatch(
          POST[endpoint]({
            body: pd,
          }),
        ),
      )
      .then(response => {
        if (isEmpty(response)) throw new Error('No Response')

        dispatch(
          changeQuote(
            vId,
            qId,
            {
              ...response,
              mscanId: response.mscanId,
              programs: response.programs,
              programsHaveTaxes: pd.includeTaxes,
              programsHaveFees: pd.includeFees,
              gettingPrograms: false,
              mode: getStartingModeRaw({
                creditScore,
                leaseTerm,
                loanTerm,
                miles,
                down,
                mode: getActiveHash(getState()),
                programs: response.programs,
              }),
              dirty: false,
              lastPull: new Date().getTime(),
            },
            { clean: true },
          ),
        )
      })
      .then(() =>
        dispatch(
          track(
            QUOTE_REFRESHED,
            getQuoteTrackerStats(getState(), vId, qId),
            true,
          ),
        ),
      )
      .catch(e => {
        console.error(e)
        dispatch(changeQuote(vId, qId, { gettingPrograms: false }))
      })
  },
  getRebatesForQuote = (
    vId,
    qId,
    customZipCode,
    addendum = {},
    options = {},
  ) => (dispatch, getState) => {
    let customer = getCustomer(getState()),
      zipcode = customZipCode || getCustomerZip(getState()),
      selectedRebateCategories = get(customer, 'selectedRebateCategories'),
      optionCode = getQuoteValue(getState(), vId, qId, 'vehicleOptionCode')

    if (!zipcode) {
      console.error("Can't get rebates without zipcode")
      return
    }

    return dispatch(
      GET.vehicleRebates(
        optionCode ? { queryStringParameters: { optionCode } } : {},
        { vId, zipcode },
      ),
    ).then(rebateInfo => {
      if (!rebateInfo) return dispatch({ type: 'DUPE_REBATES_CALL' })

      // get precalced rebate vals and merge with existing vals
      let precalcedRebateValues = get(rebateInfo, 'Rebates', []).reduce(
        (ret, r) => {
          let val = get(r, 'Value.Values[0].Value')
          return r.ManualValueInputRequired && val
            ? { ...ret, [r.ID]: val }
            : ret
        },
        {},
      )

      let selectedRebateIds = generateSelectedRebateIds(rebateInfo),
        existingRebateIds =
          getQuoteValue(getState(), vId, qId, 'selectedRebateIds', []) || [],
        rebateData = {
          rebateInfo,
          selectedRebateIds: [
            ...existingRebateIds,
            ...getPreselectedRebates(
              getState(),
              vId,
              qId,
              selectedRebateCategories || addendum.selectedRebateCategories,
              rebateInfo.Rebates,
            ),
            ...selectedRebateIds,
          ],
          selectedRebateValues: {
            ...getQuoteValue(getState(), vId, qId, 'selectedRebateValues', {}),
            ...precalcedRebateValues,
          },
        },
        merged = merge(addendum, rebateData)

      return dispatch(
        changeQuote(vId, qId, merged, {
          clean: true,
          track: true,
          ...options,
        }),
      )
    })
  },
  getProgramInfo = (vId, qId, mode) => (dispatch, getState) => {
    dispatch(changeQuote(vId, qId, { gotProgramInfo: false }))

    let pd = getClonedQuote(getState(), vId, qId),
      bpk = getBestProgramKey(getState(), vId, qId, mode),
      bp = getBestProgram(getState(), vId, qId, mode)

    if (isEmpty(bp) || isEmpty(bpk)) return {
      then: () => {}
    }

    pd.mode = mode

    set(
      pd,
      'rebateInfo.Rebates',
      getRebatesForVehicle(getState(), vId, qId, {
        customer: true,
        dealer: true,
        selected: true,
      }),
    )

    REMOVE_ON_SAVE.forEach(k => delete pd[k])
    // TODO filter out initialTrade matches

    pd.programs = { [bpk]: bp }

    if (parseInt(pd.rateMarket, 10) < 0) delete pd.rateMarket

    let usingMdrive = getHasMdrive(getState()),
      cmd = usingMdrive ? POST.getProgramInfoMDrive : POST.programInfo

    return dispatch(cmd({ body: pd }))
      .then(response => {
        dispatch(
          changeQuote(
            vId,
            qId,
            {
              [`programs[${bpk}].programInfo`]: response,
              gotProgramInfo: true
            },
            { clean: true },
          ),
        )
      })
      .catch(() => {})
  },
  saveQuote = (
    vId,
    qId,
    mode,
    intent,
    addendum = {},
    _,
    silent = false,
    createEvent = true,
  ) => (dispatch, getState) => {
    dispatch({ type: 'SAVE_QUOTE' })

    let pd

    return dispatch(
      getProgramsForQuote(
        vId,
        qId,
        {
          ...addendum,
          status: S.saving,
          isLoading: true && !silent,
        },
        { track: true, clean: true },
      ),
    )
      .then(() => {
        // TODO move all this shit to a selector :')
        pd = getClonedQuote(getState(), vId, qId)

        pd.mode = mode || 'lease'
        pd.intent = intent

        //TODO save rebates or just save selected idents?
        set(
          pd,
          'rebateInfo.Rebates',
          getRebatesForVehicle(getState(), vId, qId, {
            customer: true,
            dealer: true,
            selected: true,
          }),
        )

        pd.expirationDate = pd.expirationDate
          ? moment(pd.expirationdate).unix()
          : moment()
              .add(pd.expirationDays, 'days')
              .unix()

        pd.quotedPayment = getBestPayment(getState(), vId, qId, pd.mode)
        pd.quotedBankCode = getBestProgramValue(
          getState(),
          vId,
          qId,
          pd.mode,
          'Lender',
        )
        pd.quotedProgramDescription = getBestProgramValue(
          getState(),
          vId,
          qId,
          pd.mode,
          'ProgramDescription',
        )

        REMOVE_ON_SAVE.forEach(k => delete pd[k])

        return pd
      })
      .then(pd => dispatch(doSaveQuote(vId, qId, pd)))
      .then(({ shortCode, insurancePartnerLink }) => {
        dispatch(getQuotesForVehicle(vId))
        dispatch(getQuoteById(qId))
        createEvent &&
          dispatch(
            track(
              QUOTE_SAVED,
              {
                intent,
                appointment: pd.appointment,
                customerComments: pd.customerComments,
                ...getQuoteTrackerStats(getState(), vId, qId),
              },
              true,
            ),
          )

        return dispatch(
          changeQuote(
            vId,
            qId,
            {
              shortCode,
              intent,
              insurancePartnerLink,
              status: S.ready,
              isLoading: false,
            },
            { track: true, clean: true, dirty: false },
          ),
        )
      })
      .then(() => {
        !silent && dispatch(setNewStartingQuoteId(vId))
      })
  },
  doSaveQuote = (vId, qId, addendum) => (dispatch, getState) => {
    let quote = {
      ...getClonedQuote(getState(), vId, qId),
      ...addendum,
    }

    return dispatch(POST.saveQuote({ body: quote }))
  },
  copyVersionToLatest = (id, txn) => (dispatch, getState) => {
    return dispatch({
      type: MERGE_ENTITIES,
      payload: {
        [id]: getQuoteByVersion(getState(), id, txn),
      },
    })
  },
  addItem = (vId, qId, collection, item, tag) => (dispatch, getState) => {
    let qd = getClonedQuote(getState(), vId, qId),
      newColl = {
        [collection]: [
          ...qd[collection],
          item ||
            cloneDeep({
              ...INITIAL_TYPES[collection],
              key: uuid(),
            }),
        ],
      }

    tag && dispatch(tagCustomer(vId, qId, null, newColl))
    dispatch(changeQuote(vId, qId, newColl))

    dispatch(
      track(
        QUOTE_ADDITION,
        {
          collection,
          ...getQuoteTrackerStats(getState(), vId, qId),
        },
        true,
      ),
    )
  },
  removeItem = (vId, qId, idx, collection, tag) => (dispatch, getState) => {
    if (!vId || !qId) return

    let qd = getClonedQuote(getState(), vId, qId),
      removedItem = qd[collection][idx]

    qd[collection].splice(idx, 1)

    tag &&
      dispatch(tagCustomer(vId, qId, null, { [collection]: qd[collection] }))

    dispatch(changeQuote(vId, qId, { [collection]: qd[collection] }))

    dispatch(
      track(
        QUOTE_REMOVAL,
        {
          ...getQuoteTrackerStats(getState(), vId, qId),
          collection,
          removedItem,
        },
        true,
      ),
    )
  },
  updateQuoteStatus = (vId, qId, status, extraProps = {}) => dispatch =>
    dispatch(changeQuote(vId, qId, { status, ...extraProps })),
  forceRoll = (vId, qId, mode) => dispatch => {
    return dispatch(getRollResults(vId, qId, mode))
      .then(cash => {
        if (!cash) return

        return dispatch(
          changeQuote(
            vId,
            qId,
            {
              down: Math.ceil(cash),
            },
            { dirty: true, forceProgramUpdate: true },
          ),
        )
      })
      .then(() =>
        dispatch(
          saveQuote(vId, qId, mode, INTENTS.rolled, {}, null, false, false),
        ),
      )
  },
  getRollResults = (vId, qId, mode) => (dispatch, getState) => {
    let quote = getClonedQuote(getState(), vId, qId),
      proc = r => {
        let { cash } = r

        dispatch(
          updateQuoteStatus(vId, qId, null, {
            rollResults: {
              cash: mode === 'loan' ? get(cash, 'value', 0) : cash,
            },
          }),
        )

        return mode === 'loan' ? get(cash, 'value', 0) : cash
      }

    set(
      quote,
      'rebateInfo.Rebates',
      getRebatesForVehicle(getState(), vId, qId, {
        customer: true,
        dealer: true,
        selected: true,
      }),
    )

    dispatch(updateQuoteStatus(vId, qId, STATUS.rolling, { rollResults: null }))

    delete quote._versions

    let usingMdrive = getHasMdrive(getState()),
      cmd = usingMdrive ? POST.rollQuoteMDrive : POST.rollResults

    return dispatch(
      cmd({
        body: {
          ...quote,
          mode,
          programs: {
            [getBestProgramKey(getState(), vId, qId, mode)]: getBestProgram(
              getState(),
              vId,
              qId,
              mode,
            ),
          },
        },
      }),
    )
      .then(proc)
      .catch(proc)
  },
  toggleRebateCategories = (vId, qId, categories = [], options) => dispatch => {
    vId &&
      qId &&
      categories.forEach(c =>
        dispatch(toggleRebateCategory(vId, qId, c, null, false, options)),
      )
  },
  toggleRebateCategory = (vId, qId, category, parent = null, tag = true) => (
    dispatch,
    getState,
  ) => {
    let selectedRebateCategories = uniqBy(
        getQuoteValue(getState(), vId, qId, 'selectedRebateCategories', []),
        'ID',
      ),
      selectedRebateIds = getSelectedRebateIds(getState(), vId, qId),
      cat = { ...category, parent: parent },
      catIndex = selectedRebateCategories.findIndex(c => isEqual(cat.ID, c.ID)),
      alreadySelected = catIndex !== -1,
      rebateIdsToSelect = getRebatesForCategory(getState(), vId, qId, cat).map(
        r => r.ID,
      ),
    selectedRebateTypes = []

    if (alreadySelected) {
      selectedRebateCategories.splice(catIndex, 1)
      selectedRebateTypes = uniq(selectedRebateCategories.map(c => c.Name))}
    else {
      selectedRebateCategories = [...selectedRebateCategories, cat]
      selectedRebateTypes = uniq(selectedRebateCategories.map(c => c.Name))}

    if (alreadySelected)
      selectedRebateIds = selectedRebateIds.filter(
        s => !rebateIdsToSelect.includes(s),
      )
    else selectedRebateIds = uniq([...selectedRebateIds, ...rebateIdsToSelect])

    vId &&
      qId &&
      dispatch(
        changeQuote(vId, qId, { selectedRebateCategories, selectedRebateIds, selectedRebateTypes }),
      ).then(() => tag &&
             dispatch(
               tagCustomer(vId, qId, true, {
                 selectedRebateCategories,
               }),
             ))
  },
  toggleRebate = (vId, qId, id, trackClick = true) => (dispatch, getState) => {
    let rebateIds = getQuoteValue(getState(), vId, qId, 'selectedRebateIds'),
      alreadySelected = rebateIds.includes(id),
      newIds = alreadySelected ? without(rebateIds, id) : [...rebateIds, id],
      rebate = getRebateById(getState(), vId, qId, id)

    dispatch(changeQuote(vId, qId, { selectedRebateIds: newIds }))

    trackClick &&
      dispatch(
        track(
          alreadySelected ? QUOTE_REMOVAL : QUOTE_ADDITION,
          {
            ...getQuoteTrackerStats(getState(), vId, qId),
            rebate,
            collection: 'rebates',
          },
          true,
        ),
      )
  },
  setRebateValue = (vId, qId, rId, value) => (dispatch, getState) => {
    let selectedRebateValues = getQuoteValue(
      getState(),
      vId,
      qId,
      'selectedRebateValues',
      [],
    )

    value
      ? (selectedRebateValues[rId] = value)
      : delete selectedRebateValues[rId]

    dispatch(changeQuote(vId, qId, { selectedRebateValues }))
  },
  setShortCodeQuoteId = qId => dispatch => {
    dispatch({ type: SET_SHORT_CODE_QUOTE_ID, payload: qId })
  },
  trackSection = (vId, qId, section, open) => (dispatch, getState) => {
    dispatch(
      track(
        QUOTE_CHANGED,
        {
          changes: [{ key: section, to: open ? 'open' : 'closed' }],
          ...getQuoteTrackerStats(getState(), vId, qId),
        },
        true,
      ),
    )
  },
  trackReaction = (vId, qId, reaction) => (dispatch, getState) => {
    dispatch(
      track(QUOTE_REACTION, {
        changes: [{ key: reaction }],
        ...getQuoteTrackerStats(getState(), vId, qId),
      }),
    )
  },
  trackAction = (vId, qId, action) => (dispatch, getState) => {
    dispatch(
      track(QUOTE_CHANGED, {
        changes: [{ key: action }],
        ...getQuoteTrackerStats(getState(), vId, qId),
      }),
    )
  },
  toggleLock = (vId, qId, lock, value = 0) => (dispatch, getState) => {
    let lockValue = getQuoteValue(getState(), vId, qId, `locks.${lock}`)

    dispatch(
      changeQuote(vId, qId, {
        [`locks.${lock}`]: value === lockValue ? null : value,
      }),
    )
  },
  toggleOptionCode = (vId, qId, code) => (dispatch, getState) => {
    let selectedOptionCodes = (
        getQuoteValue(getState(), vId, qId, 'vehicleOptionCode', '') || ''
      ).split(','),
      newCodes = selectedOptionCodes.includes(code)
        ? without(selectedOptionCodes, code)
        : [...selectedOptionCodes, code]

    dispatch(changeQuote(vId, qId, { vehicleOptionCode: newCodes.join() }))
  },
  getRebateFinePrint = rebateIds => dispatch => {
    dispatch(
      POST.rebateDescriptions({ body: rebateIds.map(r => r.toString()) }),
    ).then(r =>
      dispatch({
        type: UPDATE_REBATE_DETAILS,
        payload: Object.keys(r).reduce(
          (ret, k) => ({ ...ret, [k]: r[k] || 'Details not available' }),
          {},
        ),
      }),
    )
  },
  setCurrentQuoteDirty = dirty => (dispatch, getState) => {
    let { vehicle, quote } = getQuoteIds(getState())

    return dispatch(changeQuote(vehicle, quote, { dirty }))
  }

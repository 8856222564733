import { changeQuote, getProgramsForCurrentQuote } from 'actions/quoteActions'
import { getVehicles } from 'actions/inventoryActions'
import { setShowDistractor, setProgramsDirty } from 'actions/appStatusActions'

import { getParamValue, getQueryValue } from 'selectors/pageStateSelectors'
import { getQuoteIds } from 'selectors/quoteSelectors'
import { isDealerUser } from 'selectors/authSelectors'

export const refreshPrograms = (options = {}) => (dispatch, getState) => {
    // context sensitive get programs call that delegates to a more specific
    // version of the get programs call

    let { showDistractor } = options,
      isDealer = isDealerUser(getState())

    showDistractor = !isDealer && showDistractor

    dispatch(setShowDistractor(showDistractor))

    let vId = getParamValue(getState(), 'id'),
      qId = getQueryValue(getState(), 'quoteId'),
      getPrograms = vId && qId ? getProgramsForCurrentQuote : getVehicles

    return dispatch(getPrograms({}, options)).then(() => {
      dispatch(setShowDistractor(false))
      dispatch(setProgramsDirty(false))
    })
  },

  changeCurrentQuote = (properties = {}, options = {}) => (
    dispatch,
    getState,
  ) => {
    let { vehicle, quote } = getQuoteIds(getState())

    return dispatch(changeQuote(vehicle, quote, properties, options))
  }

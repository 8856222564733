import pickBy from 'lodash/pickBy'

export const STATE_KEY = 'entities'
export const ADD_ENTITIES = 'ADD_ENTITIES'
export const MERGE_ENTITIES = 'MERGE_ENTITIES'
export const REPLACE_ENTITIES = 'REPLACE_ENTITIES'
export const DELETE_ENTITIES = 'DELETE_ENTITIES'

const initialState = {}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ENTITIES:
      return {
        ...action.payload,
        ...state,
      }

    case REPLACE_ENTITIES:
      return {
        ...state,
        ...action.payload,
      }

    case MERGE_ENTITIES:
      return {
        ...Object.keys(action.payload).reduce(
          (ret, k) => ({
            ...ret,
            [k]: { ...state[k], ...action.payload[k] },
          }),
          state,
        ),
      }

    case DELETE_ENTITIES:
      return pickBy(state, (v, k) => !action.payload.includes(k))

    default:
      return state
  }
}

import { getEnvName } from 'config/env.js'

function genEndpoints() {
  if (window.runConfig) {
    return [
      {
        name: window.runConfig.envName,
        endpoint: window.runConfig.endpoint,
      },
    ]
  } else {
    return [
      { name: 'local', endpoint: 'http://localhost:3001' },
      { name: 'dev', endpoint: 'https://api-dev.np.frikintech.com' },
      { name: 'production', endpoint: 'https://api.frikintech.com' },
    ]
  }
}

function makeNs() {
  if (window.runConfig) {
    return window.runConfig.envName
  } else {
    return {
      local: 'local',
      development: 'dev',
      'iq-dev': 'iq-dev',
      qa: 'dev',
      test: 'test',
      production: 'production',
      codeBuildGk: 'codeBuildGk',
    }[getEnvName() || 'development']
  }
}

function getAPIEndpointUrl() {
  let ns = makeNs()
  let endpoint = genEndpoints().find(e => e.name === ns)
  return endpoint.endpoint
}

function makeWsEndpointUrl() {
  let url = new URL(getAPIEndpointUrl())
  if (url.protocol === 'https:') {
    url.protocol = 'wss:'
  } else {
    url.protocol = 'ws:'
  }
  url.pathname = '/ws'
  return url.href
}

export default {
  API: {
    endpoints: genEndpoints(),
    ns: makeNs(),
    apiEndpointUrl: getAPIEndpointUrl(),
    wsEndpointUrl: makeWsEndpointUrl(),
  },
}

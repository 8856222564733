import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'reducers'
import { createLogger } from 'redux-logger'
import { routerForBrowser } from 'redux-little-router'
import routes from 'pages/routes'
import createRollbarMiddleware from './middleware/rollbar'
import { getEnvName } from 'config/env.js'

window.Frikin = { proto: window.proto }

const debugMode = ['local', 'development'].includes(getEnvName())

const logger = createLogger({
  duration: true,
  //diff: true,
  collapsed: true,
})

const router = routerForBrowser({ routes })

const initialState = {}
const enhancers = [router.enhancer]
const middleware = [
  router.middleware,
  createRollbarMiddleware(!debugMode),
  thunk,
]

const toCompose = [
  applyMiddleware(...middleware),
  ...enhancers,
  debugMode ? applyMiddleware(logger) : null,
]

window.LOG_LEVEL = debugMode ? 'DEBUG' : 'WARN'

const composedEnhancers = compose(...toCompose.filter(i => i))

const store = createStore(
  combineReducers({ router: router.reducer, ...rootReducer }),
  initialState,
  composedEnhancers,
)

export default store

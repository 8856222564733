import { getEnvName } from 'config/env.js'

export const STATE_KEY = 'appStatus'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_ROOFTOP_INFO = 'UPDATE_ROOFTOP_INFO'

export const XHR_PENDING = 'XHR_PENDING'
export const READY = 'READY'
export const ERROR = 'ERROR'
export const UPDATE_THEME = 'UPDATE_THEME'
export const UPDATE_ROOFTOP_ID = 'UPDATE_DEALER_ID'
export const UPDATE_BANKS = 'UPDATE_BANKS'
export const SET_FINDING_USER = 'SET_FINDING_USER'
export const SET_OUTSTANDING_XHR = 'SET_OUTSTANDING_XHR'
export const SET_COMPLETE_XHR = 'SET_COMPLETE_XHR'
export const SET_SHORT_CODE_QUOTE_ID = 'SET_SHORT_CODE_QUOTE_ID'
export const RETRY_TICK = 'RETRY_TICK'
export const SET_LOADING_QUOTE_INTERACTIONS = 'SET_LOADING_QUOTE_INTERACTIONS'
export const SET_TAGGED_CUSTOMER = 'SET_TAGGED_CUSTOMER'
export const UPDATE_SOCKET_HEALTH = 'UPDATE_SOCKET_HEALTH'
export const CUSTOMIZE_DEMO = 'CUSTOMIZE_DEMO'
export const SET_THEME = 'SET_THEME'

export const SET_LOCATION = 'SET_LOCATION'
export const SET_LOCATION_LOADING = 'SET_LOCATION_LOADING'

export const SET_GETTING_PROGRAMS = 'SET_GETTING_PROGRAMS'
export const SET_SHOW_DISTRACTOR = 'SET_SHOW_DISTRACTOR'
export const SET_PROGRAMS_DIRTY = 'SET_PROGRAMS_DIRTY'

export const SET_CONFIG = 'SET_CONFIG'
export const SET_SQUEEZE = 'SET_SQUEEZE'
export const SET_AVAILABLE_CONFIGS = 'SET_AVAILABLE_CONFIGS'
export const REPLACE_AVAILABLE_CONFIGS = 'REPLACE_AVAILABLE_CONFIGS'
export const SET_CONFIG_VEHICLE_DATA = 'SET_CONFIG_VEHICLE_DATA'
export const SET_GETTING_CONFIGS = 'SET_GETTING_CONFIGS'

export const UPDATE_ABE_CHAT_POSITION = 'UPDATE_ABE_CHAT_POSITION'
export const UPDATE_ROOFTOP_SCRIPTS = 'UPDATE_ROOFTOP_SCRIPTS'
export const UPDATE_REBATE_DETAILS = 'UPDATE_REBATE_DETAILS'

export const SET_WEBSITE_CONFIGS = 'SET_WEBSITE_CONFIGS'

const initialState = {
  status: READY,
  lastStatus: null,
  env: getEnvName(),
  outstandingRequests: {},
  rooftopInfo: {},
  rooftopId: '',
  lastRetry: null,
  loadingQuoteInteractions: false,
  findingUser: {},
  isGettingConfig: false,
  socketHealth: 'HEALTHY', // TODO swap out for non-magic
  rebateDetails: [],
  currentLocation: {},
  gettingLocation: false,
  gettingPrograms: false,
  showDistractor: false,
  programsAreDirty: false,
  customer: {},
}

export default function AppStatusReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
        lastStatus:
          state.status !== action.payload.status
            ? state.status
            : state.lastStatus,
        status: action.payload.status || state.status,
      }

    case UPDATE_ROOFTOP_INFO:
      return {
        ...state,
        rooftopInfo: {
          ...state.rooftopInfo,
          ...action.payload,
        },
      }

    case SET_OUTSTANDING_XHR:
      return {
        ...state,
        outstandingRequests: {
          ...state.outstandingRequests,
          ...action.payload,
        },
      }

    case SET_COMPLETE_XHR:
      let newRequests = { ...state.outstandingRequests }

      delete newRequests[action.payload]

      return {
        ...state,
        outstandingRequests: newRequests,
      }

    case SET_CONFIG_VEHICLE_DATA:
      return { ...state, ...action.payload }

    case UPDATE_THEME: // TODO ??????
      return { ...state, theme: action.payload }

    case CUSTOMIZE_DEMO:
      return {
        ...state,
        demoInfo: {
          name: action.payload.rooftopName,
          theme: action.payload.theme,
        },
      }

    case UPDATE_ROOFTOP_ID:
      return { ...state, rooftopId: action.payload }

    case UPDATE_BANKS:
      return { ...state, banks: action.payload }

    case SET_SHORT_CODE_QUOTE_ID:
      return { ...state, shortCodeQuoteId: action.payload }

    case RETRY_TICK:
      return { ...state, lastRetry: new Date() }

    case SET_SQUEEZE:
      return { ...state, squeezeCount: action.payload }

    case SET_LOADING_QUOTE_INTERACTIONS:
      return { ...state, loadingQuoteInteractions: action.payload }

    case SET_FINDING_USER:
      return { ...state, findingUser: action.payload }

    case SET_CONFIG:
      return (function(){
        let ret = {
          ...state,
          availableConfigs: {
            ...state.availableConfigs,
            [action.payload.id]: {
              ...state.availableConfigs[action.payload.id],
              config: action.payload.config,
            },
          },
        }
        if (action.payload.configuredProgramData){
          ret.availableConfigs[action.payload.id].configuredProgramData = action.payload.configuredProgramData
        }
        return ret
      }())


    case REPLACE_AVAILABLE_CONFIGS:
      return {
        ...state,
        availableConfigs: {
          ...action.payload.configs,
        },
      }

    case SET_AVAILABLE_CONFIGS:
      return {
        ...state,
        availableConfigs: {
          ...state.availableConfigs,
          ...action.payload.configs,
        },
      }

    case SET_GETTING_CONFIGS:
      return {
        ...state,
        isGettingConfig: action.payload,
      }

    case SET_TAGGED_CUSTOMER:
      return { ...state, customer: action.payload }

    case UPDATE_SOCKET_HEALTH:
      return { ...state, socketHealth: action.payload }

    case SET_WEBSITE_CONFIGS:
      return { ...state, websiteConfigs: action.payload }

    case UPDATE_ROOFTOP_SCRIPTS:
      return {
        ...state,
        rooftopInfo: {
          ...state.rooftopInfo,
          scripts: action.payload,
        },
      }

    case UPDATE_REBATE_DETAILS:
      return {
        ...state,
        rebateDetails: {
          ...state.rebateDetails,
          ...action.payload,
        },
      }

    case SET_THEME:
      return {
        ...state,
        rooftopInfo: {
          ...state.rooftopInfo,
          theme: action.payload,
        },
      }

    case SET_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      }

    case SET_LOCATION_LOADING:
      return {
        ...state,
        gettingLocation: action.payload,
      }

    case SET_GETTING_PROGRAMS:
      return {
        ...state,
        gettingPrograms: action.payload,
      }

    case SET_SHOW_DISTRACTOR:
      return {
        ...state,
        showDistractor: action.payload,
      }

    case SET_PROGRAMS_DIRTY:
      return {
        ...state,
        programsAreDirty: action.payload,
      }

    default:
      return state
  }
}

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const getEntity = (state, id) => {
    const DEBUG = window.LOG_LEVEL === 'DEBUG' && window.ENTITY_LOG

    DEBUG && console.time(`getEntity ${id}`)

    let ret = get(state, `entities[${id}]`, {})

    DEBUG && console.timeEnd(`getEntity ${id}`)

    return ret
  },
  findAllEntities = (state, test) => {
    let ret = Object.keys(state.entities).reduce((ret, k) => {
      return state.entities[k] && test(state.entities[k], k)
        ? { ...ret, [k]: state.entities[k] }
        : ret
    }, {})

    return isEmpty(ret) ? [] : ret
  }

import get from 'lodash/get'
import { STATE_KEY } from 'reducers/authReducers'
import {
  getRooftopId,
  getTaggedCustomerFromState,
} from 'selectors/appStatusSelectors'

import { DEFAULT_PERMS } from 'reducers/authReducers'
import { getBrowser, isBot } from 'selectors/appStatusSelectors'

export const ROLES = {
  CUSTOMER: 'customer',
  MANAGER: 'manager',
}

export const getAuthState = state => get(state, `[${STATE_KEY}].authState`),
  getAuthData = state => get(state, `[${STATE_KEY}].auth`, {}),
  getUser = state => get(state, `[${STATE_KEY}].cognitoUser`),
  getUserToken = state =>
    get(getUser(state), 'signInUserSession.accessToken.jwtToken'),
  getUserIdToken = state =>
    get(getUser(state), 'signInUserSession.idToken.jwtToken'),
  getIsLoggedIn = getUser,
  loginWasChecked = state => get(state, `[${STATE_KEY}].loginChecked`, false),
  getUserEmail = state => get(getUserData(state), 'email'),
  getPersonInfoForRollBar = state => ({
    id: get(state, `[${STATE_KEY}].cognitoUser.username`) || 'unauthUser',
    email:
      get(state, `[${STATE_KEY}].cognitoUser.attributes.email`) ||
      'unauthUser@frikin.tech',
  }),
  getShortUserName = state =>
    getUserData(state) &&
    `${get(getUserData(state), 'given_name', '')} ${get(
      getUserData(state),
      'family_name[0]',
      '',
    )}.`,
  getUserName = state =>
    getUserData(state) &&
    `${get(getUserData(state), 'given_name')} ${get(
      getUserData(state),
      'family_name',
    )}`,
  getRawUserName = state =>
    getUserData(state) && {
      firstName: get(getUserData(state), 'given_name'),
      lastName: get(getUserData(state), 'family_name'),
    },
  getUserData = state => get(state, `[${STATE_KEY}].userData`, {}),
  getUserRole = state => get(getUserData(state), 'role'),
  isCustomerUser = state => getUserRole(state) === ROLES.CUSTOMER,
  isDealerUser = state => getUserRole(state) !== ROLES.DEALER,
  getPermissions = state => ({
    ...DEFAULT_PERMS,
    ...get(getUserData(state), `claims.permissions.${getRooftopId(state)}`, {}),
  }),
  getGlobalPermissions = state => ({
    ...DEFAULT_PERMS,
    ...get(getUserData(state), `claims.global-permissions`, {}),
  }),
  getPermissionsForRooftop = (state, rooftopId) =>
    get(getPermissions(state), rooftopId, {}),
  getPermission = (state, permissionKey, def = { r: false, w: false }) =>
    get(getPermissions(state), permissionKey, def),
  getReadPermission = (state, permissionKey) =>
    get(getPermission(state, permissionKey), 'r', false),
  getWritePermission = (state, permissionKey) =>
    get(getPermission(state, permissionKey), 'w', false),
  hasRead = (state, permissionKey) => getPermission(state, permissionKey).r,
  hasWrite = (state, permissionKey) => getPermission(state, permissionKey).w,
  isFBBot = state => {
    let browser = getBrowser(state),
      fbMatch = {
        name: 'facebook',
      }

    return Object.keys(fbMatch).every(k => browser[k] === fbMatch[k])
  },
  getIsDealerBrowser = state => {
    let tag = getTaggedCustomerFromState(state)

    return get(tag, '_entityType') === 'dealer'
  },
  shouldTrack = state => {
    let dealer = getIsDealerBrowser(state),
      fbBot = isFBBot(state),
      genericBot = isBot(state)

    return !genericBot && !fbBot && !getIsLoggedIn(state) && !dealer
  },
  shouldTrackGTM = state => {
    return !getIsLoggedIn(state)
  },
  getRooftopOptions = state => get(state, `[${STATE_KEY}].rooftopOptions`)

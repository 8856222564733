import React, { Fragment as F } from 'react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import pluralize from 'pluralize'
import { num, money, moneyShort } from 'utils'

import Icon from 'discada/dist/Icon'
import Tag from 'discada/dist/Tag'

import { INTENTS, INTENTS_EMOJI, INTENTS_LABELS } from 'reducers/quoteReducers'
import { SORT_BYS } from 'selectors/inventorySelectors'

export const seenAfterToString = seenAfter => {
  if (typeof seenAfter === 'number') {
    const minutes = seenAfter / 60
    const hours = seenAfter / (60 * 60)
    const days = seenAfter / (60 * 60 * 24)

    if (seenAfter < 60) {
      return `${Math.round(seenAfter)} second${
        Math.round(minutes) === 1 ? '' : 's'
      }`
    }

    if (minutes < 60) {
      return `${Math.round(minutes)} minute${
        Math.round(minutes) === 1 ? '' : 's'
      }`
    }

    if (hours < 24) {
      return `${Math.round(hours)} hour${Math.round(hours) === 1 ? '' : 's'}`
    }

    return `${Math.round(days)} day${Math.round(days) === 1 ? '' : 's'}`
  } else {
    return 'a while'
  }
}

export const EVENT_LABELS = {
  QUOTE_VIEWED: () => 'Prepared Quote Opened',
  CAME_BACK: () => 'Customer Came Back',
  QUOTE_REACTION: () => '',
  QUOTE_CHANGED: () => 'Changed',
  QUOTE_ADDITION: event =>
    `${pluralize(get(event, 'payload.collection'), 1)} Added`,
  QUOTE_REMOVAL: event =>
    `${pluralize(get(event, 'payload.collection'), 1)} Removed`,
  QUOTE_REFRESHED: () => 'Refreshed',
  OPENED_DIFFERENT_VEHICLE: () => 'Customer Switched Vehicles',
  VEHICLE_PINNED: () => 'Manually Pinned Vehicle',
  VEHICLE_UNPINNED: () => 'Unpinned Vehicle',
  TAG_CHANGED: () => 'Squeeze Jacket Updated',
  SQUEEZE_JACKET_OPENED: () => 'Squeeze Jacket Opened',
  SQUEEZE_JACKET_CLOSED: () => 'Squeeze Jacket Closed',
  VIEWED_EQUIPMENT: () => 'Customer Viewed Vehicle Equipment',
  VIEWED_SPECIFICATIONS: () => 'Customer Viewed Vehicle Specifications',
  VIEWED_WARRANTY: () => 'Customer Viewed Vehicle Warranties and Extras',
  VIEWED_INCENTIVES: () => 'Customer Viewed Incentives',
}

export const HIGHLIGHT = {
  QUOTE_VIEWED: () => true, // TODO should only highlight first view?
  CAME_BACK: () => true,
  QUOTE_SAVED: () => true,
  OPENED_DIFFERENT_VEHICLE: () => true,
  QUOTE_REACTION: () => true,
  QUOTE_CHANGED: p => get(p, 'payload.changes', []).some(c => c.trade),
}

export const FIELD_LABELS = {
  miles: 'Miles per year',
  leaseTerm: 'Lease Term',
  loanTerm: 'Loan Term',
  creditScore: 'Credit Score',
  down: 'Down Payment',
  mode: 'Payment Type',
  customerZipCode: 'Zip Code',
  firstName: 'First Name',
  lastName: 'Last Name',
  desiredPaidReserve: 'Desired Paid Reserve',
  'customer-adds': 'Discounts and Options section',
  'customer-trades': 'Sell us Your Car section',
  'customer-rebates': 'Incentives section',
  'customer-fandi': 'Options and Extras section',
  'customer-finalize': 'Payment Summary section',
  'customer-contract-details': 'Payment Summary section',
  'customer-finalize-modal': "I'm Ready for my Test Drive Popup",
  'customer-roll': 'Roll Payment Popup',
  'customer-zip-code': 'Zip Code Popup',
  desiredPayment: 'Desired Payment',
  'customer.approveTab': 'Customer Accept Tab',
  viewMode: 'Quote View',
  'illuminate-me-button': 'Illuminate Me Button clicked',
  hasNoRebates: '"I don\'t qualify" for incentives',
  hasNoTrade: '"I don\'t have a trade"',
  sellMyTrade: false,
  selectedRebateTypes: 'Incentive Categories',
  'location.taxRegionLabel': 'Tax Region',
  'location.zipCode': 'Zip Code',
}

export const bool = x => (x ? 'Checked' : 'Unchecked'),
  zipFormatter = x => (x && x.length === 5 ? x : null),
  listFormatter = (x = []) =>
    isEmpty(x) ? 'none' : x.map(y => y.name).join(', '),
  QUOTE_CHANGED_FORMATTER = {
    default: x =>
      typeof x === 'object'
        ? JSON.stringify(x)
        : x && x.toString
        ? x.toString()
        : x,
    miles: num,
    leaseTerm: x => `${x} mo.`,
    loanTerm: x => `${x} mo.`,
    creditScore: x => (x === 0 ? '800+' : `${x}+`), // TODO maybe change this to config display values?
    down: moneyShort,
    mode: x => x,
    customerZipCode: zipFormatter,
    zipCode: zipFormatter,
    desiredPaidReserve: moneyShort,
    payoff: moneyShort,
    allowance: moneyShort,
    low: moneyShort,
    high: moneyShort,
    target: moneyShort,
    residual: moneyShort,
    isLease: bool,
    confirmed: () => 'Confirmed',
    hasNoRebates: bool,
    maintenancePlans: listFormatter,
    additions: listFormatter,
    warranties: listFormatter,
    selectedRebateTypes: (x = []) => (x || []).join(', '),
    selectedRebateCategories: (x = {}) => x.map(r => r.Name),
    hasNoTrade: bool,
    sellMyTrade: () => 'Customer is interested in selling their trade',
    mileage: num,
    tradePending: x => get(x, 'ymmt'),
    'customer-adds': x => x,
    'customer-trades': x => x,
    'customer-finalize': x => x,
    desiredPayment: moneyShort,
    'customer.approveTab': x =>
      x === 'thumbsUp'
        ? 'This payment looks good...'
        : "I don't like this payment",
    viewMode: x => {
      let viewMode = x.split('.'),
        view = viewMode[0],
        step = viewMode[1],
        ret = {
          simple: 'Slider View',
          complex: 'Advanced View',
        }[view]

      if (step) {
        ret = `${ret} - Step ${step}`
      }

      return ret
    },
    'location.taxRegionLabel': x => (x ? x : "I don't know"),
  }

export const convertKey = (key = '', force = false) => {
  if (!force && key.match(/tradePending.vehicle/)) return 'tradePending'
  if (!force && key.match(/customer.approveTab/)) return key

  // takes an 0-indexed array value and adds one to it to make it human readable
  key = key.replaceAll(/\W(\d+)\W/g, (_, i) => {
    return ` #${parseInt(i, 10) + 1} `
  })

  key = key
    .replace(/\./g, ' ')
    .replace('tradePending', '')
    .replace('fullYmmt', 'Vehicle')
    .replace('ymmt', 'Vehicle')
    .replace('maintenancePlans', 'Maintenance Plans')

  return key
}

export const INTENT_ILLUM_LABELS = {
  accepted: 'Accepted',
  helpRequested: 'Saved',
  revised: 'Saved',
  rolled: 'Is trying to get to a',
  rejected: 'Rejected',
  shared: 'Saved and Shared',
  cashRejected: 'Is trying to get to a',
  contact: 'Saved',
  getOTD: 'Personalized',
  holdDeal: 'Requests to Hold',
  buyNow: 'Wants to Buy',
  tradeAppraisal: 'Wants a Trade Appraisal',
  created: 'Saved',
  magicLink: 'Opened Magic Link',
}

const diffMapper = ({ noFrom, noTo } = {}) => (c, i) => {
    let { key = '', from, to } = c || {},
      lookupKey = convertKey(key),
      formatter =
        QUOTE_CHANGED_FORMATTER[key] ||
        QUOTE_CHANGED_FORMATTER[lookupKey] ||
        QUOTE_CHANGED_FORMATTER[key.split('.').slice(-1)] ||
        QUOTE_CHANGED_FORMATTER.default,
      fromContent = from !== null && formatter(from),
      toContent = formatter(to),
      changedLabel = 'changed'

    return (
      <span key={i} className="diff-display">
        <span className="diff-key">
          {FIELD_LABELS[key] || convertKey(key, true)}
        </span>{' '}
        {changedLabel}{' '}
        {fromContent && !noFrom && !isEqual(fromContent, toContent) ? (
          <F>
            from <strong>{fromContent}</strong>{' '}
          </F>
        ) : null}
        {toContent && !noTo ? (
          <F>
            to{' '}
            <strong>
              {!isNull(toContent) && !(Array.isArray(to) && isEmpty(to)) ? (
                toContent
              ) : (
                <span className="empty">none</span>
              )}
            </strong>
          </F>
        ) : null}
      </span>
    )
  },
  renderDiff = e => {
    return get(e, 'payload.changes', []).map(diffMapper())
  },
  REMOVED = {
    trade: t => t.fullYmmt,
  },
  renderRemovedObject = e => {
    let removed = get(e, 'payload.removed', {})

    return Object.keys(removed).reduce(
      (ret, k) => [
        ...ret,
        <span key={k} className="diff-display">
          <span className="diff-key">{k}</span>{' '}
          <strong>{REMOVED['trade'](removed[k])}</strong> removed
        </span>,
      ],
      [],
    )
  },
  renderDiffObject = e => {
    let changes = get(e, 'payload.changes', {})

    return Object.keys(changes)
      .reduce((ret, k) => {
        let { to, from } = changes[k]
        return [...ret, { key: k, to, from }]
      }, [])
      .map(diffMapper({}))
  }

export const EVENT_RENDERER = {
  QUOTE_CHANGED: renderDiff,
  TAG_CHANGED: e =>
    get(e, 'payload').hasOwnProperty('removed')
      ? renderRemovedObject(e)
      : renderDiffObject(e),
  QUOTE_REACTION: e => {
    let k = get(e, 'payload.changes[0].key')

    return (
      <span>
        <strong>Reacted</strong>{' '}
        {k && (
          <F>
            with{' '}
            <span>
              {INTENTS_EMOJI[k]} "{INTENTS_LABELS[k]}"
            </span>
          </F>
        )}
      </span>
    )
  },
  CAME_BACK: e => {
    return (
      <span>
        Customer <strong>came back</strong> after{' '}
        {seenAfterToString(e.payload.seenAfter)}.
      </span>
    )
  },
  QUOTE_VIEWED: e => {
    return (
      <span>
        <strong>Prepared quote opened</strong> by customer on{' '}
        <strong>
          {get(e, 'browser.mobile', false) ? 'Mobile Device' : 'Desktop'}
        </strong>
      </span>
    )
  },
  QUOTE_ADDITION: e => {
    let renderers = {
      rebates: (
        <span>
          Selected <strong>{get(e, 'payload.rebate.Name')}</strong> Incentive [
          {get(e, 'payload.rebate.Number')}]
        </span>
      ),
      trades: <span>Added emtpy trade</span>,
    }

    return renderers[get(e, 'payload.collection')]
  },
  QUOTE_REMOVAL: e => {
    let renderers = {
      rebates: (
        <span>
          Removed <strong>{get(e, 'payload.rebate.Name')}</strong>[
          {get(e, 'payload.rebate.Number')}]
        </span>
      ),
      trades: (
        <span>
          Removed Trade <strong>{get(e, 'payload.removedItem.ymmt')}</strong>
        </span>
      ),
    }

    return renderers[get(e, 'payload.collection')]
  },
  QUOTE_REFRESHED: () => (
    <span>
      <strong>Refreshed</strong> Updated Payments shown
    </span>
  ),
  QUOTE_SAVED: e => {
    let intent = get(e, 'payload.intent'),
      { mode, down } = get(e, 'payload.payment'),
      term = get(e, `payload.payment.${mode}Term`),
      selectedPayment = get(e, `payload.payment.${mode}Payment`),
      desiredPayment = get(e, 'payload.payment.desiredPayment')

    if (intent === 'cashRejected') {
      return (
        <span>
          <strong>{INTENT_ILLUM_LABELS[intent]}</strong> to pay{' '}
          <strong>{money(desiredPayment)}</strong>
        </span>
      )
    }

    if (intent === 'rolled') {
      return (
        <span>
          <strong>{INTENT_ILLUM_LABELS[intent]}</strong> a{' '}
          <strong>{moneyShort(desiredPayment)}</strong> for{' '}
          <strong>{term} months</strong>, needs{' '}
          <strong>{moneyShort(down)} down </strong>{' '}
        </span>
      )
    }

    return (
      <span>
        <strong>{INTENT_ILLUM_LABELS[intent]}</strong> a{' '}
        <strong>
          {moneyShort(selectedPayment)}mo {mode}
        </strong>{' '}
        for <strong>{term} months</strong>, with{' '}
        <strong>{moneyShort(down)} down </strong> <span></span>
      </span>
    )
  },
  VIEWED_EQUIPMENT: e => {
    let { stockNumber, year, make, model, trim } = get(
      e,
      'payload.lookedAt',
      {},
    )

    return (
      <span>
        Customer viewed equipment on{' '}
        <strong>
          #{stockNumber} {year} {make} {model} {trim}
        </strong>
      </span>)
    },
  VIEWED_SPECIFICATIONS: e => {
    let { stockNumber, year, make, model, trim } = get(
      e,
      'payload.lookedAt',
      {},
    )

    return (
      <span>
        Customer viewed specifications on{' '}
        <strong>
          #{stockNumber} {year} {make} {model} {trim}
        </strong>
      </span>)
    },
  VIEWED_INCENTIVES: e => {
    let { stockNumber, year, make, model, trim } = get(
      e,
      'payload.lookedAt',
      {},
    )

    return (
      <span>
        Customer viewed incentives on{' '}
        <strong>
          #{stockNumber} {year} {make} {model} {trim}
        </strong>
      </span>)
    },
  VIEWED_WARRANTY: e => {
    let { stockNumber, year, make, model, trim } = get(
      e,
      'payload.lookedAt',
      {},
    )

    return (
      <span>
        Customer viewed warranties on{' '}
        <strong>
          #{stockNumber} {year} {make} {model} {trim}
        </strong>
      </span>)
    },
  OPENED_DIFFERENT_VEHICLE: e => {
    let { stockNumber, year, make, model, trim } = get(
      e,
      'payload.lookedAt',
      {},
    )

    return (
      <span>
        Customer looked at{' '}
        <strong>
          #{stockNumber} {year} {make} {model} {trim}
        </strong>
      </span>
    )
  },
  VLP_VIEWED: e => {
    let selectedFilters = get(e, 'payload.selectedFilters', {}),
      sortedBy = get(selectedFilters, 'sortBy'),
      assembledFilters = Object.keys(selectedFilters)
        .filter(x => x !== 'sortBy')
        .map(k => (
          <span className="naughty-filter" key={k}>
            {pluralize(k)}:{' '}
            <strong>
              {Array.isArray(selectedFilters[k])
                ? selectedFilters[k].join(', ')
                : selectedFilters[k]}
            </strong>{' '}
          </span>
        ))

    return (
      <span>
        <strong>All Cars Viewed</strong>
        {!isEmpty(assembledFilters) && ' filtered by '}
        {assembledFilters}
        {!isEmpty(sortedBy) && ' sorted by '}
        {!isEmpty(sortedBy) && <strong>{SORT_BYS[sortedBy]}</strong>}
      </span>
    )
  },
  SQUEEZE_JACKET_OPENED: () => {
    return (
      <span>
        <strong>Squeeze Jacket Opened</strong>
      </span>
    )
  },
  SQUEEZE_JACKET_CLOSED: () => {
    return (
      <span>
        <strong>Squeeze Jacket Closed</strong>
      </span>
    )
  },
  MAGIC_LINK_OPENED: ({ payload }) => {
    return (
      <span>
        <strong>Customer Opened Magic Link</strong>
        <br />
        <small className="magiclink-display">
          {typeof payload.magicLink === 'string' && payload.magicLink}
        </small>
      </span>
    )
  },
  OPENED_ILLUMIQUOTE: () => {
    return (
      <span>
        <strong>Customer Opened Illumiquote</strong>
      </span>
    )
  },
  // kept around to handle legacy data
  REOPEN: () => {
    return (
      <span>
        <strong>Customer Opened Illumiquote</strong>
      </span>
    )
  },
  VEHICLE_PINNED: () => {
    return (
      <span>
        Customer Manually <strong>Pinned</strong> Vehicle
      </span>
    )
  },
  VEHICLE_UNPINNED: () => {
    return (
      <span>
        Customer <strong>Unpinned</strong> Vehicle
      </span>
    )
  },
}

export const EVENT_ICON = {
  VLP_VIEWED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="th" />
    </Tag>
  ),
  // keeping around so we can handle legacy data
  REOPEN: () => (
    <Tag size="large">
      <Icon fixedWidth icon="eye" />
    </Tag>
  ),
  OPENED_ILLUMIQUOTE: () => (
    <Tag size="large">
      <Icon fixedWidth icon="eye" />
    </Tag>
  ),
  CAME_BACK: () => (
    <Tag size="large" className="monkey">
      <span role="img" aria-label="Return of the mack...">
        🙌
      </span>
    </Tag>
  ),
  QUOTE_CHANGED: () => (
    <Tag size="large" className="monkey">
      <span role="img" aria-label="See no evil...">
        🙈
      </span>
    </Tag>
  ),
  QUOTE_REACTION: () => (
    <Tag size="large" className="monkey">
      <span role="img" aria-label="See no evil...">
        🙈
      </span>
    </Tag>
  ),
  QUOTE_VIEWED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="envelope-open" />
    </Tag>
  ),
  QUOTE_ADDITION: () => (
    <Tag size="large">
      <Icon fixedWidth icon="check-square" />
    </Tag>
  ),
  QUOTE_REMOVAL: () => (
    <Tag size="large">
      <Icon fixedWidth icon="minus-square" />
    </Tag>
  ),
  QUOTE_REFRESHED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="sync-alt" />
    </Tag>
  ),
  OPENED_DIFFERENT_VEHICLE: () => (
    <Tag size="large" className="monkey event-different-car">
      <span role="img" aria-label="OOH What's that?">
        🙊
      </span>
    </Tag>
  ),
  MAGIC_LINK_OPENED: () => (
    <Tag size="large" className="monkey event-magic-link">
      <span role="img" aria-label="Blast Off">
        🚀
      </span>
    </Tag>
  ),
  QUOTE_SAVED: e => {
    const colors = {
      [INTENTS.accepted]: 'success',
      [INTENTS.rejected]: 'warning',
    }

    let intent = get(e, 'payload.intent')

    return (
      <Tag size="large" color={get(colors, intent, 'info')}>
        {INTENTS_EMOJI[intent]}
      </Tag>
    )
  },
  VEHICLE_PINNED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="thumbtack" />
    </Tag>
  ),
  TAG_CHANGED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="folder-open" />
    </Tag>
  ),
  SQUEEZE_JACKET_OPENED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="folder-open" />
    </Tag>
  ),
  SQUEEZE_JACKET_CLOSED: () => (
    <Tag size="large">
      <Icon fixedWidth icon="folder-open" />
    </Tag>
  ),
  VEHICLE_UNPINNED: () => (
    <Tag size="large">
      <Icon
        fixedWidth
        icon="thumbtack"
        prefix="far"
        style={{ transform: 'rotate(180deg)' }}
      />
    </Tag>
  ),
}
